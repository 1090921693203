import { YStack, styled, H1, Text } from '@red-ui/components/'
import { PUBLIC_URL } from '../../config'

export const CARD_WIDTH = 424

export const PlansContainer = styled(YStack, {
  padding: '$6',
  backgroundColor: '$red6',
  alignItems: 'center',
  backgroundImage: `url(${PUBLIC_URL}/img/upgrade-to-premium.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  backgroundSize: 'auto 100%',
  $mobile: {
    paddingHorizontal: '$2',
    paddingVertical: '$4',
  },
  $tabletPortrait: {
    backgroundImage: 'none',
  },
})

export const SpinnerContainer = styled(YStack, {
  minHeight: '80vh',
  backgroundColor: '$red6',
  justifyContent: 'center',
})

export const Heading = styled(H1, {
  fontStyle: 'italic',
  color: '$white',
  textAlign: 'center',
})

export const Subheading = styled(Text, {
  color: '$white',
  fontSize: '$5',
  textAlign: 'center',
  marginTop: '$3',
  marginBottom: '$6',
})

export const Stack = styled(YStack, {
  gap: '$3',
  $gtTabletPortrait: {
    flexDirection: 'row',
  },
})
