import { connect } from 'react-redux'

import { getMessage, getType, getIsClosable, getVisible, getAutoDismissTime } from '@vrw/data/src/redux/toast/selectors'
import { doUnsetToastMessage } from '@vrw/data/src/redux/toast/dispatchers'
import { Toast } from './Toast'
import { DispatchFunction, RootState } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'

const mapStateToProps = (state: RootState) => ({
  message: getMessage(state),
  visible: getVisible(state),
  type: getType(state),
  isClosable: getIsClosable(state),
  autoDismissTime: getAutoDismissTime(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUnsetToastMessage,
    },
    dispatch
  )

const ToastContainer = connect(mapStateToProps, mapDispatchToProps)(Toast)

export { ToastContainer }
