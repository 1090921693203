import React from 'react'
import { useUserSubscriptionStatus } from '../query/subscriptions'

import { Subscription, SubscriptionCancellation } from '../pages/MyAccount'
import { Subscribe, SubscriptionConfirmation, SubscriptionPayment } from '../pages'

import { PATHS } from './paths'
import { SUBSCRIPTION_STATUS } from '@vrw/data/src/api/subscriptions.api'

type RouteItem = {
  path: string
  component: React.ReactNode
}

export const SUBSCRIBED_SUBSCRIPTION_ROUTES: RouteItem[] = [
  {
    path: PATHS.MY_ACCOUNT_SUBSCRIPTION,
    component: <Subscription />,
  },
  {
    path: PATHS.MY_ACCOUNT_SUBSCRIPTION_CANCEL,
    component: <SubscriptionCancellation />,
  },
]

export const NOT_SUBSCRIBED_SUBSCRIPTION_ROUTES: RouteItem[] = [
  {
    path: PATHS.SUBSCRIBE,
    component: <Subscribe />,
  },
  {
    path: PATHS.SUBSCRIPTION_PAYMENT,
    component: <SubscriptionPayment />,
  },
]

export const COMMON_SUBSCRIPTION_ROUTES: RouteItem[] = [
  {
    path: PATHS.SUBSCRIPTION_CONFIRMATION,
    component: <SubscriptionConfirmation />,
  },
]

export const ALL_SUBSCRIPTION_ROUTES: RouteItem[] = [
  ...NOT_SUBSCRIBED_SUBSCRIPTION_ROUTES,
  ...SUBSCRIBED_SUBSCRIPTION_ROUTES,
  ...COMMON_SUBSCRIPTION_ROUTES,
]

export const useSubscriptionRoutes = (): RouteItem[] => {
  const { isLoading, isSubscribedToPlan, currentSubscription } = useUserSubscriptionStatus()

  const routes = COMMON_SUBSCRIPTION_ROUTES

  if (isLoading) return ALL_SUBSCRIPTION_ROUTES

  if (isSubscribedToPlan) {
    const subscribedRoutes = routes.concat(SUBSCRIBED_SUBSCRIPTION_ROUTES)
    return subscribedRoutes.filter((route) => {
      if (route.path === PATHS.MY_ACCOUNT_SUBSCRIPTION_CANCEL) {
        return currentSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE
      }
      return true
    })
  }

  return routes.concat(NOT_SUBSCRIBED_SUBSCRIPTION_ROUTES)
}
