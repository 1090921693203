import { connect } from 'react-redux'
import { doLoginWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { DispatchFunction, RootState } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { LoggedOutHero } from './LoggedOutHero'

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticating: state.auth.isAuthenticating,
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction) => {
  return bindTypedActionCreators(
    {
      doLogin: () => doLoginWeb(),
    },
    dispatch
  )
}

export const LoggedOutHeroContainer = connect(mapStateToProps, mapDispatchToProps)(LoggedOutHero)
