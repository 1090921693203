import { connect } from 'react-redux'

import { getIsSteppedUp, getIsSteppingUp } from '@vrw/data/src/redux/auth/selectors'

import { RootState } from '../../redux/types'
import { LoadingScreen } from './LoadingScreen'

const mapStateToProps = (state: RootState) => ({
  isSteppedUp: getIsSteppedUp(state),
  isSteppingUp: getIsSteppingUp(state),
})

const LoadingScreenContainer = connect(mapStateToProps)(LoadingScreen)

export { LoadingScreenContainer }
