import React from 'react'
import { Text, Separator } from '@red-ui/components/'
import {
  Table,
  TableHeader,
  TableHeaderFeatureSection,
  TableHeaderPlansSection,
  TableHeaderRow,
  TableHeaderSectionItem,
  TableHeaderText,
  TableTitle,
} from './styles'
import { getString } from '@vrw/data'
import { SubscriptionPlan } from '@vrw/data/src/api/subscriptions.api'
import { TableBody } from './TableBody'

type Props = {
  features: string[]
  plans: SubscriptionPlan[]
}

export const CompareTable = ({ plans, features }: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderFeatureSection>
            <TableTitle>{getString('subscribe.page.content.table.title')}</TableTitle>
          </TableHeaderFeatureSection>
          <TableHeaderPlansSection>
            {plans?.map((plan) => (
              <TableHeaderSectionItem key={plan.id}>
                <TableHeaderText color={plan.primaryColor} fontWeight={700}>
                  {plan.name}
                </TableHeaderText>
                <TableHeaderText fontWeight={600}>
                  {plan.cost}
                  {plan.frequency && <Text fontSize="$3">{` /${plan.frequency.toLocaleLowerCase()}`}</Text>}
                </TableHeaderText>
              </TableHeaderSectionItem>
            ))}
          </TableHeaderPlansSection>
        </TableHeaderRow>
        <Separator />
      </TableHeader>
      <TableBody features={features} plans={plans} />
    </Table>
  )
}
