import { navItems } from './NavigationItems'
import { ViewingRegion } from '@vrw/data/src/utils'
import { SUBSCRIPTION_STATUS } from '@vrw/data/src/api/subscriptions.api'
import { PATHS } from '../../router/paths'
import { FEATURE_FLAGS } from '../../config'
import { getViewingRegion } from '../../dataImplementation/viewingRegionWeb'
import { useUserSubscriptionStatus } from '../../query/subscriptions'

export const useFilteredNavItems = () => {
  const { vidAuth } = FEATURE_FLAGS
  const viewingRegion = getViewingRegion()
  const { isSubscribedToPlan, currentSubscription } = useUserSubscriptionStatus()

  return navItems
    .filter(({ linkUrl }) => {
      if (linkUrl === PATHS.MY_ACCOUNT_SUBSCRIPTION) return isSubscribedToPlan
      return !(
        linkUrl === PATHS.MY_ACCOUNT_REFER_DASHBOARD ||
        (vidAuth && linkUrl === PATHS.MY_ACCOUNT_UPDATE_EMAIL) ||
        (viewingRegion === ViewingRegion.US && linkUrl === PATHS.MY_ACCOUNT_REFER_DASHBOARD)
      )
    })
    .map((item) => {
      if (!item.navItems) return item

      return {
        ...item,
        navItems: item.navItems.filter(({ linkUrl }) => {
          if (linkUrl === PATHS.MY_ACCOUNT_SUBSCRIPTION_CANCEL) {
            return currentSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE
          }
          return true
        }),
      }
    })
}
