import { connect } from 'react-redux'

import { getSort } from '@vrw/data/src/redux/discovery/selectors'
import { doUpdateRewards } from '@vrw/data/src/redux/discovery/dispatchers'
import { setSort } from '@vrw/data/src/redux/discovery/actions'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RootState, DispatchFunction } from '../../redux/types'
import { RewardsSort } from './RewardsSort'

const mapStateToProps = (state: RootState) => ({
  selectedSort: getSort(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUpdateRewards: doUpdateRewards,
      setSort: setSort,
    },
    dispatch
  )

export const PrivateSpendRewardsSortContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsSort)
