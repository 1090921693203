import { RewardEarn } from '@vrw/data/src/redux/rewardsEarn/types'
import { useTrackEvent } from './useTrackEvent'
import { Reward } from '@vrw/data/src/redux/rewards/types'
import { RedEvents } from './analytics.types'

export const useTrackRewardEvent = (reward: Reward | RewardEarn | undefined, rewardType: 'earn' | 'spend') => {
  const { trackEvent } = useTrackEvent()
  const categories = reward?.content?.categories
  const category = categories && categories[0] ? categories[0].categoryName : ''
  const isEarn = rewardType === 'earn'

  const trackRewardViewedEvent = () => {
    if (!reward) return
    trackEvent(isEarn ? RedEvents.EARN_OFFER_VIEWED : RedEvents.SPEND_OFFER_VIEWED, {
      offer_id: reward.rewardId,
      offer_title: reward.content.title,
      offer_category: category,
      offer_points: reward.cost.toString(),
    })
  }

  const trackRewardRedeemedEvent = () => {
    if (!reward) return
    trackEvent(isEarn ? RedEvents.EARN_GET_POINTS_CLICKED : RedEvents.SPEND_REDEEM_POINTS_CLICKED, {
      offer_id: reward.rewardId,
      offer_title: reward.content.title,
      offer_category: category,
      offer_points: reward.cost.toString(),
    })
  }

  return { trackRewardViewedEvent, trackRewardRedeemedEvent }
}
