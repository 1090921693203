import { useParams } from 'react-router-dom'
import { apiCreateStripeCheckoutSession } from '@vrw/data/src/api/subscriptions.api'
import { useQuery } from '@tanstack/react-query'
import { useSubscriptionPlanById } from '../../query/subscriptions'

export const useStripeCheckoutSession = () => {
  const { planId = '' } = useParams()
  const createCheckoutSession = useQuery({
    queryKey: ['createCheckoutSession', planId],
    queryFn: () => apiCreateStripeCheckoutSession(planId),
    enabled: !!planId,
    refetchOnMount: 'always',
  })
  const subscriptionPlan = useSubscriptionPlanById(planId)

  return {
    ...createCheckoutSession,
    plan: subscriptionPlan.data,
    clientSecret: createCheckoutSession.data?.clientSecret,
    isLoading: createCheckoutSession.isLoading || subscriptionPlan.isLoading,
  }
}
