import Cookies from 'js-cookie'
import { partnerLogin } from './partnerAuth'

const PARTNER_CONNECT_PARAMS = [
  'connectPartnerCode',
  'connectionId',
  'loginEmail',
  'onConnectSuccessUrl',
  'onConnectFailUrl',
  'partnerLinkAccessToken',
  'partnerLinkIdToken',
]

const handlePartnerLogin = () => {
  const params = new URLSearchParams(window.location.search)

  const partnerCode = params.get('partnerCode')
  const connectionId = params.get('connectionId')

  const loginParams = [
    { name: 'connectPartnerCode', value: partnerCode },
    { name: 'connectionId', value: connectionId },
    { name: 'loginEmail', value: params.get('loginEmail') },
    { name: 'onConnectSuccessUrl', value: params.get('onConnectSuccessUrl') },
    { name: 'onConnectFailUrl', value: params.get('onConnectFailUrl') },
  ]

  if (partnerCode && connectionId) {
    setPartnerLoginParams(loginParams)
    partnerLogin()
  }
}

const setPartnerLoginParams = (authResults: { name: string; value: string | null }[]) => {
  return authResults.map(({ name, value }) => {
    return value ? localStorage.setItem(name, value) : null
  })
}

const removeAllPartnerLoginParams = () => {
  return PARTNER_CONNECT_PARAMS.map((item) => {
    return localStorage.removeItem(item)
  })
}

const getPartnerLoginParams = () => {
  const connectPartnerCode = localStorage.getItem('connectPartnerCode')
  const connectionId = localStorage.getItem('connectionId')
  const loginEmail = localStorage.getItem('loginEmail')
  const onConnectSuccessUrl = localStorage.getItem('onConnectSuccessUrl') ?? ''
  const onConnectFailUrl = localStorage.getItem('onConnectFailUrl') ?? ''
  return {
    connectPartnerCode,
    connectionId,
    loginEmail,
    onConnectSuccessUrl,
    onConnectFailUrl,
  }
}

const persistPartnerLinkIdToken = (idToken: string): void => {
  const cookieName = 'partnerLinkIdToken'
  Cookies.set(cookieName, idToken, {
    expires: 28, //days
  })
}

export { handlePartnerLogin, setPartnerLoginParams, getPartnerLoginParams, removeAllPartnerLoginParams, persistPartnerLinkIdToken }
