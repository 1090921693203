import { AuthOptions, WebAuth } from 'auth0-js'
import { PublicClientApplication } from '@azure/msal-browser'
import { logger, LinkingJourneyOrigins } from '@vrw/data/src/utils'
import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import {
  AUTH0_AUTH_URL,
  AUTH0_CLIENT_ID,
  AUTH0_CHANGE_EMAIL_CLIENT_ID,
  AUTH0_API_AUDIENCE,
  VAA_AUTH_CLIENT_ID,
  VAA_AUTH_ISSUER,
  PING_AUTH_URL,
  PING_ACCOUNT_SPACE,
  PING_CLIENT_ID,
  PING_ACR_VALUE,
} from '../config'
import { FEATURE_FLAGS } from '../config'
import { PATHS } from '../router/paths'
import { RedDataConfig } from '@vrw/data/src/types'
import { navigate } from 'web/src/dataImplementation/navigation'

const redBasePath = `${window.location.protocol}//${window.location.host}/virgin-red`

const fetchConfig = (): UserManagerSettings => {
  const isVAA =
    window.location.pathname.includes(PATHS.ONBOARDING_PARTNER_LINK_VAA) ||
    (window.location.pathname.includes('login-success') && sessionStorage.getItem('isVAA') === 'true')
  sessionStorage.setItem('isVAA', isVAA.toString())
  const isVID = !isVAA && FEATURE_FLAGS.vidAuth

  return {
    automaticSilentRenew: false,
    authority: isVID ? PING_AUTH_URL || '' : AUTH0_AUTH_URL || '',
    client_id: isVID ? PING_CLIENT_ID || '' : AUTH0_CLIENT_ID || '',
    redirect_uri: `${redBasePath}/login-success`,
    response_type: 'code',
    scope: isVID ? 'openid profile email execute_api:user red_api' : 'openid profile email execute_api:user',
    extraQueryParams: {
      audience: isVID ? PING_ACCOUNT_SPACE || '' : AUTH0_API_AUDIENCE || '',
      post_logout_redirect_uri: isVID ? `${redBasePath}/logout-success` : '',
    },
    metadata: {
      authorization_endpoint: isVID ? `${PING_AUTH_URL}/authorize` : `${AUTH0_AUTH_URL}/authorize`,
      token_endpoint: isVID ? `${PING_AUTH_URL}/token` : `${AUTH0_AUTH_URL}/oauth/token`,
      end_session_endpoint: isVID ? `${PING_AUTH_URL}/signoff` : undefined,
    },
    // library defaults to session storage which can not be shared across tabs/multiple browser sessions
    // we alway requires it to be local (Note: we will likely need to clean up our existing storage of auth)
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  }
}

const config = fetchConfig()
const auth = new UserManager(config)

const authenticate: RedDataConfig['auth']['authenticate'] = async (options) => {
  try {
    options?.connection
      ? // external VAA auth
        await auth.signinRedirect({ extraQueryParams: { ...auth.settings.extraQueryParams, ...options } })
      : await auth.signinRedirect(options)
  } catch (error) {
    logger.warn('oidcClient:signinRedirect() failed: ', error)
  }
}

const postLoginCallback = async () => {
  try {
    return await auth.signinCallback()
  } catch (error) {
    // redirect home if login fails
    navigate(PATHS.HOME)

    return logger.warn('oidcClient:postLoginCallback() failed: ', error)
  }
}

const refresh = async () => {
  try {
    const response = await auth.signinSilent()

    return {
      accessToken: response?.access_token,
      idToken: response?.id_token,
      expiresIn: response?.expires_in,
    }
  } catch (error) {
    return logger.warn('oidcClient:refresh() failed: ', error)
  }
}

const stepUp = async () => {
  try {
    if (FEATURE_FLAGS.vidAuth) {
      await authenticate({ acr_values: PING_ACR_VALUE || '' })
    } else {
      await authenticate({
        scope: 'openid profile email user:mfa',
      })
    }
  } catch (error) {
    logger.warn('oidcClient:stepUp() failed: ', error)
  }
}

const stepUpTokenParsing = async () => {
  try {
    const response = await auth.getUser()
    return {
      accessToken: response?.access_token,
    }
  } catch (error) {
    return logger.warn('oidcClient:stepUpTokenParsing() failed: ', error)
  }
}

const logout = async (redirectPath?: string) => {
  try {
    if (FEATURE_FLAGS.vidAuth) {
      await auth.signoutRedirect()
    } else {
      const logoutConfig = {
        ...config,
        metadata: {
          end_session_endpoint: `${AUTH0_AUTH_URL}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${encodeURIComponent(
            `${redBasePath}${redirectPath ?? '/logout-success'}`
          )}`,
        },
      }
      const authLogout = new UserManager(logoutConfig)
      await authLogout.signoutRedirect()
    }
  } catch (err) {
    logger.warn('oidcClient:logout() failed: ', err)
  }
}

const updateEmailOptions: AuthOptions = {
  domain: String(AUTH0_AUTH_URL).replace('https://', '') || '',
  clientID: AUTH0_CHANGE_EMAIL_CLIENT_ID || '',
  responseType: 'token id_token',
}
const auth0UpdateEmailWebAuth = new WebAuth(updateEmailOptions)

const passwordless = async (email: string) => {
  new Promise((resolve, reject) => {
    auth0UpdateEmailWebAuth.passwordlessStart(
      {
        email,
        send: 'code',
        connection: 'email',
        authParams: {
          scope: 'openid',
        },
      },
      (error, response) => {
        if (error) {
          reject(error)
        } else {
          resolve(response)
        }
      }
    )
  })
}

const loginWithEmail = async (email: string, code: string) => {
  // Auth0’s authentication.oauthToken API does not send the OTP code through with the response.
  const response = await fetch(`${AUTH0_AUTH_URL}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({
      // Sonarcloud throws an error when it encounters a reference to a non-secure HTTP. Non-secure address required by Auth0
      // NOSONAR
      grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
      client_id: AUTH0_CHANGE_EMAIL_CLIENT_ID,
      username: email,
      otp: code,
      realm: 'email',
      scope: 'openid profile email',
    }),
  })
  if (!response.ok) {
    throw await response.json()
  }
}

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: VAA_AUTH_CLIENT_ID || '',
    authority: VAA_AUTH_ISSUER,
    knownAuthorities: ['avadeunloyaltyfcb2c.b2clogin.com', 'avateunloyaltyfcb2c.b2clogin.com', 'identity.virginatlantic.com'],
  },
})

const handleVaaAuth = async (journeyName?: LinkingJourneyOrigins): Promise<void> => {
  let redirectUri: string | undefined

  switch (journeyName) {
    case LinkingJourneyOrigins.ACCOUNT:
      redirectUri = `${redBasePath}/account/link-accounts`
      break
    case LinkingJourneyOrigins.ONBOARDING:
      redirectUri = `${redBasePath}/onboarding/link-vaa`
      break
    case LinkingJourneyOrigins.PARTNER:
      redirectUri = `${redBasePath}/onboarding/partner-link-vaa`
      break
    default:
      redirectUri = undefined
      break
  }

  try {
    msalInstance.loginRedirect({
      scopes: ['openid'],
      redirectUri,
    })
  } catch (error) {
    logger.warn('auth0Client.ts:handleVaaAuth() error: ', error)
  }

  return undefined
}

const getVaaToken = async (): Promise<{ idToken?: string }> => {
  try {
    const response = await msalInstance.handleRedirectPromise()
    return { idToken: response?.idToken }
  } catch (error) {
    logger.warn('auth0Client.ts:getVaaToken() error: ', error)
  }
  return {}
}

export {
  msalInstance,
  authenticate,
  refresh,
  logout,
  stepUp,
  stepUpTokenParsing,
  passwordless,
  loginWithEmail,
  handleVaaAuth,
  getVaaToken,
  postLoginCallback,
}
