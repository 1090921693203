import { connect } from 'react-redux'
import { OldActivityStatement } from './OldActivityStatement'
import { ActivityDispatchProps, ActivityStateProps } from './OldActivityStatement'
import {
  getHasLoaded,
  getIsRefreshing,
  getPendingPoints,
  getShowLoadMore,
  getStatement,
  getTotal,
} from '@vrw/data/src/redux/statement/selectors'
import { DispatchFunction } from '@vrw/data/src/redux/types'
import { doAppendStatement, doRefreshStatement } from '@vrw/data/src/redux/statement/dispatchers'
import { RootState } from '../../redux/types'

const mapDispatchToProps = (dispatch: DispatchFunction): ActivityDispatchProps => ({
  onMount: async (): Promise<void> => {
    await dispatch(doRefreshStatement())
  },
  onLoadMore: async (): Promise<void> => {
    await dispatch(doAppendStatement())
  },
})

const mapStateToProps = (state: RootState): ActivityStateProps => ({
  statement: getStatement(state),
  total: getTotal(state),
  isRefreshing: getIsRefreshing(state),
  hasLoaded: getHasLoaded(state),
  showLoadMore: getShowLoadMore(state),
  pendingPoints: getPendingPoints(state),
})

const OldActivityStatementContainer = connect(mapStateToProps, mapDispatchToProps)(OldActivityStatement)

export { OldActivityStatementContainer }
