import { connect } from 'react-redux'

import { getString } from '@vrw/data'
import { RewardEarn } from '@vrw/data/src/redux/rewardsEarn/types'
import { RedemptionType, Reward } from '@vrw/data/src/redux/rewards/types'
import { getIsLoading } from '@vrw/data/src/redux/discoveryPublic/selectors'
import { getIsLoadingEarnPublic } from '@vrw/data/src/redux/discoveryEarnPublic/selectors'
import { getAllRewardsIds as getAllRewardsEarnIds, getRewardById as getRewardEarnById } from '@vrw/data/src/redux/rewardsEarn/selectors'
import { getWishlistRewards, getIsLoadingWishlist } from '@vrw/data/src/redux/wishlist/selectors'
import { getAllRewardsIds, getRewardById } from '@vrw/data/src/redux/rewards/selectors'
import { dofetchHomePageEarnRewards } from '@vrw/data/src/redux/rewardsEarn/dispatchers'
import { doFetchHomePageRewards } from '@vrw/data/src/redux/rewards/dispatchers'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist } from '@vrw/data/src/redux/wishlist/dispatchers'
import { convertRewardToCard } from '@vrw/data/src/utils/convertRewardToCard'
import { getRecommendedItemsCarousel } from '@vrw/data/src/utils/getRecommendedItemsCarousel'

import { DispatchFunction, RootState } from '../../../redux/types'
import { PATHS, virginRedPath } from '../../../router/paths'
import { CardTypes, IntroCarouselBlockPrivateContainerProps } from '../types'
import { IntroCarouselTileProps } from './IntroCarouselTile'
import { IntroCarouselBlock, IntroCarouselType } from './IntroCarouselBlock'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'
import { selectFeatureById } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { PUBLIC_URL } from '../../../config'

const MAX_NUMBER_ITEMS = 8

const RED_CARPET_SUBSCRIPTION_ITEM_DATA = {
  rewardId: '34327',
  rewardParentId: '122',
  redemptionType: RedemptionType.WINES_VOUCHER,
  cost: 50,
  name: 'Test 1',
  content: {
    title: 'Extra benefits with Red Carpet subscription',
    lead: 'Enjoy piping hot points delivered to your door when you order from JustEat',
    imageUrl: `${PUBLIC_URL}/img/red_carpet_example.png`,
    brandDetails: {
      brandName: 'Virgin Vines',
      brandLogo: `${PUBLIC_URL}/img/virgin_vines_logo.png`,
    },
    subscriptionPlanId: 'price_1PlSV3RwjBx3JuD2yqS453fc',
    slug: 'gbp50-virgin-wines-gift-voucher',
  },
} as Reward

const RED_HOT_SUBSCRIPTION_ITEM_DATA = {
  rewardId: '1219009',
  rewardParentId: '123',
  cost: 2000,
  name: 'Test 2',
  content: {
    title: 'Virgin Atlantic sale with fares from £326 London to New York',
    lead: 'Virgin Atlantic launch sale fares to selected destinations. Book by 31 January 2022.',
    imageUrl: `${PUBLIC_URL}/img/red_hot_example.png`,
    brandDetails: {
      brandName: 'Virgin Atlantic',
      brandLogo: `${PUBLIC_URL}/img/virgin_atlantic_logo.png`,
    },
    subscriptionPlanId: 'price_1PlSVyRwjBx3JuD2N0f5Hc52',
    slug: 'virgin-trains-ticketing',
  },
} as Reward

export const SUBSCRIPTION_ITEMS = [RED_CARPET_SUBSCRIPTION_ITEM_DATA, RED_HOT_SUBSCRIPTION_ITEM_DATA]

export const getSpendItemsCarousel = (state: RootState, start: number = 4): Reward[] => {
  const spendIds = getAllRewardsIds(state).slice(start, MAX_NUMBER_ITEMS + start)
  return spendIds.map((spendId) => getRewardById(state, spendId))
}

export const getEarnItemsCarousel = (state: RootState, start: number = 4): RewardEarn[] => {
  const earnIds = getAllRewardsEarnIds(state).slice(start, MAX_NUMBER_ITEMS + start)
  return earnIds.map((earnId) => getRewardEarnById(state, earnId))
}

export const getTitleCarousel = (type: CardTypes) => {
  let title = ''
  switch (type) {
    case CardTypes.EARN:
      title = getString('home.introCarousel.title.earn')
      break
    case CardTypes.SPEND:
      title = getString('home.introCarousel.title.spend')
      break
    case CardTypes.FEATURED:
      title = getString('home.introCarousel.title.recommended')
  }
  return title
}

export const getItemsCarousel = (state: RootState, type: CardTypes): IntroCarouselTileProps[] => {
  let items: (Reward | RewardEarn)[] = []
  switch (type) {
    case CardTypes.SPEND:
      items = getSpendItemsCarousel(state)
      break
    case CardTypes.EARN:
      items = getEarnItemsCarousel(state)
      break
    case CardTypes.FEATURED: {
      const webSubscriptionFlag = selectFeatureById(state, FeatureName.WEB_SUBSCRIPTION)
      items = getRecommendedItemsCarousel(state)
      if (webSubscriptionFlag) {
        items = [...SUBSCRIPTION_ITEMS, ...items.slice(0, -SUBSCRIPTION_ITEMS.length)]
      }
    }
  }
  return convertRewardToCard(items)
}

export const doFetchAllCarousel = async (type: CardTypes, dispatch: DispatchFunction) => {
  switch (type) {
    // case CardTypes.FEATURED:
    // (ommitted so as not to cause a duplicate call - already has info from CardTypes.EARN)

    case CardTypes.EARN:
      // we limit the rewards fetch to 12 in the private Homepage
      // (4 for Featured and 8 for Carousel) to improve performance
      await dofetchHomePageEarnRewards(12)(dispatch)
      break
    case CardTypes.SPEND:
      await doFetchHomePageRewards(12)(dispatch)
      break
    default:
  }
}

const mapStateToProps = (state: RootState, { type, showAllLink }: IntroCarouselBlockPrivateContainerProps) => ({
  title: getTitleCarousel(type),
  seeAllLink: showAllLink ? virginRedPath + (type === CardTypes.EARN ? PATHS.EARN : PATHS.SPEND) : undefined,
  items: () => getItemsCarousel(state, type),
  isLoading: type === CardTypes.EARN ? getIsLoadingEarnPublic(state) : getIsLoading(state),
  type: type === CardTypes.EARN ? IntroCarouselType.EARN : IntroCarouselType.REWARD,
  wishlist: getWishlistRewards(state),
  isLoadingWishlist: getIsLoadingWishlist(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction, { type }: IntroCarouselBlockPrivateContainerProps) => ({
  doGetItems: () => doFetchAllCarousel(type, dispatch),
  ...bindTypedActionCreators(
    {
      doRemoveRewardFromWishlist,
      doAddRewardToWishlist,
    },
    dispatch
  ),
})

export const IntroCarouselBlockPrivateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroCarouselBlock) as unknown as React.FC<IntroCarouselBlockPrivateContainerProps>
