import { connect } from 'react-redux'
import { doFetchRewardsPublic } from '@vrw/data/src/redux/rewardsPublic/dispatchers'
import { RootState, DispatchFunction } from '../../redux/types'
import { RewardsList } from './RewardsList'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import {
  getIsLoading as getIsLoadingAllSpend,
  getHasLoaded as getHasLoadedAllSpend,
  getRewardsEntities,
  getRewardsIds,
  getPublicPagination,
} from '@vrw/data/src/redux/rewardsPublic/selectors'
import { getIsLoading, getIsFiltersChanged, getVisibleSpendRewardIds } from '@vrw/data/src/redux/discoveryPublic/selectors'
import { doUpdateRewardsPublicPaginated } from '@vrw/data/src/redux/discoveryPublic/dispatchers'
import { toggleCategoryPublicSelected } from '@vrw/data/src/redux/discoveryPublic/actions'
import { getCategories } from '@vrw/data/src/redux/categoriesPublic/selectors'

const mapStateToProps = (state: RootState, ownProps: { ignoreFilters?: boolean }) => {
  return {
    categories: getCategories(state),
    rewards: getRewardsEntities(state),
    rewardIds: ownProps.ignoreFilters ? getRewardsIds(state) : getVisibleSpendRewardIds(state),
    earn: false,
    isLoading: ownProps.ignoreFilters ? getIsLoadingAllSpend(state) : getIsLoading(state),
    hasLoaded: ownProps.ignoreFilters ? getHasLoadedAllSpend(state) : !getIsFiltersChanged(state),
    pagination: getPublicPagination(state),
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction, ownProps: { ignoreFilters?: boolean }) =>
  bindTypedActionCreators(
    {
      doRefreshRewards: (loadMoreClicked?: boolean) =>
        ownProps.ignoreFilters ? doFetchRewardsPublic : doUpdateRewardsPublicPaginated(loadMoreClicked),
      toggleCategorySelected: toggleCategoryPublicSelected,
      doUpdateRewards: doUpdateRewardsPublicPaginated,
    },
    dispatch
  )

export const PublicSpendRewardsListContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsList)
