import { connect } from 'react-redux'

import { DispatchFunction } from '@vrw/data/src/redux/types'
import { getRewardById } from '@vrw/data/src/redux/rewards/selectors'
import { getOrderInProgress } from '@vrw/data/src/redux/orders/selectors'
import { doSetToastMessage } from '@vrw/data/src/redux/toast/dispatchers'
import { RedemptionType } from '@vrw/data/src/redux/rewards/types'
import { doUpdateOrderConsent, doPromptSubmitOrder, doUpdateGiftMessage } from '@vrw/data/src/redux/orders/dispatchers'

import { OrderCheckout } from './OrderCheckout'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'
import { RootState } from '../../../redux/types'

const mapStateToProps = (state: RootState, ownProps: any) => {
  const reward = getRewardById(state, getOrderInProgress(state).rewardId)
  return {
    isSubmitting: state.orders.isSubmitting,
    reward: reward,
    order: getOrderInProgress(state),
    showTermsAndConditions: reward && reward.redemptionType === RedemptionType.WINES_FULFILMENT,
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUpdateOrderConsent,
      doUpdateGiftMessage,
      doSetToastMessage,
      doPromptSubmitOrder,
    },
    dispatch
  )

const OrderCheckoutContainer = connect(mapStateToProps, mapDispatchToProps)(OrderCheckout)

export { OrderCheckoutContainer }
