import { useQuery } from '@tanstack/react-query'
import { apiGetSubscriptionPlan } from '@vrw/data/src/api/subscriptions.api'

export const useSubscriptionPlanById = (planId = '') => {
  return useQuery({
    queryKey: ['subscriptionPlan', planId],
    queryFn: () => apiGetSubscriptionPlan(planId),
    enabled: !!planId,
  })
}
