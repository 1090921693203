import { connect } from 'react-redux'

import { doLoginWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { DispatchFunction } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'

import { EmailUpdatedSuccess } from './EmailUpdatedSuccess'

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogin: () => doLoginWeb(),
    },
    dispatch
  )

const EmailUpdatedSuccessContainer = connect(undefined, mapDispatchToProps)(EmailUpdatedSuccess)

export { EmailUpdatedSuccessContainer }
