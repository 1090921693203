import { Document } from '@contentful/rich-text-types'
import { Wishlist } from '@vrw/data/src/redux/wishlist/types'
import { ContentfulImage, ContentfulObject } from '@vrw/data/src/utils/contentfulPages'

import { AccordionOption } from '../Accordion/AccordionItem'
import { HeroBlockBackgroundAnchor, HeroBlockDesktopTextPosition, HeroBlockMobileTextPosition } from './HeroBlock'
import type { IntroCarouselBlockProps } from './IntroCarouselBlock/IntroCarouselBlock'

export enum BlockTypes {
  RICH_TEXT_BLOCK = 'blockRichText',
  IMAGE_BLOCK = 'blockImage',
  HERO_BLOCK = 'blockHero',
  RED_HOT_BANNER = 'blockRedHotBanner',
  SOCIAL_MEDIA_POST = 'socialMediaPost',
  EMBEDDED_CONTENT_BLOCK = 'blockEmbeddedContent',
  IMAGE_EMBEDDED_CONTENT = 'blockImageEmbeddedContent',
  HOW_IT_WORKS = 'homePageHowItWorks',
  FAQ_BLOCK = 'homePageFaqSection',
}

export interface ContentfulBlock<T> extends ContentfulObject {
  fields: T
}

export interface RichTextBlockFields {
  text: Document
}

export interface ImageBlockFields {
  caption: string
  image: ContentfulImage
  width: 'FullWidth' | 'ParagraphWidth'
  aspectRatio: '16/9' | '4/3' | '1/1'
}

export interface HeroBlockFields {
  headline: string
  body: Document
  backgroundImageHorizontal?: ContentfulImage
  backgroundImageVertical?: ContentfulImage
  foregroundImage?: ContentfulImage
  button1Text?: string
  button1Target?: string
  button1Image?: object
  button2Text?: string
  button2Target?: string
  button2Image?: object
  backgroundAnchor?: HeroBlockBackgroundAnchor
  desktopTextPosition?: HeroBlockDesktopTextPosition
  mobileTextPosition?: HeroBlockMobileTextPosition
}

type ButtonImage = {
  fields: {
    description?: string
    file: {
      url: string
    }
  }
}

export interface DownloadAppBlockFields {
  headline: string
  body: Document
  foregroundImage?: ContentfulImage
  button1Image: ButtonImage
  button1Target: string
  button2Image: ButtonImage
  button2Target: string
  backgroundAnchor?: HeroBlockBackgroundAnchor
  desktopTextPosition?: HeroBlockDesktopTextPosition
  mobileTextPosition?: HeroBlockMobileTextPosition
}

export interface PropositionCarouselFields {
  heroBlocks: ContentfulBlock<HeroBlockFields>[]
}

export interface IntroCarouselBlockFields {
  header?: ContentfulBlock<HeroBlockFields>
  sliderContentType: string
}

export interface RedHotBannerBlockFields {
  image?: ContentfulImage
  body: Document
  button1Text?: string
  button1Target?: string
  button2Text?: string
  button2Target?: string
}

export interface EmbeddedContentBlockFields {
  html: string
  type: string
  forwardUrlParameters?: boolean
}

export interface ImageEmbeddedContentBlockFields {
  topHero?: boolean
  backgroundColour: { fields: { colour: string } }
  backgroundImage?: ContentfulImage
  textColour?: boolean
  headline?: string
  foregroundImage: ContentfulImage
  embeddedContent: { fields: EmbeddedContentBlockFields }
}

export interface IntroCarouselBlockPrivateContainerProps extends Pick<IntroCarouselBlockProps, 'title' | 'header' | 'disableWishlist'> {
  type: CardTypes
  showAllLink?: boolean
  wishlist?: Wishlist
  isLoadingWishlist?: boolean
}

export interface ImagesTextBlockFields {
  body: string
  headline: string
  buttonText?: string
  buttonTarget?: string
  backgroundColour: { fields: { colour: string } }
  processStepSubSection: ImageFields[]
}

export interface ImageFields {
  fields: ImageBodyText
}

interface ImageBodyText {
  header: string
  bodyText: string
  image: ContentfulImage
}

export interface FaqBlockFields {
  headline: string
  buttonText?: string
  buttonTarget?: string
  backgroundColour: { fields: { colour: string } }
  accordions: AccordionFields[]
}

export interface AccordionFields {
  fields: AccordionOption
}

export enum CardTypes {
  EARN = 'Earn',
  SPEND = 'Spend',
  FEATURED = 'Featured',
  ELIGIBLE_BENEFITS = 'EligibleBenefits',
}
