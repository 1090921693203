import React, { useEffect } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { GridContainer, GridItem, GridWrapper } from '@virgin-core/components'
import { getString } from '@vrw/data'
import { WishlistReward, WishListIcon } from '..'
import { media } from '../../style/responsive'
import { color } from '../../style/variables'
import { H3Header } from '../typography'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { getIsLoadingWishlist, getWishlistRewards } from '@vrw/data/src/redux/wishlist/selectors'
import { doGetWishlist, doRemoveRewardFromWishlist } from '@vrw/data/src/redux/wishlist/dispatchers'

export const Wishlist = () => {
  const dispatch = useAppDispatch()

  const wishlist = useAppSelector(getWishlistRewards)
  const isLoadingWishlist = useAppSelector(getIsLoadingWishlist)
  const isMobile = useMediaPredicate(media.mobileAndLower)

  useEffect(() => {
    dispatch(doGetWishlist())
  }, [dispatch])

  const getOnWishListIconClick = (rewardId: string, earnType: string) => {
    return () => dispatch(doRemoveRewardFromWishlist(rewardId, earnType))
  }

  return (
    <>
      <style jsx>{`
        .wishlist-container {
          width: 100%;
          margin-bottom: 74px;
        }
        .wishlist-empty-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 15px;
          border-radius: 8px;
          border: solid 1px ${color.lightGrey};
          margin: 0 10px;
        }
        .wishlist-empty-description {
          color: ${color.lighterGrey};
          margin-left: 16px;
          font-size: 16px;
          line-height: 26px;
          max-width: 264px;
        }
        .wishlist-empty-wrapper {
          display: flex;
          align-items: center;
        }
        :global(.wishlist-grid-container) {
          max-height: 340px;
          overflow-x: auto;
          gap: 8px 0px;
        }
        :global(.grid-item.wishlist-reward-container) {
          padding: 0 8px;
        }
        @media ${media.mobileAndLower} {
          :global(.grid-wrapper.wishlist-reward-wrapper) {
            margin: 0;
          }
          :global(.wishlist-grid-container) {
            padding: 0 14px 12px 12px;
          }
        }
        @media ${media.tabletAndHigher} {
          .wishlist-container {
            padding: 0px;
          }
          .wishlist-empty-container {
            padding: 18px 0;
          }
          .wishlist-empty-description {
            max-width: none;
          }
          :global(.wishlist-grid-container) {
            gap: 16px 0px;
            max-height: none;
            overflow-x: none;
            padding: 0 6px;
          }
          .wishlist-empty-container {
            margin: 0;
          }
        }
        @media ${media.desktopAndHigher} {
          .wishlist-empty-container {
            padding: 30px 0;
          }
          .wishlist-empty-description {
            font-size: 20px;
          }
        }
        @media ${media.hiResDesktop} {
          .wishlist-container {
            max-width: 1440px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      `}</style>
      <div data-testid="wishlist" className="wishlist-container">
        <GridWrapper expanded>
          <GridContainer justifyContent="flex-start" alignItems="flex-start">
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <H3Header
                textAlign="left"
                weight={600}
                marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                marginBottom={{ mobile: 15, tablet: 15, desktop: 15 }}
                fontSize={{ mobile: 20, tablet: 28, desktop: 40 }}
              >
                {getString('home.favourites.title')}
              </H3Header>
            </GridItem>
          </GridContainer>
        </GridWrapper>

        <GridWrapper expanded className="wishlist-reward-wrapper">
          {wishlist?.rewards.length ? (
            <GridContainer key="wishlist-reward-list" direction={isMobile ? 'column' : 'row'} className="wishlist-grid-container">
              {wishlist.rewards.map((item, index) => (
                <GridItem
                  xs={10}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  className="wishlist-reward-container"
                  key={`wishlist-reward-grid-item-${index}`}
                >
                  <WishlistReward
                    reward={item}
                    isWished
                    onWishListIconClick={getOnWishListIconClick(item.rewardId, item.type)}
                    isLoadingWishlist={isLoadingWishlist}
                    data-testid={`wishlist-reward-${index}`}
                  />
                </GridItem>
              ))}
            </GridContainer>
          ) : (
            <GridContainer justifyContent="center" alignItems="flex-start">
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="wishlist-empty-container" data-testid="wishlist-empty">
                  <div className="wishlist-empty-wrapper">
                    <WishListIcon sizeKey={isMobile ? 'small' : 'large'} />
                    <div className="wishlist-empty-description">{getString('home.favourites.empty.tile')}</div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </GridWrapper>
      </div>
    </>
  )
}
