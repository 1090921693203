import { styled, Stack, YStack, XStack, Text, Image, Button, H4 } from '@red-ui/components/'
import { PUBLIC_URL } from '../../config'

export const PageWrapper = styled(YStack, {
  paddingTop: '$4',
  minHeight: '80vh',
})

export const BlockWrapper = styled(Stack, {
  flexDirection: 'row',
  alignItems: 'center',
  marginHorizontal: 'auto',
  marginVertical: 'auto',
  $tabletPortrait: {
    flexDirection: 'column-reverse',
  },
})

export const TextSectionWrapper = styled(YStack, {
  maxWidth: '350px',
  width: '100%',
  marginBottom: '$4',
  marginRight: '$2',
  $mobile: {
    marginRight: '$0',
  },
})

export const ImageSectionWrapper = styled(YStack, {
  maxWidth: '350px',
  width: '100%',
  alignItems: 'center',
  marginBottom: '$2',
})

export const HeaderText = styled(H4, {
  marginBottom: '$3',
})

export const SubtitleText = styled(Text, {
  fontSize: '$5',
  lineHeight: '$5',
  marginBottom: '$2',
})

export const BenefitItemWrapper = styled(XStack, {
  marginBottom: '$2',
})

export const HeroImage = styled(Image, {
  source: {
    uri: `${PUBLIC_URL}/img/CAMPAIGN-TRAINS-HERO.jpg`,
    width: 320,
    height: 400,
  },
})

export const ConfirmButton = styled(Button, {
  width: '100%',
  size: '$5',
  marginTop: '$3',
})
