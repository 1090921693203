import React from 'react'
import { getString } from '@vrw/data'
import { H2, YStack, XStack, Text, Separator, Spinner } from '@red-ui/components/'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Page } from '../../components'
import { STRIPE_PUBLISHABLE_KEY } from '../../config'
import {
  PageWrapper,
  LogoImageWrapper,
  LogoImage,
  BoldText,
  SecondaryText,
  RightText,
  PriceInfoWrapper,
  InfoIcon,
  PaymentDescriptionText,
  SpinnerContainer,
} from './styles'
import { useStripeCheckoutSession } from './useStripeCheckoutSession'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

export const SubscriptionPayment = () => {
  const { clientSecret, isLoading, plan } = useStripeCheckoutSession()
  const isAnnualSubscription = plan?.frequency === 'YEAR'
  const frequencyCopyKey = plan?.frequency.toLowerCase()

  return (
    <Page title={getString('subscriptionPayment.page.title')}>
      <PageWrapper>
        {isLoading || !plan ? (
          <SpinnerContainer>
            <Spinner size="large" color="$red6" />
          </SpinnerContainer>
        ) : (
          <>
            <H2 marginBottom="$2">{getString('subscriptionPayment.page.headerText')}</H2>
            <XStack marginVertical="$2">
              <LogoImageWrapper>
                <LogoImage />
              </LogoImageWrapper>
              <YStack>
                <BoldText>{plan.name}</BoldText>
                <SecondaryText>
                  {getString('subscriptionPayment.page.subscriptionPeriod').replace(
                    '{{period}}',
                    getString(`subscriptionPayment.page.subscriptionPeriod.${frequencyCopyKey}`)
                  )}
                </SecondaryText>
              </YStack>
            </XStack>
            <YStack>
              <PriceInfoWrapper marginBottom="$2">
                <YStack>
                  <Text>{plan.name}</Text>
                </YStack>
                <YStack>
                  <RightText>
                    {plan.cost} / {getString(`subscriptionPayment.page.cost.${frequencyCopyKey}`)}
                  </RightText>
                </YStack>
              </PriceInfoWrapper>

              <Separator marginVertical="$1" />

              <XStack marginBottom="$2" justifyContent="space-between">
                <BoldText>{getString('subscriptionPayment.page.total')}</BoldText>
                <BoldText textAlign="right">{plan.cost}</BoldText>
              </XStack>
            </YStack>
            <XStack marginBottom="$3" alignItems="center">
              <InfoIcon />
              <PaymentDescriptionText>
                {getString('subscriptionPayment.page.paymentDescription')
                  .replace('{{totalSum}}', plan.cost)
                  .replace('{{billingPeriodMoths}}', isAnnualSubscription ? '12' : '')}
              </PaymentDescriptionText>
            </XStack>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </>
        )}
      </PageWrapper>
    </Page>
  )
}
