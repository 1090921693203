import { connect } from 'react-redux'

import { doFetchTermsAndConditions } from '@vrw/data/src/redux/termsAndConditions/dispatchers'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { DispatchFunction } from '../../redux/types'

import { TermsAndConditions } from './TermsAndConditions'

const mapDispatchToProps = (dispatch: DispatchFunction) => bindTypedActionCreators({ doFetchTermsAndConditions }, dispatch)

const TermsAndConditionsContainer = connect(undefined, mapDispatchToProps)(TermsAndConditions)

export { TermsAndConditionsContainer }
