import React, { useEffect, useState } from 'react'
import { getString } from '@vrw/data'
import { fetchBalance } from '@vrw/data/src/redux/balance/balance.thunk'
import { getBalance } from '@vrw/data/src/redux/balance/balance.selectors'

import { FontFamilies, color, ZIndex } from '../../style/variables'
import { PointsSupport } from '../index'
import { TextId } from '../TextId/TextId'
import { CurvedContainer } from '../CurvedContainer'
import H1Header from '../typography/Red/H1Header'
import QuestionIcon from '../icons/QuestionIcon'
import QuestionIconAlt from '../icons/QuestionIconAlt'
import { Points } from '../Points'
import { media } from '../../style/media'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'

const ActivityBalance = () => {
  const dispatch = useAppDispatch()
  const balance = useAppSelector(getBalance)
  useEffect(() => {
    dispatch(fetchBalance())
  }, [dispatch])

  let points = balance?.current
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleOnClick = () => setTooltipVisible(true)

  return (
    <>
      <style jsx>{`
        .banner {
          background-color: ${color.darkPinkBackground};
          height: 289px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .points {
          color: ${color.brandPrimary};
          font-weight: 600;
          font-style: italic;
          text-align: center;
          font-size: 28px;
          line-height: 1.14;
          letter-spacing: 0.6px;
          margin: 8px 0;
        }
        .points-container {
          margin-bottom: 16px;
        }
        .cant-see-points {
          display: inherit;
          cursor: default;
          z-index: ${ZIndex.carouselBoxShadow};
        }
        .text-cant-see-points {
          color: ${color.brandPrimary};
          cursor: pointer;
          font-family: ${FontFamilies.barlow};
          font-weight: bold;
        }
        .question-icon,
        .question-icon-alt {
          cursor: pointer;
          margin-right: 6px;
        }
        .question-icon {
          display: ${tooltipVisible ? 'none' : 'inline-block'};
        }
        .question-icon-alt {
          display: ${tooltipVisible ? 'inline-block' : 'none'};
        }
        .text-caption {
          font-size: 12px;
          text-align: center;
          margin: 0;
        }
        @media ${media.mobileAndLower} {
          .header :global(.header-one) {
            font-size: 36px;
          }
        }
        @media ${media.tablet} {
          .header :global(.header-one) {
            font-size: 44px;
          }
        }
        @media ${media.tabletAndHigher} {
          .banner {
            height: 332px;
          }
          .points {
            font-size: 42px;
            line-height: 1.05;
            letter-spacing: 1px;
            margin: 16px 0;
          }
        }
        @media ${media.desktopAndHigher} {
          .banner {
            height: 398px;
          }
          .points-container {
            margin-bottom: 24px;
          }
        }
      `}</style>
      <CurvedContainer position="bottom">
        <div className="banner">
          <div className="header">
            <H1Header
              color={color.black}
              marginTop={{ mobile: 48, tablet: 48, desktop: 88 }}
              marginBottom={{ mobile: 16, tablet: 16, desktop: 24 }}
              textTransform={'uppercase'}
            >
              <TextId id="activity.title" />
            </H1Header>
          </div>
          <p className="text-caption">{getString('activity.balance.available')}</p>
          <div className="points-container">
            <div className="points">
              <Points value={points?.toLocaleString() || ''} />
            </div>
            <p className="text-caption">
              {getString(`${points === 1 ? 'activity.balance.virginPoint' : 'activity.balance.virginPoints'}`)}
            </p>
          </div>
          <div className="cant-see-points" data-testid="cant-see-points">
            <span className="question-icon" onClick={handleOnClick} data-testid="question-icon">
              <QuestionIcon size={22} color={color.brandPrimary} />
            </span>
            <span className="question-icon-alt">
              <QuestionIconAlt size={22} color={color.brandPrimary} />
            </span>
            <div className="text-cant-see-points" onClick={handleOnClick} data-testid="points-support">
              {getString('activity.pointsSupport.title')}
            </div>
          </div>
          {tooltipVisible && <PointsSupport closeModal={() => setTooltipVisible(false)} data-testid="points-support-modal" />}
        </div>
      </CurvedContainer>
    </>
  )
}

export { ActivityBalance }
