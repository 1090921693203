import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { getString } from '@vrw/data'
import { ViewingRegion, getRegionDisplayName } from '@vrw/data/src/utils'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { RootState } from '../../redux/types'
import { media } from '../../style/media'
import { color } from '../../style/variables'
import { Content } from '../layout'
import { PATHS } from '../../router/paths'
import { GlobeIcon } from '../icons'
import { useRegionSelector } from '../../context/RegionSelectorProvider'
import { getLocale, localeToRegion, localizePath } from '../../dataImplementation/viewingRegionWeb'
import { useAppSelector } from '../../redux/hooks/hooks'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'

const RedFooter: FC = () => {
  const isAuthenticated = useAppSelector((state: RootState) => getIsAuthenticated(state))
  const viewingRegion = localeToRegion(getLocale())
  const { showRegionSelectorModal } = useRegionSelector()
  const userProfileCountry = useAppSelector(getProfileData)?.country
  const viewingGB = userProfileCountry === ViewingRegion.GB || viewingRegion === ViewingRegion.GB
  const viewingUS = userProfileCountry === ViewingRegion.US || viewingRegion === ViewingRegion.US

  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${color.brandPrimary};
          width: 100%;
        }
        .container {
          margin: 0;
          padding: 0;
          list-style-type: none;
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-evenly;
          max-width: 1440px;
          min-height: 65px;
          padding: 16px;
          width: 100%;
        }
        .item {
          color: ${color.white};
          flex: 1 0 50%;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          padding: 22px 5px;
        }
        :global(.item-link) {
          color: ${color.white};
          text-decoration: none;
        }
        .region-switch-button {
          display: flex;
          align-items: center;
          border: 0;
          background: none;
          font: inherit;
          padding: 0;
          margin: auto;
          cursor: pointer;
        }
        .region-switch-button[disabled] {
          cursor: initial;
        }
        .region-name {
          padding-left: 10px;
        }
        // TODO: do hover style for a:hover
        @media ${media.tabletAndHigher} {
          .container {
            padding: 0;
          }
          .item {
            flex: 1 0 25%;
          }
        }
        @media ${media.desktopAndHigher} {
          .item {
            flex: 1 0 20%;
            font-size: 16px;
          }
        }
      `}</style>
      <nav className="wrapper" aria-label="Virgin Red footer navigation" data-testid="red-footer">
        <Content>
          <ul className="container">
            <li className="item">
              <Link className="item-link" to={localizePath(PATHS.PRIVACY)} data-testid="privacy-link">
                {getString('redFooter.privacyPolicy')}
              </Link>
            </li>
            <li className="item">
              <Link className="item-link" to={localizePath(PATHS.TERMS_AND_CONDITIONS)} data-testid="terms-link">
                {getString('redFooter.termsConditions')}
              </Link>
            </li>
            <li className="item">
              <Link className="item-link" to={localizePath(PATHS.MODERN_SLAVERY_STATEMENT)} data-testid="modern-slavery-link">
                {getString('redFooter.modernSlaveryStatement')}
              </Link>
            </li>
            <li className="item">
              <a
                className="item-link"
                href={getString('link.account.help')}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="account-help">
                {getString('redFooter.help')}
              </a>
            </li>
            {viewingUS && (
              <li className="item">
                <Link className="item-link" to={localizePath(`${PATHS.REWARDS_CREDIT_CARD}?siteCode=VRCOMFOOT`)}>
                  {getString('redFooter.rewardsCreditCard')}
                </Link>
              </li>
            )}
            {viewingGB ? (
              <li className="item">
                <a
                  className="item-link"
                  href={isAuthenticated ? localizePath(PATHS.MY_ACCOUNT_REFER_DASHBOARD) : localizePath(PATHS.FOOTER_REFER_A_FRIEND)}
                  rel="noopener noreferrer">
                  {getString('refer.a.friend.title')}
                </a>
              </li>
            ) : null}
            <li className="item">
              <button
                className="item-link region-switch-button"
                onClick={showRegionSelectorModal}
                disabled={isAuthenticated}
                data-testid="region-switch-button">
                <GlobeIcon color={color.white} size={20} />
                <span className="region-name">{getRegionDisplayName(viewingRegion)}</span>
              </button>
            </li>
          </ul>
        </Content>
      </nav>
    </>
  )
}

export { RedFooter }
