import { useQuery } from '@tanstack/react-query'
import { getString } from '@vrw/data'
import { apiGetSubscriptionPlans, SubscriptionPlan } from '@vrw/data/src/api/subscriptions.api'

export const FREE_PLAN = {
  id: 'free',
  name: getString('subscribe.page.content.freePlan.name'),
  cost: getString('subscribe.page.content.freePlan.cost'),
  frequency: '',
  features: [
    { description: getString('subscribe.page.content.freePlan.feature.1') },
    { description: getString('subscribe.page.content.freePlan.feature.2') },
  ],
} as unknown as SubscriptionPlan

export const useSubscriptionPlans = () => {
  const plansQuery = useQuery({
    queryKey: ['subscriptionPlans'],
    queryFn: apiGetSubscriptionPlans,
  })
  const allPlans = [...(plansQuery.data ?? []), FREE_PLAN]
  const allFeatures = Array.from(new Set(allPlans.flatMap((plan) => plan.features.map((feature) => feature.description))))

  return { ...plansQuery, allPlans, allFeatures }
}
