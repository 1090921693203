import { connect } from 'react-redux'

import { doSubmitOnboarding } from '@vrw/data/src/redux/profile/dispatchers'
import { doFetchTermsAndConditions } from '@vrw/data/src/redux/termsAndConditions/dispatchers'
import { getIsUpdating } from '@vrw/data/src/redux/profile/selectors'
import { getTermsAndConditions } from '@vrw/data/src/redux/termsAndConditions/selectors'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'
import { DispatchFunction, RootState } from '../../../redux/types'
import { PersonalDetails } from './PersonalDetails'

const mapStateToProps = (state: RootState) => ({
  isUpdating: getIsUpdating(state),
  termsVersion: getTermsAndConditions(state)?.version,
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doSubmitOnboarding,
      doFetchTermsAndConditions,
    },
    dispatch
  )

const PersonalDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)

export { PersonalDetailsContainer }
