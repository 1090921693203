import { connect } from 'react-redux'

import { getTermsAndConditionsBody, getTermsAndConditionsVersion, getIsLoading } from '@vrw/data/src/redux/termsAndConditions/selectors'

import { DynamicContent } from '../../components'
import { RootState } from '../../redux/types'

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: getIsLoading(state),
    body: getTermsAndConditionsBody(state),
    version: getTermsAndConditionsVersion(state),
  }
}

const DynamicContentContainer = connect(mapStateToProps)(DynamicContent)

export { DynamicContentContainer }
