import { exchangeVoucher, exchangeVoucherSuccess, exchangeVoucherFailure } from '../actions'
import { logger } from '../../../utils'
import { apiErrors } from '../../../utils/httpClient'
import { doRefreshVouchers, doViewVoucher } from '../../wallet/dispatchers'
import { getVoucherById } from '../../wallet/selectors'
import { showWalletBadge } from '../../wallet/actions'
import { BadgeType } from '../../wallet/types'
import { apiPostExchangeVoucher } from '../../../api/exchange.api'
import { Reward } from '../types'
import { doStepUpWeb, doStepDown } from '../../auth/dispatchers'
import { getIsSteppedUp } from '../../auth/selectors'

export const doVoucherRedemptionFlow =
  (reward: Reward, stepUp = false) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    const { rewardId, rewardParentId } = reward
    logger.log(`doVoucherRedemptionFlow(${rewardId}, ${rewardParentId})`)
    dispatch(exchangeVoucher(rewardId))
    try {
      const voucherId = await apiPostExchangeVoucher(rewardId, rewardParentId, stepUp)
      await dispatch(doRefreshVouchers())
      if (!getVoucherById(getState(), voucherId)) {
        throw Error(`voucher.ts:doVoucherRedemptionFlow(): Failed to get voucher with id ${voucherId}`)
      }
      dispatch(showWalletBadge(BadgeType.NEW_VOUCHER))
      dispatch(exchangeVoucherSuccess())
      dispatch(doViewVoucher(voucherId))
      dispatch(doStepDown())
    } catch (error) {
      if ((error as Error)?.message === apiErrors.NOT_ACCEPTABLE) {
        getIsSteppedUp(getState()) ? await dispatch(doVoucherRedemptionFlow(reward, true)) : dispatch(doStepUpWeb())
        return logger.log(`doVoucherRedemptionFlow(): Step Up flow fired`)
      }
      dispatch(doStepDown())
      dispatch(exchangeVoucherFailure((error as Error)?.message))
      throw error
    }
  }
