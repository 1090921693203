import React, { useEffect, useRef } from 'react'
import { getSearchRewardsState, getErrorMessage } from '@vrw/data/src/redux/search/selectors'
import { useAppSelector } from 'web/src/redux/hooks/hooks'
import { color } from 'web/src/style/variables'
import { getString } from '@vrw/data'
import { SearchResultsMessage } from '../SearchResultsMessage'
import { pushToGTM } from 'web/src/helpers/windowMethods'
import { media } from 'web/src/style/media'
import { brandLogoFallBackImage, brandLogoImages } from 'web/src/helpers/images'
import { Picture } from '../../../Picture'
import { regionalizePath } from 'web/src/dataImplementation/viewingRegionWeb'

type GTMData = {
  campaignId: string
  rewardId: string
  slug: string
}

export const SearchResults: React.FC = () => {
  const { emptyEarnResults, emptySpendResults, data, searchResultsView } = useAppSelector(getSearchRewardsState)
  const errorMessage = useAppSelector(getErrorMessage)
  const viewingEarn = searchResultsView === 'Earn'
  const resultsEmpty = viewingEarn ? emptyEarnResults : emptySpendResults
  const emptyResultsMessage = `${getString('search.noResults.body')} ${viewingEarn ? 'Spend' : 'Earn'}?`
  const { earnRewards = [], spendRewards = [] } = data

  const results = viewingEarn ? earnRewards : spendRewards

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const node = ref.current

    if (node) {
      node.scrollTop = 0
    }
  }, [searchResultsView])

  if (errorMessage !== null) {
    return <SearchResultsMessage title={getString('search.error.title')} body={getString('search.error.body')} />
  }

  const handleOnClick = (data: GTMData) =>
    pushToGTM({ event: 'Clicked Search Result', selectedResult: { ...data, type: searchResultsView } })

  return (
    <>
      <style jsx>
        {`
          .search-results-container {
            max-height: 45vh;
            overflow-y: auto;
            margin-top: 30px;
            width: calc(100% + 18px);
            padding-right: 16px;
            scrollbar-gutter: stable;
          }
          ::-webkit-scrollbar {
            width: 2px;
          }
          ::-webkit-scrollbar-track {
            background: #f3f3f3;
            border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb {
            background: #a9a9a9;
            border-radius: 10px;
          }
          .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
          }
          .search-result {
            align-items: center;
            background-color: ${color.white};
            border: 1px solid ${color.lightGrey};
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            margin-top: 24px;
            padding: 18px;
            transition: 0.3s border ease-in-out;
          }
          .search-result:first-child {
            margin-top: 0;
          }
          .search-result:hover,
          .search-result:focus {
            border: 1px solid ${color.grey07};
            outline: none;
          }
          a.search-result {
            color: ${color.textHeader};
            text-decoration: none;
          }
          .search-result p {
            font-weight: 600;
            margin: 0 0 0 16px;
          }
          .logo {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
            height: 64px;
            width: 64px;
          }
          :global(.logo .responsive-img--override) {
            height: 64px;
            width: 64px;
            max-width: none;
          }

          @media ${media.tabletAndHigher} {
            .search-results-container {
              width: calc(100% + 28px);
              padding-right: 18px;
            }
            ::-webkit-scrollbar {
              width: 10px;
            }
            .search-result p {
              font-size: 20px;
            }
          }
        `}
      </style>
      <div role="status" className="sr-only">{`${results.length} results returned`}</div>
      {resultsEmpty ? (
        <SearchResultsMessage title={getString('search.noResults.title')} body={emptyResultsMessage} />
      ) : (
        <div ref={ref} className="search-results-container" role="listbox">
          {results.map(({ content, rewardId, campaignId = '' }) => {
            const {
              brandDetails: { brandLogo },
              slug = '',
              title,
            } = content
            const url = regionalizePath(`/${viewingEarn ? 'earn' : 'spend'}-virgin-points/${slug}-${rewardId}`)

            return (
              <a
                key={rewardId}
                className="search-result"
                aria-label={`Search result ${title}`}
                href={url}
                onClick={() => handleOnClick({ campaignId, rewardId, slug })}
              >
                <div className="logo">
                  <Picture
                    altText={title}
                    className="responsive-img--override"
                    width={64}
                    fallbackImg={brandLogoFallBackImage(brandLogo)}
                    height={64}
                    responsiveImages={brandLogoImages(brandLogo)}
                    loading={'lazy'}
                    isWebP={true}
                  />
                </div>
                <p>{title}</p>
              </a>
            )
          })}
        </div>
      )}
    </>
  )
}
