import React, { FC, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { PATHS, EXTERNAL_PATHS } from '../../../../../../router/paths'
import { color, FontFamilies } from '../../../../../../style/variables'
import ChevronRight from '../../../../../icons/ChevronRight'
import { media } from '../../../../../../style/responsive'
import { AccountInfo } from '../AccountInfo'
import { localizePath } from '../../../../../../dataImplementation/viewingRegionWeb'
import { useAppDispatch } from '../../../../../../redux/hooks/hooks'
import { doLogoutWeb } from '@vrw/data/src/redux/auth/dispatchers'

interface AccountMenuProps {
  close: () => void
  firstName: string
  email: string
  points: number
}

const AccountMenu: FC<AccountMenuProps> = ({ close, firstName, email, points }) => {
  const dispatch = useAppDispatch()
  const menuLinks = [
    {
      label: 'Account',
      href: PATHS.MY_ACCOUNT_PERSONAL_DETAILS,
      testId: 'account-menu-item-account',
      isActive: true,
    },
    {
      label: 'Activity',
      href: PATHS.ACTIVITY,
      testId: 'account-menu-item-activity',
      isActive: true,
    },
    {
      label: 'Help',
      href: EXTERNAL_PATHS.HELP_URL,
      testId: 'account-menu-item-help',
      external: true,
      isActive: true,
    },
  ]

  return (
    <>
      <style jsx>{`
        .account-menu {
          color: ${color.textStandard};
          font-family: ${FontFamilies.barlow};
          font-size: 15px;
        }
        .account-menu__header {
          border-bottom: 1px solid ${color.secondaryButtonBorder};
          display: flex;
          flex-direction: column;
          padding: 13px 16px;
        }
        .account-menu__info {
          display: block;
        }
        .account-menu__email {
          font-size: 14px;
          font-style: italic;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
        }
        .account-menu__items {
          padding: 13px 16px;
        }
        .account-menu__items :global(.account-menu__item) {
          align-items: center;
          color: ${color.textStandard};
          cursor: pointer;
          display: flex;
          font-weight: 600;
          justify-content: space-between;
          padding: 13px 0;
          text-decoration: none;
          transition: all 250ms;
        }
        .account-menu__items :global(.account-menu__item:hover),
        .account-menu__items :global(.account-menu__item:focus),
        .account-menu__items :global(.account-menu__item--selected) {
          color: ${color.brandPrimary};
          outline: none;
        }
        .account-menu__items .account-menu__log-out {
          background: transparent;
          border: 0;
          color: #757575;
          font-weight: 600;
          padding: 13px 0;
          width: 100%;
        }
        @media ${media.mobileLargeAndHigher} {
          .account-menu__header {
            flex-direction: row-reverse;
            justify-content: space-between;
          }
        }
        @media ${media.tabletLargeAndHigher} {
          .account-menu__info {
            display: none;
          }
          .account-menu__header {
            flex-direction: row;
          }
        }
      `}</style>
      <div className="account-menu" data-testid="account-menu">
        <div className="account-menu__header">
          <div className="account-menu__email">{email}</div>
          <div className="account-menu__info">
            <AccountInfo points={points} firstName={firstName} />
          </div>
        </div>
        <div className="account-menu__items">
          {menuLinks.map((link, index) => (
            <Fragment key={index}>
              {!link.external && link.isActive && (
                <NavLink
                  data-testid={link.testId}
                  to={localizePath(link.href)}
                  className={({ isActive }) => 'account-menu__item' + (isActive ? ' account-menu__item--selected' : '')}
                >
                  {link.label}
                  <ChevronRight />
                </NavLink>
              )}
              {link.external && link.isActive && (
                <a data-testid={link.testId} href={link.href} className="account-menu__item">
                  {link.label}
                  <ChevronRight />
                </a>
              )}
            </Fragment>
          ))}
          <button
            id="account-logout"
            data-testid="account-menu-item-log-out"
            className={cn(['account-menu__item', 'account-menu__log-out'])}
            onClick={(): void => {
              close()
              dispatch(doLogoutWeb())
            }}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  )
}

export { AccountMenu }
