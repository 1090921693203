import React, { FC, useEffect } from 'react'
import { PendingPointsData, StatementData } from '@vrw/data/src/redux/statement/types'
import { getString } from '@vrw/data'
import { Content, LoadSpinner, Spacer } from '../index'
import { FontFamilies, color } from '../../style/variables'
import { media } from '../../style/media'
import { ActivityStatementItem } from '../ActivityStatementItem/ActivityStatementItem'
import { Button } from '../Buttons/Button'
import LinkButton from '../Buttons/LinkButton'
import { ButtonStyle } from '../Buttons/LinkButton/linkButton'
import { TextId } from '../TextId/TextId'
import DashedDivider from '../../components/DashedDivider'
import { localizePath } from '../../dataImplementation/viewingRegionWeb'
import { PATHS } from '../../router/paths'

export interface ActivityStateProps {
  statement: StatementData
  total: number
  isRefreshing: boolean
  hasLoaded: boolean
  showLoadMore: boolean
  pendingPoints: PendingPointsData
}

export interface ActivityDispatchProps {
  onMount: () => Promise<void>
  onLoadMore: () => Promise<void>
}

type ActivityProps = ActivityStateProps & ActivityDispatchProps
let keyId = 0
const ActivityStatement: FC<ActivityProps> = ({ onMount, statement, onLoadMore, showLoadMore, hasLoaded, total, pendingPoints }) => {
  useEffect(() => {
    onMount().then()
  }, [onMount])

  if (!hasLoaded && total === 0) {
    return <LoadSpinner verticalMargin={130} />
  }
  return (
    <>
      <style jsx>{`
        .activity-date-wrapper {
          margin-bottom: 24px;
          margin-top: 24px;
        }
        .transaction-date {
          color: ${color.textStandard};
          font-family: ${FontFamilies.barlow};
          font-size: 16px;
          line-height: 1.25;
          text-align: left;
        }
        .load-more-button {
          margin: 0px auto;
          width: 140px;
        }
        .no-activity-container {
          margin: 0px auto;
        }
        .no-activity-button {
          height: 48px;
          margin: 0px auto;
          width: 200px;
        }
        .no-activity-text {
          font-size: 28px;
          line-height: 1.43;
          text-align: center;
        }
        .title {
          font-style: italic;
          font-weight: 600;
          margin: 40px 0;
          text-transform: uppercase;
        }
        .section-title {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          margin-top: 40px;
          text-align: center;
        }
        .section-text {
          margin-top: 24px;
        }
        .credit-time-subheading {
          font-style: italic;
          text-align: center;
        }
        .page-order__divider {
          margin-top: 40px;
        }
        .pending-points-header {
          text-align: center;
          margin-bottom: 54px;
        }
        .activity-date-points-headings {
          display: flex;
          justify-content: space-between;
          margin: 0 0 24px 24px;
        }
        @media ${media.tabletAndHigher} {
          .credit-time-subheading {
            text-align: right;
          }
          .statement-container {
            padding: 0px 20px;
          }
        }
        @media ${media.desktopAndHigher} {
          .statement-container {
            padding: 0px 224px;
          }
      `}</style>
      <Content>
        <div className="statement-container">
          {pendingPoints.dates.length > 0 && (
            <>
              <div className="pending-points-header">
                <div className="section-title" data-testid="pending-points-header">
                  {getString('activity.pendingPoints.header')}
                </div>
                <div className="section-text" data-testid="pending-points-credit-time">
                  {getString('activity.pendingPoints.creditTime')}
                </div>
              </div>
              {pendingPoints.dates.map((date, index) => (
                <>
                  <div key={date} className="activity-date-wrapper">
                    <div className="activity-date-points-headings">
                      <div className="transaction-date" data-testid="pending-date">
                        {getString('activity.pendingPoints.trasactionsOn') + date}
                      </div>
                    </div>
                    {pendingPoints.idsByDate[date].map((id, index) => (
                      <ActivityStatementItem
                        pending
                        multipleItems={index > 0}
                        key={`activityItem${keyId++}`}
                        name={pendingPoints.transactions[id].description}
                        points={pendingPoints.transactions[id].points}
                      />
                    ))}
                  </div>
                </>
              ))}
              <div className="page-order__divider">
                <DashedDivider />
              </div>
            </>
          )}

          {total > 0 && statement.dates.length > 0 && (
            <>
              <div className="section-title" data-testid="activity-header">
                {getString('activity.title.section')}
              </div>
              {statement.dates.map((date) => (
                <>
                  <div key={date} className="activity-date-wrapper">
                    <div className="activity-date-points-headings">
                      <div className="transaction-date" data-testid="transaction-date">
                        {date}
                      </div>
                    </div>
                    {statement.idsByDate[date].map((id, index) => (
                      <ActivityStatementItem
                        multipleItems={index > 0}
                        key={`activityItem${keyId++}`}
                        name={statement.transactions[id].description}
                        points={statement.transactions[id].pointsInt}
                        showPlus={statement.transactions[id].pointsInt > 0}
                      />
                    ))}
                  </div>
                </>
              ))}
            </>
          )}
          {showLoadMore && (
            <div className="load-more-button">
              <Button dataAttributes={{ testid: 'btn-load-more' }} onClick={onLoadMore} text="Load more" isPrimary />
            </div>
          )}
          {(total === 0 || statement.dates.length === 0) && (
            <div className="no-activity-container">
              <Spacer size="medium" />
              <div className="no-activity-text title">
                <TextId id="activity.noActivityTitle" />
              </div>
              <div className="no-activity-text">
                <TextId id="activity.noActivityText" />
              </div>
              <Spacer size="large" />
              <div className="no-activity-button">
                <LinkButton
                  style={ButtonStyle.Primary}
                  href={localizePath(PATHS.EARN)}
                  text={getString('activity.browseRewardsButton')}
                  display="block"
                />
              </div>
            </div>
          )}
        </div>
      </Content>
    </>
  )
}

export { ActivityStatement }
