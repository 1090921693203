import { logger } from '../../../utils/logger'
import { getRedDataConfig } from '../../../config'
import { DispatchFunction } from '../../types'
import { stepDownStart, stepDownSuccess } from '../actions'

export const doStepDown = () => async (dispatch: DispatchFunction) => {
  logger.log('doStepDown()')
  dispatch(stepDownStart())
  const config = getRedDataConfig()
  await config.secrets.delete('stepUpAccessToken')
  dispatch(stepDownSuccess())
}
