import React from 'react'
import { Spinner } from '@red-ui/components/'
import { Page } from '../../components'
import { Heading, SpinnerContainer, PlansContainer, Stack, Subheading } from './styles'
import { Plan } from './Plan'
import { getString } from '@vrw/data'
import { useSubscriptionPlans } from './useSubscriptionPlans'
import { CompareTable } from './CompareTable'

export const Subscribe = () => {
  const { isPending, data: subscriptionPlans, allPlans, allFeatures } = useSubscriptionPlans()

  return (
    <Page title={getString('subscribe.page.title')}>
      {isPending ? (
        <SpinnerContainer>
          <Spinner color="$white" size="large" />
        </SpinnerContainer>
      ) : (
        subscriptionPlans?.length && (
          <>
            <PlansContainer>
              {getString('subscribe.page.content.title')
                .split('\n')
                .map((row) => (
                  <Heading key={row}>{row}</Heading>
                ))}
              <Subheading>{getString('subscribe.page.content.subtitle')}</Subheading>
              <Stack>
                {subscriptionPlans.map((plan) => (
                  <Plan key={plan.id} {...plan} />
                ))}
              </Stack>
            </PlansContainer>
            <CompareTable plans={allPlans} features={allFeatures} />
          </>
        )
      )}
    </Page>
  )
}
