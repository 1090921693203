import { combineReducers } from 'redux'
import { accountsReducer as accounts } from './accounts/reducer'
import { articlesPublicReducer as articlesPublic } from './articlesPublic/reducer'
import { authReducer as auth } from './auth/reducer'
import balance from './balance/balance.slice'
import { categoriesReducer as categories } from './categories/reducer'
import { categoriesPublicReducer as categoriesPublic } from './categoriesPublic/reducer'
import { categoriesEarnReducer as categoriesEarn } from './categoriesEarn/reducer'
import { categoriesEarnPublicReducer as categoriesEarnPublic } from './categoriesEarnPublic/reducer'
import { dialogReducer as dialog } from './dialog/reducer'
import dialogApp from './dialogApp/dialogApp.slice'
import { discoveryReducer as discovery } from './discovery/reducer'
import { discoveryEarnReducer as discoveryEarn } from './discoveryEarn/reducer'
import { discoveryEarnPublicReducer as discoveryEarnPublic } from './discoveryEarnPublic/reducer'
import { discoveryPublicReducer as discoveryPublic } from './discoveryPublic/reducer'
import { wishlistReducer as wishlist } from './wishlist/reducer'
import { introReducer as intro } from './intro/reducer'
import { ordersReducer as orders } from './orders/reducer'
import { pagesReducer as pages } from './pages/reducer'
import { privacyPolicyReducer as privacyPolicy } from './privacyPolicy/reducer'
import { profileReducer as profile } from './profile/reducer'
import { promoCodesReducer as promoCodes } from './promotions/reducer'
import { rewardsEarnPublicReducer as rewardsEarnPublic } from './rewardsEarnPublic/reducer'
import { rewardsEarnReducer as rewardsEarn } from './rewardsEarn/reducer'
import { rewardsPublicReducer as rewardsPublic } from './rewardsPublic/reducer'
import { rewardsReducer as rewards } from './rewards/reducer'
import { statementReducer as statement } from './statement/reducer'
import { searchRewardsReducer as searchRewards } from './search/reducer'
import { termsAndConditionsReducer as termsAndConditions } from './termsAndConditions/reducer'
import { toastReducer as toast } from './toast/reducer'
import { walletReducer as wallet } from './wallet/reducer'
import featureFlags from './features/features.slice'

export const reducer = combineReducers({
  accounts,
  articlesPublic,
  auth,
  balance,
  categories,
  categoriesEarn,
  categoriesPublic,
  categoriesEarnPublic,
  dialog,
  dialogApp,
  discovery,
  discoveryEarn,
  discoveryPublic,
  discoveryEarnPublic,
  wishlist,
  intro,
  orders,
  pages,
  privacyPolicy,
  profile,
  promoCodes,
  rewards,
  rewardsEarn,
  rewardsEarnPublic,
  rewardsPublic,
  searchRewards,
  statement,
  termsAndConditions,
  toast,
  wallet,
  featureFlags,
})
