import React, { FC } from 'react'

import { formatPoints } from '@vrw/data/src/utils/formatters'

interface PointsProps {
  value: number | string
  showPlus?: boolean
}

const Points: FC<PointsProps> = ({ value, showPlus = false }) => (
  <span role="note" data-testid="points-text">
    {formatPoints(value) + (showPlus ? '+' : '')}
  </span>
)

export { Points }
