import React, { FC } from 'react'

import { getLocalisedString } from '@vrw/data/src/utils/helperFunctions'

import { media } from '../../style/media'
import { spacing } from '../../style/variables'

import H3Header from '../typography/H3Header'
import Body from '../typography/Body'

import { ViewingRegion, getString } from '@vrw/data/src/utils'
import { EXTERNAL_PATHS } from '../../router/paths'
import { Picture } from '../Picture'

import { getViewingRegion } from '../../dataImplementation/viewingRegionWeb'
import { PUBLIC_URL } from '../../config'
import { Content } from '../layout'

const MobileAppsSplash: FC = () => {
  const showDownloadImage =
    getViewingRegion() === ViewingRegion.GB
      ? `${PUBLIC_URL}/img/download_the_virgin_red_app.jpg`
      : `${PUBLIC_URL}/img/download_the_virgin_red_app_us.jpg`

  return (
    <>
      <style jsx>{`
        .mobile-app-splash {
          align-items: center;
          display: flex;
          flex-direction: column-reverse;
          margin: 0 auto;
          max-width: 448px;
          width: 100%;
        }
        .mobile-app-splash-image {
          height: auto;
          width: 100%;
        }
        .mobile-app-info {
          height: 100%;
          padding: 0px 15px;
        }
        .mobile-app-info :global(.header-three) {
          font-size: 24px;
          width: 200px;
        }
        .mobile-app-info :global(.body) {
          font-size: 16px;
          width: auto;
        }
        .download-image-links {
          display: flex;
          justify-content: center;
          margin: 24px 0;
        }
        .download-image-link {
          height: 35px;
          margin-left: 9px;
        }
        .download-image {
          height: 35px;
        }
        @media ${media.tabletAndHigher} {
          .mobile-app-splash {
            flex-direction: row;
            justify-content: space-around;
            max-width: 90%;
          }
          .mobile-app-splash-image {
            align-self: flex-end;
          }
          .mobile-app-info :global(.header-three) {
            font-size: 28px;
            width: 220px;
          }
        }
        @media ${media.desktopAndHigher} {
          .download-image-link {
            margin: 0 8px;
            height: 60px;
          }
          .download-image {
            height: 100%;
            width: auto;
            aspect-ratio: attr(width) / attr(height);
          }
          .mobile-app-info :global(.header-three) {
            font-size: 36px;
            width: 280px;
          }
        }
      `}</style>
      <Content>
        <div className="mobile-app-splash" data-testid="mobile-app-splash">
          <div className="mobile-app-splash-image">
            <Picture
              altText=""
              width={600}
              fallbackImg={showDownloadImage}
              height={600}
              responsiveImages={{
                mobile: {
                  imgSrc: showDownloadImage,
                },
              }}
              loading={'lazy'}
              isWebP={true}
            />
          </div>
          <div className="mobile-app-info">
            <H3Header
              marginTop={{
                desktop: 0,
              }}
              textAlign="center"
              fontStyle="italic"
              weight={600}
            >
              {getString('mobileApps.title').toUpperCase()}
            </H3Header>
            <Body textAlign="center" marginTop={spacing.zero} marginBottom={{ mobile: 15, tablet: 45, desktop: 45 }}>
              {getString(`mobileApps.description${getLocalisedString(getViewingRegion())}`)}
            </Body>
            <div className="download-image-links">
              <a href={EXTERNAL_PATHS.APPLE_STORE_DOWNLOAD_URL} className="download-image-link" target="_blank" rel="noopener noreferrer">
                <img
                  className="download-image"
                  src={`${PUBLIC_URL}/img/red/mobile/app-store-badge.svg`}
                  alt="App store icon"
                  width={107}
                  height={60}
                  loading={'lazy'}
                />
              </a>
              <a href={EXTERNAL_PATHS.GOOGLE_PLAY_DOWNLOAD_URL} className="download-image-link" target="_blank" rel="noopener noreferrer">
                <img
                  className="download-image"
                  src={`${PUBLIC_URL}/img/red/mobile/play-store-badge.svg`}
                  alt="Play store icon"
                  width={107}
                  height={60}
                  loading={'lazy'}
                />
              </a>
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}

export { MobileAppsSplash }
