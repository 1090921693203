import { PointsRange } from '../redux/categories/types'
import { Buffer } from 'buffer'

// https://stackoverflow.com/questions/39468022/how-do-i-know-if-my-code-is-running-as-react-native/39473604
export const isWeb = () => {
  return typeof document !== 'undefined'
}

export const numberWithCommas = (x: number | string): string => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const getPointsLabels = (obj: PointsRange): string[] => {
  const labels: string[] = []
  Object.entries(obj).forEach((item) => {
    if (item[0].includes('+')) {
      const parts = item[0].split('+')
      if (parts.length > 0) labels.push(numberWithCommas(parts[0]) + '+')
    } else if (item[0].includes('-')) {
      const parts = item[0].split('-')
      if (parts.length > 1) labels.push(numberWithCommas(parts[0]) + ' - ' + numberWithCommas(parts[1]))
    }
  })
  return labels
}

export enum Locale {
  EN_GB = 'en-gb',
  EN_US = 'en-us',
}

export enum ViewingRegion {
  GB = 'GB',
  US = 'US',
}

export enum LinkingJourneyOrigins {
  ONBOARDING = 'onboarding',
  ACCOUNT = 'account',
  PARTNER = 'partner',
}

export enum ViewingRegionDisplayName {
  GB = 'United Kingdom',
  US = 'United States',
}

export const getRegionDisplayName = (key: ViewingRegion): ViewingRegionDisplayName => ViewingRegionDisplayName[key]

export const getLocalisedString = (region?: ViewingRegion) => (region !== ViewingRegion.GB ? `.${region}` : '')

export const parseDigits = (input?: string) => (input?.match(/\d+/g) || []).join('')

export const delay = async (delayTime: number) => new Promise((resolve) => setTimeout(resolve, delayTime))

export const jsonToBase64 = <Record>(object: Record) => {
  const json = JSON.stringify(object)
  return Buffer.from(json).toString('base64')
}
