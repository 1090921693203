import React, { FC } from 'react'

import { getString } from '@vrw/data'
import { FontFamilies } from '../../style/variables'

import { Content, H3Header, Page, Spacer, AngledContainer } from '../../components'
import H1Header from '../../components/oldTypography/H1Header'
import TextWithSymbol from '../../components/oldTypography/textWithSymbol'
import LeadText from '../../components/oldTypography/leadText'
import Copy from '../../components/richText/copy'
import LinkButton, { ButtonStyle } from '../../components/Buttons/LinkButton/linkButton'
import { PUBLIC_URL } from '../../config'

const ModernSlaveryStatement: FC = () => {
  const title = getString('redModernSlaveryStatement.title')

  return (
    <>
      <style jsx>{`
        .button-container {
          text-align: center;
        }
      `}</style>
      <Page
        testId="page-modern-slavery"
        title="Modern Slavery Statement | Virgin Red"
        metaDescription={getString('meta.description.modern.slavery')}
      >
        <H1Header textAlign="center" fontFamily={FontFamilies.barlow}>
          <TextWithSymbol text={`${title}.`} />
        </H1Header>

        <Content>
          <LeadText>{getString('redModernSlaveryStatement.description')}</LeadText>
          <AngledContainer>
            <Spacer size={'small'} />
            <Content>
              <H3Header textAlign="center">{getString('redModernSlaveryStatement.download.title')}</H3Header>
              <Copy>
                <p>{getString('redModernSlaveryStatement.download.description')}</p>
                <p style={{ textAlign: 'center' }}>
                  <b>2023</b>
                </p>
                <div className="button-container">
                  <LinkButton
                    href={`${PUBLIC_URL}/doc/modern-slavery-statement-2023.pdf`}
                    text="modern-slavery-statement-2023.pdf"
                    style={ButtonStyle.Secondary}
                    display="inline-block"
                    buttonIcon={`${PUBLIC_URL}/img/download-icon-24-px.svg`}
                    buttonIconAlt="Download Icon"
                    useRouter={false}
                    testId="pdf-button"
                  />
                </div>
                <p style={{ textAlign: 'center' }}>
                  <b>2022</b>
                </p>
                <div className="button-container">
                  <LinkButton
                    href={`${PUBLIC_URL}/doc/modern-slavery-statement-2022.pdf`}
                    text="modern-slavery-statement-2022.pdf"
                    style={ButtonStyle.Secondary}
                    display="inline-block"
                    buttonIcon={`${PUBLIC_URL}/img/download-icon-24-px.svg`}
                    buttonIconAlt="Download Icon"
                    useRouter={false}
                    testId="pdf-button"
                  />
                </div>
                <p style={{ textAlign: 'center' }}>
                  <b>2021</b>
                </p>
                <div className="button-container">
                  <LinkButton
                    href={`${PUBLIC_URL}/doc/modern-slavery-statement-2021.pdf`}
                    text="modern-slavery-statement-2021.pdf"
                    style={ButtonStyle.Secondary}
                    display="inline-block"
                    buttonIcon={`${PUBLIC_URL}/img/download-icon-24-px.svg`}
                    buttonIconAlt="Download Icon"
                    useRouter={false}
                    testId="pdf-button"
                  />
                </div>
                <p style={{ textAlign: 'center' }}>
                  <b>2020</b>
                </p>
                <div className="button-container">
                  <LinkButton
                    href={`${PUBLIC_URL}/doc/modern-slavery-statement-2020.pdf`}
                    text="modern-slavery-statement-2020.pdf"
                    style={ButtonStyle.Secondary}
                    display="inline-block"
                    buttonIcon={`${PUBLIC_URL}/img/download-icon-24-px.svg`}
                    buttonIconAlt="Download Icon"
                    useRouter={false}
                    testId="pdf-button"
                  />
                </div>
                <p style={{ textAlign: 'center' }}>
                  <b>2019</b>
                </p>
                <div className="button-container">
                  <LinkButton
                    href={`${PUBLIC_URL}/doc/modern-slavery-statement-2019.pdf`}
                    text="modern-slavery-statement-2019.pdf"
                    style={ButtonStyle.Secondary}
                    display="inline-block"
                    buttonIcon={`${PUBLIC_URL}/img/download-icon-24-px.svg`}
                    buttonIconAlt="Download Icon"
                    useRouter={false}
                    testId="pdf-button"
                  />
                </div>
              </Copy>
            </Content>
            <Spacer size={'small'} />
          </AngledContainer>
        </Content>
      </Page>
    </>
  )
}

export { ModernSlaveryStatement }
