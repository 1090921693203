import React from 'react'
import { View, Text, Button } from '@red-ui/components/'
import { getString } from '@vrw/data'
import { SubscriptionPlan } from '@vrw/data/src/api/subscriptions.api'
import { CustomLink, Chip } from '../../../components'
import { virginRedPath } from '../../../router/paths'
import { Feature } from '../Feature'
import { Card, featuresListStyle } from './styles'

export const Plan = ({ id, name, cost, features, featuresDescription, primaryColor, nameTextPrimaryColor }: SubscriptionPlan) => {
  return (
    <Card>
      <View>
        <Chip.Root backgroundColor={primaryColor}>
          <Chip.Text color={nameTextPrimaryColor}>{name}</Chip.Text>
        </Chip.Root>
        <Text fontSize="$10" fontWeight="600" marginTop="$3">
          {cost}
        </Text>
        <View marginTop="$2" marginBottom="$4">
          <Text fontSize="$6" fontWeight="bold">
            <Text color={primaryColor}>{`${name} `}</Text>
            {getString('subscribe.page.content.plan.title')}
          </Text>
          <Text fontSize="$4" marginTop="$1">
            {featuresDescription}
          </Text>
          <ul style={featuresListStyle}>
            {features.map((feature) => (
              <Feature key={feature.description} {...feature} />
            ))}
          </ul>
        </View>
      </View>
      <View>
        <CustomLink to={`${virginRedPath}/subscription-payment/${id}`}>
          <Button width="100%" size="$5">
            {getString('subscribe.page.content.plan.button').replace('{{plan}}', name)}
          </Button>
        </CustomLink>
        <Text alignSelf="center" marginTop="$3">
          <Text fontSize="$2">{getString('subscribe.page.content.plan.trialDuration')}</Text>
          <Text fontWeight="600">{getString('subscribe.page.content.plan.cost').replace('{{cost}}', cost)}</Text>
        </Text>
      </View>
    </Card>
  )
}
