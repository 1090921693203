import { Button, H5, H6, styled, View } from '@red-ui/components/'

export const Title = styled(H5, {
  marginBottom: '$4',
})

export const Subtitle = styled(H6, {
  marginBottom: '$1',
})

export const StyledButton = styled(Button, {
  size: '$4',
  $gtMobile: { flexBasis: '40%', flexGrow: 1 },
})

export const ButtonsContainer = styled(View, {
  gap: '$2',
  display: 'flex',
  flexDirection: 'column',
  $gtMobile: {
    flexDirection: 'row',
    gap: '$3',
  },
})
