import { getRedDataApiPaths, getRedDataConfig } from '../config'
import { Reward } from '../redux/rewards/types'
import { get } from '../utils/httpClient'

const createRewardGroupApi = () => getRedDataApiPaths().GENERATE_B2C_API('/v1/reward-groups')

export type RewardGroup = {
  id: string
  description: string
  reference: string
}

export const apiGetRewardGroups = async (): Promise<RewardGroup[]> => {
  const response = (await get(createRewardGroupApi(), true)) as { rewardGroups: RewardGroup[] }
  return response.rewardGroups
}

export const apiGetRewardsByGroupId = async ({ rewardGroupId }: { rewardGroupId: string }): Promise<Reward[]> => {
  const viewingRegionParam = `viewingRegion=${getRedDataConfig().getViewingRegion()}`
  // requirement is only to get spend rewards currently
  const response = (await get(`${createRewardGroupApi()}/${rewardGroupId}/rewards?type=SPEND&${viewingRegionParam}`, true)) as {
    rewards: Reward[]
  }
  return response.rewards
}

export const apiGetGroupRewardById = async ({ rewardId, rewardGroupId }: { rewardId: string; rewardGroupId: string }): Promise<Reward> => {
  const viewingRegionParam = `viewingRegion=${getRedDataConfig().getViewingRegion()}`
  const response = (await get(`${createRewardGroupApi()}/${rewardGroupId}/rewards/${rewardId}?${viewingRegionParam}`, true)) as Reward
  return response
}
