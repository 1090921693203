import React from 'react'

import { Checkbox } from '@virgin-core/components'
import { getString } from '@vrw/data'
import { Profile } from '@vrw/data/src/redux/profile/types'
import { RewardTags } from '@vrw/data/src/redux/rewards/types'
import { Page } from '../../../components'
import H3Header from '../../../components/typography/H3Header'
import { MyAccountBackground } from '../MyAccountBackground'
import { AccountSettingsProps } from './types'
import { color, spacing } from '../../../style/variables'
import { jsonToBase64 } from '@vrw/data/src/utils/helperFunctions'
import { VID_ACCOUNT } from 'web/src/config'

// comms
const commsIntroText = getString('account.settings.communicationPreferences.introText')
const commsInstructionText = getString('account.settings.communicationPreferences.instruction')
const commsEmailLabel = getString('account.settings.communicationPreferences.marketingEmail.label')
const commsSmsLabel = getString('account.settings.communicationPreferences.marketingSms.label')
const commsVirginRedOnly = getString('account.settings.communicationPreferences.virginRedOnly')
const commsVirginGroupPreferences = getString('account.settings.communicationPreferences.virginGroupPreferences')

const vidAccountCallbackParam = jsonToBase64({
  partner: getString('account.vid.settings.web.platform'),
  redirectPath: getString('account.vid.preferences.web.path'),
})
const accountSpaceUrl = `${VID_ACCOUNT}?callback=${vidAccountCallbackParam}`

// content
const contentIntroText = getString('account.settings.contentPreferences.introText')
const contentAlcoholLabel = getString('account.settings.contentPreferences.alcoholOptOutText')
const contentGamblingLabel = getString('account.settings.contentPreferences.gamblingOptOutText')

const AccountSettings: React.FC<AccountSettingsProps> = ({ profile, doPatchProfile, doRemoveOptedOutRewards }) => {
  const showContentPreferences: boolean = !profile?.restrictAlcoholGambling

  const doSubmitCommsChange = async (payload: Partial<Profile>) => {
    return await doPatchProfile({
      ...payload,
      marketingText: `${commsIntroText}\n${commsInstructionText}`,
    })
  }

  const removeOptedOutRewards = (payload: Partial<Profile>) => {
    if (payload.optOutPrefAlcohol) {
      doRemoveOptedOutRewards(RewardTags.Alcohol)
    } else if (payload.optOutPrefGambling) {
      doRemoveOptedOutRewards(RewardTags.Gambling)
    }
  }

  const doSubmitContentChange = async (payload: Partial<Profile>) => {
    removeOptedOutRewards(payload)
    return await doPatchProfile({
      ...payload,
    })
  }

  return (
    <Page title="Your preferences | Virgin Red" testId="page-account-settings">
      <MyAccountBackground>
        <style jsx>
          {`
            .checkbox-wrapper {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
            }
            a {
              text-decoration: none;
              font-weight: 600;
              color: ${color.brandPrimary};
            }
            a:hover {
              text-decoration: underline;
            }
          `}
        </style>

        <H3Header
          textAlign="left"
          fontSize={{ mobile: 24, tablet: 24, desktop: 28 }}
          weight={600}
          marginTop={spacing.zero}
          marginBottom={spacing.zero}
        >
          {getString('account.settings.communicationPreferences.title')}
        </H3Header>
        <div className="prompt-text">
          <p>{commsIntroText}</p>
          <p>{commsInstructionText}</p>
        </div>
        <div className="checkbox-wrapper">
          <Checkbox
            data-testid="marketing-email"
            id="marketingEmail"
            label={commsEmailLabel}
            checked={!profile?.marketingEmail}
            onChange={(event: any, checked: boolean) =>
              doSubmitCommsChange({
                marketingEmail: !checked,
                marketingEmailText: commsEmailLabel,
              })
            }
          />
        </div>
        <div className="checkbox-wrapper">
          <Checkbox
            id="marketingSms"
            label={commsSmsLabel}
            checked={!profile?.marketingSms}
            onChange={(event: any, checked: boolean) =>
              doSubmitCommsChange({
                marketingSms: !checked,
                marketingSmsText: commsSmsLabel,
              })
            }
            data-testid="sms-checkbox"
          />
        </div>
        <div>
          <p>
            {commsVirginRedOnly}
            <a href={accountSpaceUrl}>{commsVirginGroupPreferences}</a>
          </p>
        </div>
        {showContentPreferences && (
          <>
            <H3Header
              textAlign="left"
              fontSize={{ mobile: 24, tablet: 24, desktop: 28 }}
              weight={600}
              marginTop={spacing.small}
              marginBottom={spacing.zero}
            >
              {getString('account.settings.contentPreferences.title')}
            </H3Header>
            <div className="prompt-text">
              <p>{contentIntroText}</p>
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                id="optOutPrefAlcohol"
                label={contentAlcoholLabel}
                checked={profile?.optOutPrefAlcohol}
                onChange={(_event, checked: boolean) =>
                  doSubmitContentChange({
                    optOutPrefAlcohol: checked,
                    optOutPrefAlcoholText: contentAlcoholLabel,
                  })
                }
                data-testid="email-checkbox"
              />
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                id="optOutPrefGambling"
                label={contentGamblingLabel}
                checked={profile?.optOutPrefGambling}
                onChange={(_event, checked: boolean) =>
                  doSubmitContentChange({
                    optOutPrefGambling: checked,
                    optOutPrefGamblingText: contentGamblingLabel,
                  })
                }
                data-testid="email-checkbox"
              />
            </div>
          </>
        )}
      </MyAccountBackground>
    </Page>
  )
}

export { AccountSettings }
