import React, { useEffect, useRef } from 'react'
import { LoadSpinner } from '../../components'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import {
  getIsAuthenticated,
  getIsAuthenticating,
  getIsSteppingUp,
  getIsUnauthenticating,
  getLoginStartPath,
} from '@vrw/data/src/redux/auth/selectors'
import { postLoginCallback } from '../../dataImplementation/auth'
import { postLoginRedirect, postRefreshRedirect } from '@vrw/data/src/redux/auth/postLoginRedirect'
import { doFetchProfile } from '@vrw/data/src/redux/profile/dispatchers'
import { Profile } from '@vrw/data/src/redux/profile/types'
import { clearLoginStartPath, loginFailure, loginStop, loginSuccess, stepUpFailure, stepUpSuccess } from '@vrw/data/src/redux/auth/actions'
import { getRedDataConfig } from '@vrw/data/src/config'
import { logger } from '@vrw/data/src/utils'
import { errorHandler } from '@vrw/data/src/redux/errorHandler'
import { getAuthTokens } from '@vrw/data/src/redux/auth/getAuthTokens'
import { useIncentiveCodes } from '../../helpers'
import { apiPostUserEvents } from '@vrw/data/src/api/userEvents.api'
import { getOrdersState } from '@vrw/data/src/redux/orders/selectors'
import { useRedNavigate } from '../../dataImplementation/useRedNavigate'
import { getRewardById } from '@vrw/data/src/redux/rewards/selectors'
import { PATHS } from '../../router/paths'
import { persistor } from 'web/src/redux/configureStore'

export const LoginSuccess = () => {
  const dispatch = useAppDispatch()
  const { loggedInUserChanged, secrets, auth } = getRedDataConfig()
  const postLoginPath = useAppSelector(getLoginStartPath)
  const isAuthenticating = useAppSelector(getIsAuthenticating)
  const isUnauthenticating = useAppSelector(getIsUnauthenticating)
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const isSteppingUp = useAppSelector(getIsSteppingUp)

  const redNavigate = useRedNavigate()
  const orderState = useAppSelector(getOrdersState)
  const reward = useAppSelector((state) => getRewardById(state, orderState?.inProgress?.rewardId))
  const rewardType = reward && reward.campaignId === 'EARN' ? PATHS.EARN : PATHS.SPEND

  const initialised = useRef(false)

  const { getCodes, clearCodes } = useIncentiveCodes()

  useEffect(() => {
    if (initialised.current === false) {
      ;(async () => {
        if (isSteppingUp) {
          try {
            await postLoginCallback()
            const response = await auth.stepUpTokenParsing()
            await secrets.save('stepUpAccessToken', response?.accessToken)
            dispatch(stepUpSuccess())
            redNavigate(`${rewardType}/${orderState.inProgress.rewardId}`)
          } catch (error) {
            errorHandler(dispatch, error, stepUpFailure)
          }
        } else {
          await postLoginCallback()
          await getAuthTokens(false)
          const profile = (await dispatch(doFetchProfile())) as Profile
          if (!isAuthenticating && !isUnauthenticating && isAuthenticated) {
            postRefreshRedirect(profile)
          } else {
            try {
              apiPostUserEvents({ codes: getCodes() })
              clearCodes()
              dispatch(loginSuccess())
              loggedInUserChanged && loggedInUserChanged(profile)
              await persistor.flush()
              postLoginRedirect(profile, postLoginPath)
              if (!profile.appState?.onboarding) dispatch(clearLoginStartPath())
            } catch (error) {
              logger.error('LoginSuccess.ts: Error thrown', error)
              errorHandler(dispatch, error, loginFailure)
              dispatch(loginStop())
            }
          }
        }
      })()
    }

    return () => {
      initialised.current = true
    }
  }, [
    isAuthenticating,
    dispatch,
    loggedInUserChanged,
    postLoginPath,
    auth,
    secrets,
    isUnauthenticating,
    isAuthenticated,
    isSteppingUp,
    getCodes,
    orderState,
    redNavigate,
    rewardType,
    clearCodes,
  ])

  return (
    <>
      <style jsx>{`
        html,
        body {
          height: 100vh;
        }
        .loading {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          width: 100%;
        }
      `}</style>
      <div className="loading">
        <LoadSpinner />
      </div>
    </>
  )
}
