import { useEffect, useCallback, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { updateRewards } from '@vrw/data/src/redux/rewards/actions'
import { Reward } from '@vrw/data/src/redux/rewards/types'
import { RewardEarn } from '@vrw/data/src/redux/rewardsEarn/types'
import { RedDataState } from '@vrw/data/src/types'
import { findWishedReward, getRewardIdFromURL } from '@vrw/data/src/utils'
import { useTrackRewardEvent } from '../../analytics/useTrackRewardEvent'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { useGroupRewardById } from '../../query/rewardGroups'
import { getIsSteppedUp } from '@vrw/data/src/redux/auth/selectors'
import { doRedeemRewardConfirmed } from '@vrw/data/src/redux/rewards/dispatchers'

type UseRewardProps = {
  isEarn: boolean
  getRewardById: (state: RedDataState, rewardId: string) => Reward | RewardEarn | undefined
  doFetchReward: (rewardId: string) => void
  onViewReward: (rewardId: string) => void
  doGetWishlist: () => void
  isLoading: boolean
  errorMessage: string | null
}

export const useReward = ({
  isEarn,
  getRewardById,
  doFetchReward,
  onViewReward,
  doGetWishlist,
  isLoading,
  errorMessage,
}: UseRewardProps) => {
  const dispatch = useAppDispatch()
  const { rewardPath = '' } = useParams() ?? {}
  const rewardId = getRewardIdFromURL(rewardPath) ?? ''
  const rewardInState = useAppSelector((state) => getRewardById(state, rewardId))
  const isWished = useAppSelector((state) => findWishedReward(state.wishlist.wishlist.rewards, rewardId))
  const isSteppedUp = useAppSelector(getIsSteppedUp)
  const [reward, setReward] = useState<Reward | RewardEarn | undefined>(rewardInState)
  const { trackRewardRedeemedEvent, trackRewardViewedEvent } = useTrackRewardEvent(reward, isEarn ? 'earn' : 'spend')

  const { search } = useLocation()
  const groupId = new URLSearchParams(search).get('groupId') ?? ''
  const isGroupReward = !!groupId
  const { data: groupReward, status: groupRewardStatus } = useGroupRewardById(rewardId, groupId)

  const isLoadingState = !!rewardId && ((!rewardInState && isLoading) || (isGroupReward && groupRewardStatus === 'pending'))
  const isErrorState = !rewardId || (!rewardInState && !!errorMessage) || (isGroupReward && groupRewardStatus === 'error')

  useEffect(() => {
    // On direct first time visit or if we need to re-hydrate the app
    // we will fetch only the relevant reward
    if (!reward) {
      if (rewardInState) {
        setReward(rewardInState)
      } else if (groupReward) {
        setReward(groupReward)
        dispatch(updateRewards([groupReward]))
      } else {
        doFetchReward(rewardId)
      }
    }
  }, [isEarn, doFetchReward, reward, rewardId, groupReward, dispatch, rewardInState])

  useEffect(() => {
    if (isSteppedUp) {
      dispatch(doRedeemRewardConfirmed())
    }
  }, [isSteppedUp, dispatch])

  useEffect(() => {
    if (doGetWishlist) {
      doGetWishlist()
    }
  }, [doGetWishlist])

  const onRender = useCallback(() => {
    if (reward) {
      onViewReward(reward.rewardId)
      trackRewardViewedEvent()
    }
  }, [onViewReward, reward, trackRewardViewedEvent])

  return {
    rewardId,
    reward,
    isGroupReward,
    isLoadingState,
    isErrorState,
    isWished,
    trackRewardRedeemedEvent,
    onRender,
  }
}
