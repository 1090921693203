import { connect } from 'react-redux'

import { doVerifyEmail } from '@vrw/data/src/redux/profile/dispatchers'
import { getNewEmail, getIsUpdating } from '@vrw/data/src/redux/profile/selectors'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

import { VerifyEmail } from './VerifyEmail'

const mapStateToProps = (state: RootState) => ({
  newEmail: getNewEmail(state) ?? '',
  isUpdating: getIsUpdating(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doVerifyEmail,
    },
    dispatch
  )

const VerifyEmailContainer = connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)

export { VerifyEmailContainer }
