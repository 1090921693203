import React from 'react'
import { View, useMedia, mediaQueryConfig } from '@red-ui/components'
import { getString } from '@vrw/data'
import { convertRewardToCard } from '@vrw/data/src/utils/convertRewardToCard'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { selectFeatureById } from '@vrw/data/src/redux/features/features.selectors'
import { useAppSelector } from '../../redux/hooks/hooks'
import { Chip } from '../Chip'
import { Content } from '../layout'
import { IntroCarouselBlock, IntroCarouselType } from '../ContentfulBlock/IntroCarouselBlock/IntroCarouselBlock'
import { SUBSCRIPTION_ITEMS } from '../ContentfulBlock/IntroCarouselBlock/IntroCarouselBlockPrivateContainer'
import { useSubscriptionPlanById, useUserSubscriptionStatus } from '../../query/subscriptions'
import { SectionWrapper, DescribeWrapper, ChipRoot, CarouselWrapper, TitleText, DescriptionText, ButtonShowAll } from './styles'

const ShowAllButton = () => <ButtonShowAll>{getString('exclusiveRewardsSection.button.title')}</ButtonShowAll>

export const ExclusiveRewardsSection = () => {
  const webSubscriptionFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_SUBSCRIPTION))
  const { currentSubscription } = useUserSubscriptionStatus()

  const { data: planData } = useSubscriptionPlanById(currentSubscription?.planId)
  const { name, primaryColor, nameTextPrimaryColor } = planData ?? {}
  const { gtTabletLandscape, gtMobile } = useMedia()
  const carouselItems = convertRewardToCard([...SUBSCRIPTION_ITEMS, ...SUBSCRIPTION_ITEMS, ...SUBSCRIPTION_ITEMS])

  const DesktopWrapper = gtTabletLandscape ? Content : View

  if (!webSubscriptionFlag || !currentSubscription || !planData) return null

  return (
    <DesktopWrapper>
      <SectionWrapper>
        <DescribeWrapper>
          <ChipRoot backgroundColor={primaryColor}>
            <Chip.Text color={nameTextPrimaryColor}>{name}</Chip.Text>
          </ChipRoot>
          <TitleText color={primaryColor}>{getString('exclusiveRewardsSection.title')}</TitleText>
          <DescriptionText>{getString('exclusiveRewardsSection.description')}</DescriptionText>
          {gtMobile && <ShowAllButton />}
        </DescribeWrapper>
        <CarouselWrapper>
          <IntroCarouselBlock
            items={() => carouselItems}
            doGetItems={() => ({})}
            isLoading={false}
            type={IntroCarouselType.REWARD}
            disableWishlist
            visibleItemsAmount={gtTabletLandscape ? 2 : undefined}
            width={gtTabletLandscape ? 770 : undefined}
            hiResDesktopMinValue={mediaQueryConfig.gtTabletLandscape.minWidth}
          />
        </CarouselWrapper>
        {!gtMobile && <ShowAllButton />}
      </SectionWrapper>
    </DesktopWrapper>
  )
}
