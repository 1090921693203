import React, { FC } from 'react'

import { getString } from '@vrw/data'
import { FontFamilies, color } from '../../../../style/variables'
import { media } from '../../../../style/media'
import { Content } from '../../../../components/layout'
import LinkButton, { ButtonStyle } from '../../../../components/Buttons/LinkButton/linkButton'
import { localizePath } from '../../../../dataImplementation/viewingRegionWeb'
import { PATHS } from '../../../../router/paths'

const NoRewards: FC = () => (
  <>
    <style jsx>{`
      .no-rewards {
        text-align: center;
      }
      .title {
        font-family: ${FontFamilies.barlow};
        font-size: 29px;
        font-weight: 600;
        line-height: 1.38;
        color: ${color.textStandard};
        margin-bottom: 24px;
        margin-top: 64px;
      }
      .body {
        font-family: ${FontFamilies.barlow};
        font-size: 20px;
        line-height: 1.6;
        text-align: center;
        margin: auto;
        margin-bottom: 24px;
      }

      @media ${media.tabletAndHigher} {
        .body {
          width: 606px;
        }
      }
    `}</style>
    <Content>
      <div className="no-rewards">
        <div className="title">{getString('myRewards.emptyTitle')}</div>
        <p className="body">{getString('myRewards.emptyDescription')}</p>
        <div>
          <LinkButton display="inline-block" text="Browse rewards" style={ButtonStyle.Primary} href={localizePath(PATHS.SPEND)} />
        </div>
      </div>
    </Content>
  </>
)

export default NoRewards
