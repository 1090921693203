import React, { useEffect } from 'react'
import { getCurrentPage, getError } from '@vrw/data/src/redux/pages/selectors'
import { doSpecificPageFetch } from '@vrw/data/src/redux/pages/dispatchers'
import { ContentfulEntry } from '@vrw/data/src/utils/contentfulPages'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { Page, DynamicContentFactory } from '../../components'
import { NotFound } from '../NotFound'

export const DynamicComposedPage = ({ slugName }: { slugName: string }) => {
  const dispatch = useAppDispatch()
  const viewingRegion = localeToRegion(getLocale())
  const error = useAppSelector(getError)
  const currentPage = useAppSelector(getCurrentPage)
  const { seo, content } = currentPage || {}

  useEffect(() => {
    dispatch(
      doSpecificPageFetch({
        slugName,
        ...(viewingRegion && { viewingRegion: `EN_${viewingRegion}` }),
      })
    )
  }, [viewingRegion, dispatch, slugName])

  if (error) {
    return <NotFound />
  }

  return currentPage ? (
    <Page title={`${seo?.fields?.title} | Virgin Red `} metaDescription={`${seo?.fields?.description}`}>
      {content && <DynamicContentFactory content={content as ContentfulEntry} />}
    </Page>
  ) : null
}
