import { PATHS } from '../../router/paths'

export const navItems = [
  {
    linkUrl: PATHS.MY_ACCOUNT_PERSONAL_DETAILS,
    linkText: 'Personal Details',
  },
  {
    linkUrl: PATHS.MY_ACCOUNT_SUBSCRIPTION,
    linkText: 'Manage Membership',
    navItems: [
      {
        linkUrl: PATHS.MY_ACCOUNT_SUBSCRIPTION_CANCEL,
        linkText: 'Cancel Membership',
      },
    ],
  },
  { linkUrl: PATHS.MY_ACCOUNT_LINK_ACCOUNTS, linkText: 'Link Account' },
  { linkUrl: PATHS.MY_ACCOUNT_PROMO_CODES, linkText: 'Promo codes' },
  { linkUrl: PATHS.MY_ACCOUNT_UPDATE_EMAIL, linkText: 'Update email' },
  { linkUrl: PATHS.MY_ACCOUNT_REFER_DASHBOARD, linkText: 'Refer a friend' },
  { linkUrl: PATHS.MY_ACCOUNT_SETTINGS, linkText: 'Settings' },
]
