import React, { FC } from 'react'
import { ImagesTextBlockFields, ImageFields } from './types'
import { media } from '../../style/media'
import { color as globalColor } from '../../style/variables'
import { convertToKebabCase } from '../../helpers/text'
import { Dimensions, optimizeContentfulImageUrl } from '../../helpers/images'
import { Button } from '../Buttons/Button'
import { H2Header, Body } from '..'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'

type ImagesTextBlockProps = {
  sectionName: string
  fields: ImagesTextBlockFields
}

type HelpBlockProps = {
  content: Array<ImageFields>
}

export const ImagesTextBlock: FC<ImagesTextBlockProps> = ({ sectionName, fields }) => {
  const hasButton = fields.buttonText && fields.buttonTarget
  const buttonId = hasButton ? `${sectionName}_button_${convertToKebabCase(fields?.buttonText ?? '')}` : undefined

  return (
    <>
      <style jsx>{`
        .button-wrapper {
          width: 148px;
          margin: auto;
        }

        @media ${media.tabletAndHigher} {
          .button-wrapper {
            width: 140px;
            margin-top: 8px;
          }
        }

        @media ${media.desktopAndHigher} {
          .button-wrapper {
            width: 164px;
            margin-top: 24px;
          }
        }
      `}</style>
      <GridWrapper>
        {fields.headline && (
          <GridContainer direction="column">
            <GridItem>
              <H2Header
                weight={600}
                textAlign="center"
                fontStyle="Regular"
                color={globalColor.textStandard}
                fontSize={{ mobile: 28, tablet: 24, desktop: 50 }}
                marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
                {fields.headline}
              </H2Header>
            </GridItem>
          </GridContainer>
        )}
        {fields.body && (
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={7} xl={5}>
              <Body
                textAlign="center"
                fontSize={{ mobile: 20, tablet: 14, desktop: 20 }}
                marginBottom={{ mobile: 32, tablet: 24, desktop: 32 }}
                marginTop={{ mobile: 16, tablet: 16, desktop: 24 }}>
                {fields?.body}
              </Body>
            </GridItem>
          </GridContainer>
        )}
        <HelpBlock content={fields?.processStepSubSection} />
        {hasButton && (
          <GridContainer direction="column">
            <GridItem>
              <div className="button-wrapper">
                <Button
                  id={buttonId}
                  isPrimary
                  display="block"
                  text={fields?.buttonText!}
                  onClick={() => (window.location.href = fields?.buttonTarget!)}
                />
              </div>
            </GridItem>
          </GridContainer>
        )}
      </GridWrapper>
    </>
  )
}

const HelpBlock: FC<HelpBlockProps> = ({ content }) =>
  content && (
    <>
      <style jsx>{`
        .icon {
          width: 164px;
          height: 123px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        @media ${media.tabletAndHigher} {
          .icon {
            width: 152px;
            height: 114px;
          }
        }

        @media ${media.desktopAndHigher} {
          .icon {
            width: 85%;
            height: unset;
          }
        }
      `}</style>

      <GridContainer justifyContent="center">
        {content.map(({ fields }) => {
          const iconDimensions: Dimensions = { width: 384, height: 288 }
          const iconUrl = optimizeContentfulImageUrl(fields?.image?.fields?.file?.url, 'pad', iconDimensions)
          const iconAlt = fields.image?.fields.title

          return (
            <GridItem key={convertToKebabCase(fields?.image?.fields?.title)} md={3} xs={12}>
              <img alt={iconAlt} src={iconUrl} className="icon" />
              <H2Header
                weight={600}
                textAlign="center"
                marginTop={{ mobile: 16, tablet: 16, desktop: 24 }}
                marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
                fontSize={{ mobile: 24, tablet: 18, desktop: 24 }}>
                {fields?.header}
              </H2Header>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={12}>
                  <Body
                    textAlign="center"
                    fontSize={{ mobile: 20, tablet: 14, desktop: 18 }}
                    marginTop={{ mobile: 8, tablet: 8, desktop: 16 }}
                    marginBottom={{ mobile: 32, tablet: 24, desktop: 32 }}>
                    {fields?.bodyText}
                  </Body>
                </GridItem>
              </GridContainer>
            </GridItem>
          )
        })}
      </GridContainer>
    </>
  )
