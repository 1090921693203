import { Action as ReduxAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { reducer } from './reducer'

export const CLEAR_PRIVATE_CONTENT = 'CLEAR_PRIVATE_CONTENT'
export const CLEAR_TRANSIENT_STATE = 'CLEAR_TRANSIENT_STATE'

export type RedDataState = ReturnType<typeof reducer>

export interface RedDataAction extends ReduxAction {
  type: string
  payload?: any
}

export class UserFacingError extends Error {
  constructor(
    public friendlyString: string,
    debugError?: string
  ) {
    super(`UserFacingError: ${debugError || 'no debug message'} (presented as "${friendlyString}")`)
  }
}

export type ContentfulRichText = any // Used for API documentation

export type DispatchFunction = ThunkDispatch<RedDataState, void, RedDataAction>
export type GetRedDataState = () => RedDataState
