import { DOTCOM_URL } from '../config'

export const PARTIAL_PATHS = {
  VOUCHERS: 'vouchers',
  ORDERS: 'orders',
}

export const PATHS = {
  HOME: '',
  NOT_FOUND: `/404`,

  TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  PRIVACY: `/privacy-policy`,
  MODERN_SLAVERY_STATEMENT: `/modern-slavery-statement`,
  HELP: 'https://membersupport.red.virgin.com/',
  COMPOSE_PAGE: `/:pagePath`,

  SPEND: `/spend-virgin-points`,
  SPEND_REWARD: `/spend-virgin-points/:rewardPath`,
  EARN: `/earn-virgin-points`,
  EARN_REWARD: `/earn-virgin-points/:rewardPath`,

  SUBSCRIBE: `/subscribe`,
  SUBSCRIPTION_PAYMENT: `/subscription-payment/:planId`,

  BRAND_DETAILS: `/brands/:brandPath`,

  LOGIN: `/login`,
  LOGIN_SUCCESS: `/login-success`,
  LOGOUT_SUCCESS: `/logout-success`,
  PARTNER_LOGIN_SUCCESS: `/partner-login-success`,

  ONBOARDING: `/onboarding`,
  ONBOARDING_PERSONAL_DETAILS: `/onboarding/personal-details`,
  ONBOARDING_TERMS_AND_CONDITIONS: `/onboarding/terms-and-conditions`,
  ONBOARDING_LINK_ACCOUNTS_DEPRECATED: `/onboarding/link-vaa`,
  ONBOARDING_LINK_ACCOUNTS_ERROR: `/onboarding/link-vaa-error`,
  ONBOARDING_LINK_ACCOUNTS_SUCCESS: `/onboarding/link-vaa-success`,
  ONBOARDING_AUTO_LINK_ACCOUNTS: `/onboarding/link-partner-account`,
  ONBOARDING_PARTNER_LINK_VAA: `/onboarding/partner-link-vaa`,
  ONBOARDING_COMMUNICATION_PREFERENCES: `/onboarding/communication-preferences`,
  ONBOARDING_WELCOME: `/onboarding/welcome`,

  MY_REWARDS: `/my-rewards/*`,
  MY_REWARDS_VOUCHERS: `/my-rewards/${PARTIAL_PATHS.VOUCHERS}`,
  MY_REWARDS_ORDERS: `/my-rewards/${PARTIAL_PATHS.ORDERS}`,
  VOUCHER: `/my-rewards/vouchers/:voucherId`,
  ORDER: `/my-rewards/orders/:orderId`,

  ORDER_CHECKOUT: `/order-checkout/*`,
  ORDER_CHECKOUT_ADD_ADDRESS: `/order-checkout/add-address`,

  ACTIVITY: `/activity`,

  MY_ACCOUNT: `/account`,
  MY_ACCOUNT_PERSONAL_DETAILS: `/account/personal-details`,
  MY_ACCOUNT_LINK_ACCOUNTS: `/account/link-accounts`,
  MY_ACCOUNT_PROMO_CODES: `/account/promo-codes`,
  MY_ACCOUNT_REFER_DASHBOARD: `/account/refer-a-friend-dashboard`,
  MY_ACCOUNT_SETTINGS: `/account/settings`,
  MY_ACCOUNT_UPDATE_EMAIL: `/account/update-email`,
  MY_ACCOUNT_VERIFY_EMAIL: `/account/verify-email`,
  MY_ACCOUNT_SUBSCRIPTION: `/account/subscription`,
  MY_ACCOUNT_SUBSCRIPTION_CANCEL: `/account/subscription/cancel`,
  MY_ACCOUNT_CREDIT_CARD_HUB: `/account/credit-card-hub`,
  EMAIL_UPDATED: `/email-updated`,
  EMAIL_UPDATED_SUCCESS: `/email-updated-success`,
  NEW_HOME: `/newHome`,
  REFER_A_FRIEND: '/refer-a-friend',
  FOOTER_REFER_A_FRIEND: '/earn-virgin-points/refer-a-friend-to-virgin-red-and-both-earn-1-000-points-501252',

  SUBSCRIPTION_CONFIRMATION: '/subscription-confirmation',
  REWARDS_CREDIT_CARD: '/rewards-credit-card',
  REWARDS_CREDIT_CARD_WAITLIST: '/rewards-credit-card-waitlist',
} as const

const dotcom = DOTCOM_URL

export const virginRedPath = '/virgin-red'

export const DOTCOM_PATHS = {
  HOME: `${dotcom}`,
  CONTACT_US: `${dotcom}/contact-us`,
  MSA: `${dotcom}/modern-slavery-act`,
  TAX_STRATEGY: `${dotcom}/tax-strategy-statement`,
  TERMS: `${dotcom}/terms-and-conditions`,
  CO_GOV: `${dotcom}/corporate-governance`,
  PRIVACY: `${dotcom}/privacy-policy`,
  COOKIES: `${dotcom}/cookie-policy`,
  REPORT_A_SCAM: `${dotcom}/report-a-scam`,
  VIRGIN_GROUP_FAQS: `${dotcom}/virgin-group-faqs`,
  VIRGIN_COMPANIES: `${dotcom}/virgin-companies`,
  NEWSLETTER: `${dotcom}/newsletter`,
  CANDIDATE_PRIVACY_NOTICE: `${dotcom}/doc/Candidate-Privacy-Notice.pdf`,
}

export const EXTERNAL_PATHS = {
  GOOGLE_PLAY_DOWNLOAD_URL: 'https://play.google.com/store/apps/details?id=com.virgin.red.app&hl=en_GB',
  APPLE_STORE_DOWNLOAD_URL: 'https://apps.apple.com/gb/app/virgin-red/id1533988059',
  HELP_URL: 'https://membersupport.red.virgin.com/hc/en-gb',
  BUY_POINTS: 'https://sso2.points.com/SSOGateway/gateway.do?ssoSource=virginredlogin&ssoProduct=buy',
  GIFT_POINTS: 'https://sso2.points.com/SSOGateway/gateway.do?ssoSource=virginredlogin&ssoProduct=gift',
  TRANSFER_POINTS: 'https://sso2.points.com/SSOGateway/gateway.do?ssoSource=virginredlogin&ssoProduct=transfer',
}
