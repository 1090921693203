import React, { FC, useEffect, useRef } from 'react'
import { PATHS } from '../../router/paths'
import { LoadSpinner } from '../../components'
import { LogoutSuccessProps } from './types'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'
import { useAppSelector } from '../../redux/hooks/hooks'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'

const LogoutSuccess: FC<LogoutSuccessProps> = ({ isUnauthenticating, doLogoutWebSuccess }) => {
  const navigate = useRedNavigate()
  const homePageViewingRegion = useRef(useAppSelector(getProfileData)?.country)

  useEffect(() => {
    if (isUnauthenticating) {
      doLogoutWebSuccess()
    } else {
      navigate(PATHS.HOME, {}, homePageViewingRegion.current)
    }
  })
  return (
    <>
      <style jsx>{`
        html,
        body {
          height: 100vh;
        }
        .loading {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          width: 100%;
        }
      `}</style>
      <div className="loading">
        <LoadSpinner />
      </div>
    </>
  )
}

export { LogoutSuccess }
