import React, { PropsWithChildren } from 'react'
import { Content } from '../../components'
import { InPageNavigation } from '../../components'
import H2Header from '../../components/typography/H2Header'
import { media } from '../../style/media'
import { color } from '../../style/variables'
import { getString } from '@vrw/data'
import { useFilteredNavItems } from './useFilteredNavItems'

const MyAccountBackground = ({ children }: PropsWithChildren) => {
  const filteredNavItems = useFilteredNavItems()

  return (
    <>
      <style jsx>
        {`
          .account-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 80px;
          }

          .switch-container {
            align-items: left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
          }

          .line {
            background-color: ${color.lightGrey};
            height: 1px;
            margin-top: 20px;
            margin-bottom: 26px;
          }

          @media ${media.tabletAndHigher} {
            .account-wrapper {
              flex-direction: row;
              margin-bottom: 200px;
            }

            .switch-container {
              margin-top: 32px;
              margin-left: 10%;
              max-width: 375px;
            }

            .in-page-nav-container {
              margin-right: 40px;
              min-width: 200px;
            }
          }
          @media ${media.desktopAndHigher} {
            .account-wrapper {
              margin-bottom: 300px;
            }

            .switch-container {
              margin-top: 40px;
              margin-left: 15%;
              max-width: 512px;
            }
        `}
      </style>
      <Content>
        <div className="account-wrapper">
          <div className="in-page-nav-container">
            <div className="header-wrapper">
              <H2Header textAlign="left" fontSize={{ mobile: 28, tablet: 28, desktop: 40 }} fontStyle="italic" weight={600}>
                {getString('account.page.title')}
              </H2Header>
            </div>
            <InPageNavigation navItems={filteredNavItems} />
          </div>
          <div className="line" />
          <div className="switch-container">{children}</div>
        </div>
      </Content>
    </>
  )
}

export { MyAccountBackground }
