import React, { FC } from 'react'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import { MobileAppsSplash, RewardsListPrivateSpend, RewardsFilterWrapper } from '../../components'
import { CurvedContainer } from '../../components/CurvedContainer'
import { getString } from '@vrw/data'
import { Profile } from '@vrw/data/src/redux/profile/types'
import { ExclusiveRewardsSection } from '../../components/ExclusiveRewardsSection'

export const SpendPrivate: FC<{ profile: Profile | null }> = ({ profile }) => {
  return (
    <>
      <style jsx>{`
        .hidden-seo-header {
          visibility: hidden;
          margin: 0;
          height: 0;
        }
        .pink {
          height: 210px;
          margin-top: 130px;
          margin-bottom: -350px;
          background-image: linear-gradient(to bottom, #fce6e6, #fef4f4 57%, #ffffff);
        }

        @media ${media.tabletAndHigher} {
          .pink {
            height: 200px;
            margin-bottom: -330px;
          }
        }

        @media ${media.desktopAndHigher} {
          .pink {
            height: 200px;
            margin-bottom: -350px;
          }
        }
      `}</style>

      <CurvedContainer position="bottom" height={65} offset={140} overlayColor={color.darkPinkBackground}>
        <h1 className="hidden-seo-header">
          {profile && getString('spend.private.title').replace('{{firstName}}', profile.firstName).toLocaleUpperCase()}
        </h1>
        <ExclusiveRewardsSection />
        <RewardsFilterWrapper />
      </CurvedContainer>
      <div className="pink" />
      <RewardsListPrivateSpend />
      <MobileAppsSplash />
    </>
  )
}
