import React, { useEffect } from 'react'
import { stepUpFailure } from '@vrw/data/src/redux/auth/actions'
import { LoadSpinner } from '..'
import { useAppDispatch } from '../../redux/hooks/hooks'
import { PATHS } from '../../router/paths'
import { stopOrder } from '@vrw/data/src/redux/orders/actions'
interface LoadingScreenProps {
  isSteppedUp: boolean
  isSteppingUp: boolean
}

const LoadingScreen = ({ isSteppedUp, isSteppingUp }: LoadingScreenProps) => {
  const visible = isSteppedUp || isSteppingUp
  const dispatch = useAppDispatch()

  useEffect(() => {
    const currentPath = window.location.pathname
    const loginSuccesScreen = currentPath.includes(PATHS.LOGIN_SUCCESS)
    if (!loginSuccesScreen) {
      dispatch(stepUpFailure())
      dispatch(stopOrder())
    }
  }, [dispatch])

  return (
    <>
      <style jsx>{`
        .loading {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          background: white;
          align-items: center;
          display: flex;
          justify-content: center;
          transition: 150ms;
          opacity: ${visible ? 1 : 0};
          pointer-events: ${visible ? 'initial' : 'none'};
          z-index: 1;
        }
      `}</style>
      <div className="loading">
        <LoadSpinner />
      </div>
    </>
  )
}

export { LoadingScreen }
