import React, { FC } from 'react'

import { formatDateTimeFull } from '@vrw/data/src/utils/formatters'

import { color } from '../../style/variables'
import { CalendarIcon, LocationIcon } from '../icons'

interface DateAndLocationProps {
  location: string
  dateAndTime: string
}

const DateAndLocation: FC<DateAndLocationProps> = ({ location, dateAndTime }) => (
  <>
    <style jsx>{`
      .date-location-wrapper {
        display: flex;
        flex-direction: column;
      }
      .row {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: ${color.textStandard};
        margin-left: 10px;
      }
    `}</style>
    <div className="date-location-wrapper">
      {location && (
        <div className="row" data-testid="reward-location">
          <LocationIcon size={20} color={color.textStandard} />
          <div className="text">{location}</div>
        </div>
      )}
      {dateAndTime && (
        <div className="row" data-testid="reward-date-time">
          <CalendarIcon size={20} color={color.textStandard} />
          <div className="text">{formatDateTimeFull(dateAndTime, true)}</div>
        </div>
      )}
    </div>
  </>
)

export { DateAndLocation }
