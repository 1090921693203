import React from 'react'
import { ColorTokens, H1, H2, H3, H4, H5, H6, Paragraph, styled, YStack, Text } from '@red-ui/components'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS, Document } from '@contentful/rich-text-types'
import { isUrlExternal } from '../../helpers/urlHelper'
import { DynamicContentFactory } from './DynamicContentFactory'
import { Link } from '../Link'

const Mark = styled(Text, {
  color: 'inherit',
  fontSize: 'unset',
})

const optionOverrides: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <H1
        color="unset"
        children={children}
        $tabletLandscape={{
          size: '$9',
        }}
        $tabletPortrait={{
          size: '$7',
        }}
      />
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <H2
        color="unset"
        children={children}
        $tabletLandscape={{
          size: '$8',
        }}
        $tabletPortrait={{
          size: '$6',
        }}
      />
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <H3
        color="unset"
        children={children}
        $tabletLandscape={{
          size: '$7',
        }}
        $tabletPortrait={{
          size: '$5',
        }}
      />
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <H4
        color="unset"
        children={children}
        $tabletLandscape={{
          size: '$5',
        }}
        $tabletPortrait={{
          size: '$4',
        }}
      />
    ),
    [BLOCKS.HEADING_5]: (_, children) => <H5 color="unset" children={children} $tabletLandscape={{ size: '$4' }} />,
    [BLOCKS.HEADING_6]: (_, children) => <H6 color="unset" children={children} $tabletLandscape={{ size: '$3' }} />,
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Paragraph
        color="unset"
        children={children}
        marginBottom="$2"
        $tabletLandscape={{ size: '$5' }}
        $tabletPortrait={{
          size: '$4',
        }}
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => (
      <YStack marginBottom="$2">
        <DynamicContentFactory content={node.data?.target} />
      </YStack>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} external={isUrlExternal(node.data.uri)} asChild children={<Mark>{children}</Mark>} />
    ),
  },
  renderMark: {
    // ensures we are providing symmetrical rendering for all mark types
    [MARKS.BOLD]: (text) => <Mark tag="b" children={text} fontWeight={600} />,
    [MARKS.ITALIC]: (text) => <Mark tag="i" children={text} />,
    [MARKS.UNDERLINE]: (text) => <Mark tag="u" children={text} />,
    [MARKS.CODE]: (text) => <Mark tag="code" children={text} />,
    [MARKS.SUBSCRIPT]: (text) => <Mark tag="sub" children={text} />,
    [MARKS.SUPERSCRIPT]: (text) => <Mark tag="sup" children={text} />,
  },
}

export const RichTextRenderer = YStack.styleable<{ content: Document; options?: Options; color?: ColorTokens }>(
  ({ content, options = optionOverrides, ...props }, ref) => (
    <YStack {...props} ref={ref}>
      {documentToReactComponents(content, options)}
    </YStack>
  )
)
