import React from 'react'
import { YStack, Icon, View } from '@red-ui/components'
import { getString } from '@vrw/data'
import { InfoBoxWrapper, InfoText } from './styles'
import { format } from 'date-fns'

type Props = {
  subscriptionEndDate: Date
}

export const RestartSubscriptionInfoBox = ({ subscriptionEndDate }: Props) => {
  const onRestartMembership = () => console.log('Restart membership under development')

  const endDateText = format(subscriptionEndDate, 'd MMM yyyy')

  return (
    <InfoBoxWrapper>
      <View>
        <Icon.Info size={20} marginRight="$2" />
      </View>
      <YStack flex={1}>
        <InfoText>
          {getString('account.subscription.content.restart.title')}
          <InfoText fontWeight="600">{endDateText}.</InfoText>
        </InfoText>
        <InfoText onPress={onRestartMembership} cursor="pointer" userSelect="none" fontWeight="700" color="$red6">
          {getString('account.subscription.content.restart.button')}
        </InfoText>
      </YStack>
    </InfoBoxWrapper>
  )
}
