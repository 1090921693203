import { connect } from 'react-redux'
import { RootState } from '../../redux/types'
import { AuthWrapper } from './AuthWrapper'
import { getIsAuthenticated, getIsAuthenticating } from '@vrw/data/src/redux/auth/selectors'

const mapStateToProps = (state: RootState) => ({
  isAuthenticating: getIsAuthenticating(state),
  isAuthenticated: getIsAuthenticated(state),
})

const AuthWrapperContainer = connect(mapStateToProps)(AuthWrapper)

export { AuthWrapperContainer }
