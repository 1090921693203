import React, { FC, useEffect } from 'react'
import { Document } from '@contentful/rich-text-types'
import { ContentfulLocale } from '@vrw/data/src/utils/contentfulPages'
import { getString } from '@vrw/data'
import { DialogOptions, DialogType } from '@vrw/data/src/redux/dialog/types'
import { media } from '../../../style/media'
import ContentfulCopy from '../../../components/richText/contentfulCopy'
import { getLocale, localeToRegion } from '../../../dataImplementation/viewingRegionWeb'
import H3Header from '../../../components/typography/Red/H3Header'
import { Button } from '../../../components/Buttons/Button'
import { LoadSpinner, Page } from '../../../components'
import { OnboardingBackground } from '../OnboardingBackground'
import { color } from '../../../style/variables'

interface TermsAndConditionsProps {
  doFetchTermsAndConditions: (viewingRegion?: ContentfulLocale | string) => void
  doFetchPrivacyPolicy: (viewingRegion?: ContentfulLocale | string) => void
  doShowDialog: (dialogOptions: DialogOptions) => void
  doSubmitOnboarding: Function
  isTermsLoading: boolean
  isPrivacyPolicyLoading: boolean
  isProfileUpdating: boolean
  termsTitle?: string
  termsBody?: Document
  termsVersion?: string
  privacyBody?: Document
  privacyVersion?: string
}

const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  doFetchTermsAndConditions,
  doFetchPrivacyPolicy,
  doShowDialog,
  doSubmitOnboarding,
  isProfileUpdating,
  isTermsLoading,
  isPrivacyPolicyLoading,
  termsTitle,
  termsBody,
  termsVersion,
  privacyBody,
}) => {
  const viewingRegion = localeToRegion(getLocale())

  useEffect(() => {
    if (!termsBody) viewingRegion ? doFetchTermsAndConditions(`EN_${viewingRegion}`) : doFetchTermsAndConditions()
    if (!privacyBody) viewingRegion ? doFetchPrivacyPolicy(`EN_${viewingRegion}`) : doFetchPrivacyPolicy()
  }, [termsBody, privacyBody, doFetchTermsAndConditions, doFetchPrivacyPolicy, viewingRegion])

  if (isTermsLoading || isPrivacyPolicyLoading || !termsBody || !privacyBody) {
    return <LoadSpinner position="absolute" />
  }

  const showDialog = () =>
    doShowDialog({
      callbackOnConfirm: () => doSubmitOnboarding({ termsVersion }),
      titleTextId: 'onboarding.termsAndConditions.confirmPopup.title',
      bodyTextId: 'onboarding.termsAndConditions.confirmPopup.description',
      buttonCancelTextId: 'actions.cancel',
      buttonConfirmTextId: 'actions.agree',
      type: DialogType.ALERT,
    })

  return (
    <Page
      testId="onboarding-terms-and-conditions"
      title="Terms & Conditions | Virgin Red"
      showNavigation={false}
      showRedFooter={false}
      showFooter={false}
    >
      <OnboardingBackground>
        <style jsx>{`
          @keyframes in {
            from {
              transform: translateY(20px);
              opacity: 0;
            }
            to {
              transform: translateY(0px);
              opacity: 1;
            }
          }
          .parentContainer {
            animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
          }
          .parentContainer :global(.header-three) {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 16px;
          }
          .terms {
            width: 100%;
            height: 350px;
            padding-right: 20px;
            margin-bottom: 40px;
            overflow-y: auto;
            overflow-x: hidden;
          }
          .terms :global(.rich-text-copy p) {
            font-size: 16px;
          }

          @media ${media.mobileAndLower} {
            .button-wrapper {
              display: flex;
              justify-content: center;
            }
          }

          @media ${media.tabletAndHigher} {
            .terms {
              height: 400px;
            }
          }
        `}</style>
        <div className="parentContainer">
          <H3Header color={color.brandPrimary} textTransform="none" marginTop={{ mobile: 0 }}>
            {termsTitle}
          </H3Header>
          <div className="terms">
            <ContentfulCopy content={termsBody} />
            <ContentfulCopy content={privacyBody} />
          </div>
          <div className="button-wrapper">
            <Button
              isPrimary
              text={getString('onboarding.termsAndConditions.button')}
              onClick={showDialog}
              loading={isProfileUpdating}
              dataAttributes={{ testid: 'onboarding-terms-button' }}
            />
          </div>
        </div>
      </OnboardingBackground>
    </Page>
  )
}

export { TermsAndConditions }
