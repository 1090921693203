import { useQuery } from '@tanstack/react-query'

import { apiGetUserSubscriptionStatus } from '@vrw/data/src/api/subscriptions.api'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { getRedUserId } from '@vrw/data/src/redux/profile/selectors'
import { selectFeatureById } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'

import { useAppSelector } from '../../redux/hooks/hooks'

export const useUserSubscriptionStatus = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const webSubscriptionFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_SUBSCRIPTION))
  const redUserId = useAppSelector(getRedUserId)

  const result = useQuery({
    queryKey: ['userSubscriptionStatus', redUserId],
    queryFn: async () => apiGetUserSubscriptionStatus(),
    enabled: isAuthenticated && !!webSubscriptionFlag,
  })

  const subscriptions = result.data?.subscriptions

  return {
    ...result,
    isSubscribedToPlan: !!subscriptions?.length,
    currentSubscription: subscriptions?.[subscriptions?.length - 1],
  }
}
