import React from 'react'
import { ButtonProps, Dialog, Spinner, Image, Icon, SizableText, XStack } from '@red-ui/components'
import { useLocation, useSearchParams } from 'react-router-dom'
import { apiCreateApplication } from '@vrw/data/src/api/creditCard.api'
import { getString } from '@vrw/data'
import { errorHandler } from '@vrw/data/src/redux/errorHandler'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { doLoginWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { PUBLIC_URL, SYNCHRONY_APPLY_URL } from '../../config'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { createRelativePath } from '../../dataImplementation/viewingRegionWeb'
import { useMutation } from '@tanstack/react-query'

export const TriggerCreditApply = ({ children }: { children: React.ReactElement<ButtonProps & { onClick?: ButtonProps['onPress'] }> }) => {
  const [searchParams] = useSearchParams()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { mutate, isPending, data } = useMutation({
    mutationFn: async () => {
      const siteCode = searchParams.get('siteCode') || undefined
      const accorId = searchParams.get('accorId') || undefined

      return apiCreateApplication({ siteCode, accorId })
    },
    onError: (error) => {
      // hack to handle the error in existing error handler ( all in redux currently )
      errorHandler(dispatch, error, () => ({ type: 'DUD_ACTION' }))
    },
    onSuccess: (data) => {
      window.location.assign(`${SYNCHRONY_APPLY_URL}?dsaToken=${data.token}`)
    },
  })

  const onClickHandler = () => {
    if (isAuthenticated) mutate()
    else dispatch(doLoginWeb(createRelativePath(`${location.pathname}${location.search}`)))
  }

  return (
    <>
      {/* React clone only understands html mappings :( */}
      {React.cloneElement(children, { onClick: onClickHandler })}
      <Dialog modal open={isPending || !!data}>
        <Dialog.Portal>
          <Dialog.Overlay key="overlay" opacity={0.5} enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} backgroundColor="$black10" />
          <Dialog.Content
            bordered
            size="$3"
            maxWidth="600px"
            key="content"
            animateOnly={['transform', 'opacity']}
            enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
            gap="$4">
            <XStack alignItems="center" justifyContent="center" gap="$2">
              <Image
                source={{
                  uri: `${PUBLIC_URL}/img/virgin-logo-circle.svg`,
                  width: 50,
                  height: 50,
                }}
                alt={getString('apply.virgin.logo.alt')}
              />
              <Icon.ChevronRight color="$red7" />
              <Image
                source={{
                  uri: `${PUBLIC_URL}/img/synchrony-logo.jpg`,
                  width: 130,
                  height: 30,
                }}
                alt={getString('apply.synchrony.logo.alt')}
              />
            </XStack>

            <Dialog.Title textAlign="center" size="$5">
              {getString('rewardDetails.redirect.title').replace('{{brandName}}', 'Synchrony.com')}
            </Dialog.Title>
            <SizableText textAlign="center" size="$3">
              {getString('apply.card.dialog.body')}
            </SizableText>
            <Spinner size="large" color="$red7" />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog>
    </>
  )
}
