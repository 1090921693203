import { useQuery } from '@tanstack/react-query'
import { apiGetGroupRewardById, apiGetRewardGroups, apiGetRewardsByGroupId, RewardGroup } from '@vrw/data/src/api/rewardGroups.api'
import { Reward } from '@vrw/data/src/redux/rewards/types'

export type GroupsWithRewards = (RewardGroup & { rewards: Reward[] })[]

const fetchRewardGroupsWithRewards = async () => {
  const groups = await apiGetRewardGroups()
  const groupsWithRewards: GroupsWithRewards = await Promise.all(
    groups.map(async (group) => {
      const rewards = await apiGetRewardsByGroupId({ rewardGroupId: group.id })
      return { ...group, rewards }
    })
  )
  return groupsWithRewards
}

export const rewardGroupsQueryKeys = {
  all: () => ['rewardGroups'] as const,
  groupRewardById: (rewardId: string, rewardGroupId: string) => [...rewardGroupsQueryKeys.all(), rewardId, rewardGroupId] as const,
}

export const useRewardGroups = () =>
  useQuery({
    queryKey: rewardGroupsQueryKeys.all(),
    queryFn: () => fetchRewardGroupsWithRewards(),
  })

export const useGroupRewardById = (rewardId: string, rewardGroupId: string) =>
  useQuery({
    queryKey: rewardGroupsQueryKeys.groupRewardById(rewardId, rewardGroupId),
    queryFn: ({ queryKey: [, rewardId, rewardGroupId] }) => apiGetGroupRewardById({ rewardId, rewardGroupId }),
    enabled: !!rewardId && !!rewardGroupId,
  })
