import { useMutation } from '@tanstack/react-query'
import { apiCancelUserSubscription, CancelSubscriptionRequestBody } from '@vrw/data/src/api/subscriptions.api'
import { ApiErrorResponse } from '@vrw/data/src/utils'

export const useCancelSubscription = (onSuccess: () => void) => {
  const { mutate: cancelSubscription, ...mutation } = useMutation<{}, ApiErrorResponse, CancelSubscriptionRequestBody>({
    mutationFn: apiCancelUserSubscription,
    onSuccess,
  })

  return {
    ...mutation,
    cancelSubscription,
  }
}
