import * as React from 'react'
import { InPageNavigationItem, IPageNavItem } from './InPageNavigationItem'

interface IPageNavigation {
  navItems: IPageNavItem[]
}

export const InPageNavigation: React.FC<IPageNavigation> = ({ navItems }) => (
  <>
    <style jsx>{`
      .unstyled {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    `}</style>
    <nav>
      <ul className="unstyled">{navItems?.map((item) => <InPageNavigationItem key={item.linkUrl} {...item} />)}</ul>
    </nav>
  </>
)
