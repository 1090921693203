import { connect } from 'react-redux'

import { getCurrentPage } from '@vrw/data/src/redux/pages/selectors'
import { doSpecificPageFetch } from '@vrw/data/src/redux/pages/dispatchers'

import { DispatchFunction, RootState } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { HomePrivate } from './HomePrivate'

const mapStateToProps = (state: RootState) => {
  return {
    currentPage: getCurrentPage(state),
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doSpecificPageFetch,
    },
    dispatch
  )

export const HomePrivateContainer = connect(mapStateToProps, mapDispatchToProps)(HomePrivate)
