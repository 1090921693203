import React, { FC, useEffect, MouseEvent, DragEvent, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useMediaPredicate } from 'react-media-hook'
import AliceCarousel from 'react-alice-carousel'
import { getTransitionProperty } from 'react-alice-carousel/lib/utils'

import { getString } from '@vrw/data'
import { WishedType, Wishlist } from '@vrw/data/src/redux/wishlist/types'

import { media, size } from '../../../style/media'
import { color } from '../../../style/variables'
import IconMapper, { Icon } from '../../icons/IconMapper'
import { IntroCarouselTile, IntroCarouselTileProps } from './IntroCarouselTile'
import { HeroBlock } from '../HeroBlock'
import { HeroBlockFields } from '../types'
import { CustomLink } from '../../links'
import { Content } from '../../layout'
import { H3Header } from '../../typography'
import { RewardType } from '@vrw/data/src/redux/rewards/types'

export enum IntroCarouselType {
  REWARD = 'reward',
  EARN = 'earn',
  EDITORIAL = 'editorial',
}

export interface IntroCarouselBlockProps {
  items: () => IntroCarouselTileProps[]
  isLoading: boolean
  doGetItems: () => void
  header?: HeroBlockFields
  type: IntroCarouselType
  title?: string
  seeAllLink?: string
  wishlist?: Wishlist
  doRemoveRewardFromWishlist?: (rewardId: string, rewardType: WishedType) => void
  doAddRewardToWishlist?: (rewardId: string, rewardType: WishedType) => void
  isLoadingWishlist?: boolean
  disableWishlist?: boolean
  visibleItemsAmount?: number
  width?: number
  hiResDesktopMinValue?: string | number
}

export const IntroCarouselBlock: FC<IntroCarouselBlockProps> = ({
  items,
  isLoading,
  type,
  doGetItems,
  doRemoveRewardFromWishlist,
  doAddRewardToWishlist,
  header,
  title,
  seeAllLink,
  wishlist,
  isLoadingWishlist,
  disableWishlist,
  visibleItemsAmount,
  width,
  hiResDesktopMinValue,
}) => {
  let isSlideChanging = false
  const carousel = useRef(null)
  const [index, setIndex] = useState(0)
  const carouselItems = items()
  const isLargeDesktop = useMediaPredicate('(min-width: 1300px)')
  let maxVisibleItems = isLargeDesktop ? 4 : 3
  if (visibleItemsAmount) maxVisibleItems = visibleItemsAmount

  const setTransition = (animationDuration: number = 0) => {
    const carouselList = carousel.current
      ? (ReactDOM.findDOMNode(carousel.current) as Element).getElementsByClassName('alice-carousel__stage')[0]
      : null
    if (carouselList) {
      ;(carouselList as HTMLElement).style.transition = getTransitionProperty({
        animationDuration,
      })
    }
  }

  // Prevents the propagation of the Drag and Drop events.
  // It fixes some issues in Firefox.
  const handleDragStart = (e: DragEvent) => {
    e.preventDefault()
    setTransition()
  }

  const handleMouseUp = (e: MouseEvent) => setTransition(400)

  // Prevents the propagation of the click event.
  // It stops to open the page if "Link" is clicked during the Drag and Drop.
  const handleOnClick = (e: MouseEvent) => (preventDefault: boolean) => {
    if (preventDefault) {
      e.preventDefault()
    }
  }
  const onWishlist = (rewardId: string) => {
    const reward = wishlist?.rewards.find((wishlistReward) => wishlistReward.rewardId === rewardId)
    return reward !== undefined
  }

  const getOnWishListIconClick = (rewardId: string, type: RewardType) => {
    const wishlistType = type === 'reward' ? 'SPEND' : 'EARN'
    if (onWishlist(rewardId)) {
      return doRemoveRewardFromWishlist ? () => doRemoveRewardFromWishlist(rewardId, wishlistType) : undefined
    } else {
      return doAddRewardToWishlist ? () => doAddRewardToWishlist(rewardId, wishlistType) : undefined
    }
  }

  useEffect(() => {
    doGetItems()
  }, [doGetItems])

  if (!carouselItems.length) return null

  return (
    <>
      <style jsx>{`
        .intro-carousel {
          width: ${width ? `${width}px` : '100%;'};
        }
        .intro-carousel :global(.card) {
          width: 240px;
        }
        .intro-carousel :global(.card a) {
          background: ${color.white};
          border: solid 1px ${color.lightGrey};
          border-radius: 8px;
          display: inline-block;
          margin-right: 15px;
          overflow: hidden;
          width: 225px;
        }
        .intro-carousel :global(.alice-carousel__wrapper) {
          padding-bottom: 18px !important;
          padding-left: 20px !important;
        }
        .intro-carousel__slider {
          max-width: ${size.hiResDesktopMin}px;
          margin: 0 auto 50px;
          position: relative;
          z-index: 1;
        }
        .intro-carousel :global(.alice-carousel__prev-btn),
        .intro-carousel :global(.alice-carousel__next-btn) {
          display: none;
          cursor: pointer;
          height: 50px;
          left: 25px;
          padding: 0;
          position: absolute;
          top: 210px;
          width: 50px;
          z-index: 1;
        }
        .intro-carousel :global(.alice-carousel__prev-btn .alice-carousel__icon-btn),
        .intro-carousel :global(.alice-carousel__next-btn .alice-carousel__icon-btn) {
          align-items: center;
          background: ${color.brandPrimary};
          border: none;
          border-radius: 100%;
          cursor: pointer;
          display: flex;
          height: 50px;
          justify-content: center;
          padding: 0;
          width: 50px;
          box-shadow: 2px 2px 10px ${color.greyTransparent2};
        }
        .intro-carousel :global(.alice-carousel__next-btn) {
          left: unset;
          right: 25px;
        }
        .intro-carousel__pre-header {
          display: flex;
          justify-content: space-between;
        }
        .intro-carousel__pre-header :global(a) {
          color: ${color.brandPrimary};
          font-size: 18px;
          font-weight: 600;
          padding: 10px 0 0;
          text-decoration: none;
        }
        @media ${media.tabletAndHigher} {
          .intro-carousel :global(.card) {
            user-select: none;
            width: 233px;
          }
          .intro-carousel :global(.card a) {
            margin-right: 20px;
            width: 211px;
          }
          .intro-carousel :global(.alice-carousel__wrapper) {
            padding-left: 44px !important;
          }
          .intro-carousel :global(.content) {
            padding: 0 20px;
          }
          .intro-carousel__slider {
            ${header ? 'margin-top: -43px;' : ''}
          }
          .intro-carousel :global(.hero-block .text-panel) {
            ${header ? 'margin-bottom: 40px;' : ''}
          }
        }
        @media ${media.desktopAndHigher} {
          .intro-carousel :global(.card) {
            width: 334px;
          }
          .intro-carousel :global(.card a) {
            margin-right: 24px;
            width: 312px;
          }
          .intro-carousel :global(.alice-carousel__wrapper) {
            padding-left: 60px !important;
          }
          .intro-carousel :global(.alice-carousel__prev-btn),
          .intro-carousel :global(.alice-carousel__next-btn) {
            display: block;
          }
          .intro-carousel :global(.content) {
            padding: 0;
          }
          .intro-carousel__slider {
            ${header ? 'margin-top: -48px;' : ''}
          }
          .intro-carousel :global(.hero-block .text-panel) {
            ${header ? 'margin-bottom: 48px;' : ''}
          }
        }
        @media (min-width: ${hiResDesktopMinValue ?? size.hiResDesktopMin + 1}px) {
          .intro-carousel :global(.alice-carousel__wrapper::before),
          .intro-carousel :global(.alice-carousel__wrapper::after) {
            z-index: 1;
            content: '';
            width: 80px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 100%);
          }
          .intro-carousel :global(.alice-carousel__wrapper::after) {
            left: unset;
            right: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
          }
          .intro-carousel__slider {
            ${header ? 'margin-top: -145px;' : ''}
          }
          .intro-carousel :global(.hero-block .text-panel) {
            ${header ? 'margin-bottom: 145px !important;' : ''}
          }
        }
      `}</style>
      <div className="intro-carousel" data-testid="intro-carousel" ref={carousel}>
        {title && (
          <Content>
            <div className="intro-carousel__pre-header">
              <H3Header
                textAlign="left"
                weight={600}
                marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                marginBottom={{ mobile: 15, tablet: 15, desktop: 15 }}
                fontSize={{ mobile: 20, tablet: 28, desktop: 40 }}>
                {title}
              </H3Header>
              {seeAllLink && <CustomLink to={seeAllLink}>{getString('home.introCarousel.link')}</CustomLink>}
            </div>
          </Content>
        )}
        {header && <HeroBlock fields={header} bottomGradient />}
        <div className="intro-carousel__slider">
          {!isLoading && carouselItems.length && (
            <AliceCarousel
              activeIndex={index}
              mouseTracking
              items={carouselItems.map((item, index) => (
                <div
                  key={index}
                  className="card"
                  onDragStart={handleDragStart}
                  onMouseUp={handleMouseUp}
                  data-testid={item.type === IntroCarouselType.REWARD ? `reward-tile-${item.rewardId}` : `earn-tile-${item.rewardId}`}>
                  <IntroCarouselTile
                    key={item.rewardId}
                    {...item}
                    disableWishlist={disableWishlist || !!item.subscriptionPlanId}
                    onClick={(e) => handleOnClick(e)(isSlideChanging)}
                    isWished={onWishlist(item.rewardId)}
                    onWishListIconClick={getOnWishListIconClick(item.rewardId, item.type)}
                    isLoadingWishlist={isLoadingWishlist}
                  />
                </div>
              ))}
              autoWidth
              disableDotsControls
              disableButtonsControls
              onSlideChange={() => (isSlideChanging = true)}
              onSlideChanged={(e) => {
                isSlideChanging = false
                setIndex(e.item)
              }}
              paddingRight={48}
            />
          )}
          {index > 0 && (
            <div className="alice-carousel__prev-btn">
              <button
                type="button"
                aria-label="Previous"
                className="alice-carousel__icon-btn"
                onClick={() => {
                  setIndex(index - maxVisibleItems)
                  setTransition(400)
                }}>
                <IconMapper icon={Icon.ArrowLeft} color={color.white} size={20} />
              </button>
            </div>
          )}
          {index < carouselItems.length - maxVisibleItems && (
            <div className="alice-carousel__next-btn">
              <button
                type="button"
                aria-label="Next"
                className="alice-carousel__icon-btn"
                onClick={() => {
                  setIndex(index + maxVisibleItems)
                  setTransition(400)
                }}>
                <IconMapper icon={Icon.ArrowRight} color={color.white} size={20} />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
