import { connect } from 'react-redux'

import { getIsUpdating } from '@vrw/data/src/redux/profile/selectors'
import { doUpdateEmail } from '@vrw/data/src/redux/profile/dispatchers'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

import { UpdateEmail } from './UpdateEmail'

const mapStateToProps = (state: RootState) => ({
  isUpdating: getIsUpdating(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUpdateEmail,
    },
    dispatch
  )

const UpdateEmailContainer = connect(mapStateToProps, mapDispatchToProps)(UpdateEmail)

export { UpdateEmailContainer }
