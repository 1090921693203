import { datadogRum } from '@datadog/browser-rum'
import { ENV, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SERVICE_NAME, API_URL } from '../config'
import { logger } from '@vrw/data/src/utils'

const trimUrlPrefix = (url: string) => url.replace(/^https?:\/\//, '')

export const initDatadog = () => {
  if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN || !DATADOG_SERVICE_NAME) {
    throw new Error('Missing required datadog env variables')
  }

  logger.log('>>>>> Enabled RUM')

  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: DATADOG_SERVICE_NAME,
    env: ENV,
    sessionSampleRate: 40,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [trimUrlPrefix(API_URL || '')],
  })

  // allows us to have screen recordings of users interactions
  datadogRum.startSessionReplayRecording()
}
