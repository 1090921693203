import { Reward } from '@vrw/data/src/redux/rewards/types'

import { RewardTileProps } from '..'

const formatRewardTiles = (reward: Reward): RewardTileProps => {
  const { rewardId, cost, content, redemptionType } = reward || {}
  const { imageUrl, brandDetails, title, lead, slug } = content || {}
  return {
    type: 'reward',
    rewardId,
    imageUrl,
    brandLogoUrl: brandDetails?.brandLogo || '',
    brandName: brandDetails?.brandName || '',
    title,
    lead: lead ?? '',
    cost,
    redemptionType,
    ...(slug && { slug }),
  }
}

export { formatRewardTiles }
