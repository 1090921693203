import React, { FC, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'

import { getString } from '@vrw/data'
import { RewardCategory, CategoryCostCounts } from '@vrw/data/src/redux/categories/types'
import { getPointsLabels } from '@vrw/data/src/utils'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import { FilterIcon } from '../icons'
import { useNavigate } from 'react-router-dom'
import { useSetInitialFilterState } from '../../helpers/useSetInitialFilterState/useSetInitialFilterState'

export interface RewardsFilterProps {
  categories?: RewardCategory[]
  categoryCostCounts?: CategoryCostCounts
  doFetchCategories: () => void
  isFiltersChanged: boolean
  isLastResultsFiltered: boolean
  isFiltersSet: boolean
  selectedCategories: string[]
  selectedCategoryCostCounts: string[]
  clearCategoryCostCountFilter: () => void
  clearAllFilters: () => void
  clearPaginationFromView?: () => void
  toggleCategorySelected: (category: string) => void
  toggleCategoryCostCountSelected: (categoryCostCount: string) => void
  doUpdateRewards: () => void
}

export const getCounts = (costCounts: CategoryCostCounts, selectedCategoryName: string = 'all', pointsRange: string): number => {
  let counts = 0
  if (selectedCategoryName in costCounts) {
    for (const [key, value] of Object.entries(costCounts[selectedCategoryName])) {
      if (key === pointsRange) {
        counts = value
      }
    }
  }
  return counts
}

export const RewardsFilter: FC<RewardsFilterProps> = ({
  categories,
  categoryCostCounts,
  doFetchCategories,
  isFiltersChanged,
  isLastResultsFiltered,
  selectedCategories,
  selectedCategoryCostCounts,
  clearCategoryCostCountFilter,
  clearAllFilters,
  clearPaginationFromView,
  toggleCategorySelected,
  toggleCategoryCostCountSelected,
  doUpdateRewards,
  isFiltersSet,
}) => {
  const [isVisible, setVisible] = useState(false)

  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)

  const handleKeyPressSelect = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const handleKeyPressCategoryItem = (event: React.KeyboardEvent, categoryName?: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      event.stopPropagation()
      handleClickCategoryItem(categoryName)
    }
  }

  const handleClickCategoryItem = (categoryName?: string) => {
    !!clearPaginationFromView && clearPaginationFromView()
    clearAllFilters()
    if (categoryName) {
      toggleCategorySelected(categoryName)
    }
    if (!categoryCostCounts) {
      handleShowResults([categoryName || ''])
    }
  }

  const handleKeyPressCategoryCostCountItem = (event: React.KeyboardEvent, pointsRange?: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      event.stopPropagation()
      handleClickCategoryCostCountItem(pointsRange)
    }
  }

  const handleClickCategoryCostCountItem = useCallback(
    (pointsRange?: string) => {
      clearCategoryCostCountFilter()
      if (pointsRange) {
        toggleCategoryCostCountSelected(pointsRange)
      } else if (isLastResultsFiltered) {
        // Non-obvious requirement: update page immediately if 'all' is clicked
        doUpdateRewards()
      }
    },
    [clearCategoryCostCountFilter, doUpdateRewards, isLastResultsFiltered, toggleCategoryCostCountSelected]
  )

  const handleShowResults = (categoryNames?: string[]) => {
    const selectedCategory = (categoryNames ? categoryNames : selectedCategories).toString()
    if (selectedCategory !== '') {
      params.set('category', encodeURIComponent(selectedCategory))
      navigate(
        {
          search: params.toString(),
        },
        { replace: true }
      )
    } else {
      params.delete('category')
      navigate(
        {
          search: '',
        },
        { replace: true }
      )
    }
    doUpdateRewards()
    setVisible(false)
  }

  const handleReset = () => {
    clearAllFilters()
    if (isLastResultsFiltered) {
      params.delete('category')
      navigate(
        {
          search: '',
        },
        { replace: true }
      )
      doUpdateRewards()
      setVisible(false)
    }
  }

  useEffect(() => {
    doFetchCategories()
  }, [doFetchCategories])

  useSetInitialFilterState(handleClickCategoryCostCountItem)

  const pointsRangeMapping = categoryCostCounts?.all ? getPointsLabels(categoryCostCounts?.all) : []

  const getRewardsCount = () => {
    if (selectedCategories?.length && selectedCategoryCostCounts?.length) {
      return categoryCostCounts?.[selectedCategories[0]][selectedCategoryCostCounts[0]]
    } else if (selectedCategories?.length) {
      return categoryCostCounts?.[selectedCategories[0]].all
    } else if (selectedCategoryCostCounts?.length) {
      return categoryCostCounts?.all[selectedCategoryCostCounts[0]]
    } else return categoryCostCounts?.all.all
  }

  const isFilterButtonDisabled = getRewardsCount() === 0 || !isFiltersChanged
  const isResetButtonDisabled = !isFiltersSet

  return (
    <>
      <style jsx>{`
        .filter {
          display: inline-block;
          position: relative;
        }
        .filter__button {
          align-items: center;
          border: solid 1px ${color.grey02};
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          height: 38px;
          font-weight: bold;
          padding: 6px 15px;
        }
        :global(.filter__button-icon) {
          color: ${color.brandPrimary};
          margin-left: 10px;
        }
        .filter__button--selected,
        .filter__button:focus,
        .filter__button:hover {
          border-color: ${color.brandPrimary};
          color: ${color.brandPrimary};
          outline: none;
        }
        .filter__button--selected :global(.filter__button-icon),
        .filter__button:focus :global(.filter__button-icon),
        .filter__button:hover :global(.filter__button-icon) {
          fill: ${color.brandPrimary};
        }
        .filter__mask {
          cursor: default;
          height: 100%;
          left: 0;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2;
        }
        .filter__dropdown {
          background-color: ${color.white};
          border: solid 1px ${color.secondaryButtonBorder};
          border-radius: 8px 8px 0 0;
          box-shadow: 0 0 40px 0 rgba(${color.blackRGB}, 0.3);
          left: 0;
          padding: 0;
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 3;
        }
        .filter__dropdown-title {
          border-bottom: solid 1px ${color.grey03};
          font-size: 22px;
          font-style: italic;
          font-weight: 600;
          height: 60px;
          padding: 15px 25px;
          text-transform: uppercase;
        }
        .filter__dropdown-content {
          max-height: 70vh;
          overflow-y: auto;
        }
        .filter__section-title {
          font-size: 17px;
          font-weight: bold;
          line-height: normal;
          padding: 20px 30px 10px;
          text-transform: uppercase;
        }
        .filter__section-item {
          font-size: 17px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          height: 60px;
          padding: 20px 55px 20px 30px;
          position: relative;
        }
        .filter__section-item:after {
          content: '';
          border: 2px solid ${color.lightGrey};
          border-radius: 50%;
          height: 22px;
          position: absolute;
          right: 25px;
          top: 18px;
          width: 22px;
        }
        .filter__section-item:before {
          background: transparent;
          border-radius: 50%;
          content: '';
          height: 14px;
          position: absolute;
          right: 31px;
          top: 24px;
          width: 14px;
        }
        .filter__section-item:hover,
        .filter__section-item:focus {
          background-color: ${color.darkPinkBackground};
          cursor: pointer;
          outline: none;
        }
        .filter__section-item--selected {
          background-color: ${color.darkPinkBackground};
          font-weight: 600;
        }
        .filter__section-item--selected:before {
          background: ${color.brandPrimary};
        }
        .filter__section-item--selected:after {
          border-color: ${color.brandPrimary};
        }
        .filter__section-item--disabled,
        .filter__section-item--disabled:focus,
        .filter__section-item--disabled:hover {
          background-color: ${color.white};
          color: ${color.grey03};
          cursor: default;
        }
        .reset-button {
          border-color: ${color.grey04};
          border-radius: 6px;
          border-style: solid;
          border-width: 2px;
          color: ${color.brandPrimary};
          cursor: pointer;
          font-size: 18px;
          font-weight: bold;
          margin: 16px;
          padding: 7px 5px;
          text-align: center;
        }
        .reset-button:hover,
        .reset-button:focus {
          border-color: ${color.brandPrimary};
        }
        .reset-button--disabled,
        .reset-button--disabled:hover,
        .reset-button--disabled:focus {
          border-color: ${color.grey04};
          cursor: default;
          opacity: 0.2;
        }
        .show-results-button {
          background-color: ${color.brandPrimary};
          border-color: ${color.brandPrimary};
          border-radius: 6px;
          border-radius: 8px;
          border-style: solid;
          border-width: 2px;
          border: 1px solid ${color.brandPrimary};
          color: ${color.white};
          cursor: pointer;
          font-size: 18px;
          font-weight: bold;
          margin: 16px;
          padding: 7px 5px;
          text-align: center;
        }
        .show-results-button--disabled {
          cursor: default;
          opacity: 0.2;
        }
        .filter-bottom-wrapper {
          background-color: ${color.white};
          bottom: 0;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
          padding: 1px 0;
          position: sticky;
        }

        @media ${media.tabletAndHigher} {
          :global(.filter--open) {
            overflow-y: auto !important;
          }
          .filter__dropdown {
            border-radius: 8px;
            bottom: auto;
            position: absolute;
            top: 38px;
            width: 300px;
            z-index: 3;
          }
          .filter__dropdown-content {
            display: inline;
            max-height: auto;
            overflow-y: visible;
          }
          .reset-button {
            box-shadow: none;
          }
          .filter-bottom-wrapper {
            bottom: auto;
            box-shadow: none;
            padding: none;
          }
        }

        @media ${media.mobileAndLower} {
          :global(body) {
            overflow-y: ${isVisible ? 'hidden !important;' : ''};
          }
        }
      `}</style>
      <div className="filter" data-testid="filter">
        <div
          role="button"
          className={cn('filter__button', {
            'filter__button--selected': isVisible,
          })}
          onKeyPress={handleKeyPressSelect}
          onClick={() => setVisible(true)}
          tabIndex={0}
        >
          {getString('filter.title')}{' '}
          <FilterIcon
            size={18}
            className="filter__button-icon"
            hasCircle={!!selectedCategories.length || !!selectedCategoryCostCounts?.length}
          />
        </div>
        {isVisible && (
          <>
            <div className="filter__dropdown" role="listbox">
              <div className="filter__dropdown-title">{getString('filter.title')}</div>
              {categories && (
                <div className="filter__dropdown-content">
                  <div className="filter__section-title">{getString('filter.categories')}</div>
                  <div
                    role="listitem"
                    className={cn('filter__section-item', {
                      'filter__section-item--selected': !selectedCategories[0],
                    })}
                    tabIndex={0}
                    onClick={() => handleClickCategoryItem()}
                    onKeyPress={(event) => handleKeyPressCategoryItem(event)}
                  >
                    {getString('filter.all')}
                  </div>
                  {categories
                    .filter(({ categoryName }) => categoryName.toLowerCase().indexOf('points') === -1)
                    .map(({ categoryName }, index) => (
                      <div
                        role="listitem"
                        key={index}
                        className={cn('filter__section-item', {
                          'filter__section-item--selected': categoryName === selectedCategories[0],
                        })}
                        data-category-name={categoryName}
                        tabIndex={0}
                        onClick={() => handleClickCategoryItem(categoryName)}
                        onKeyPress={(event) => handleKeyPressCategoryItem(event, categoryName)}
                      >
                        {categoryName}
                      </div>
                    ))}
                  {categoryCostCounts && (
                    <>
                      <div className="filter__section-title">{getString('filter.points')}</div>
                      <div
                        role="listitem"
                        className={cn('filter__section-item', {
                          'filter__section-item--selected': !selectedCategoryCostCounts.length,
                        })}
                        tabIndex={0}
                        onClick={() => handleClickCategoryCostCountItem()}
                        onKeyPress={(event) => handleKeyPressCategoryCostCountItem(event)}
                      >
                        {getString('filter.all')}
                      </div>
                      {Object.entries(categoryCostCounts.all)
                        .filter(([pointsRange]) => pointsRange.toLowerCase().indexOf('all') === -1)
                        .map(([filteredPointsRange], index) => {
                          let costCount = selectedCategories ? getCounts(categoryCostCounts, selectedCategories[0], filteredPointsRange) : 1
                          return (
                            <div
                              key={index}
                              role="listitem"
                              className={cn('filter__section-item', {
                                'filter__section-item--selected':
                                  selectedCategoryCostCounts.length && filteredPointsRange === selectedCategoryCostCounts[0],
                                'filter__section-item--disabled': costCount === 0,
                              })}
                              tabIndex={costCount ? 0 : -1}
                              onClick={costCount ? () => handleClickCategoryCostCountItem(filteredPointsRange) : undefined}
                              onKeyPress={
                                costCount ? (event) => handleKeyPressCategoryCostCountItem(event, filteredPointsRange) : undefined
                              }
                            >
                              {pointsRangeMapping[index]}
                            </div>
                          )
                        })}
                      <div className="filter-bottom-wrapper">
                        <div
                          role="button"
                          className={cn('reset-button', {
                            'reset-button--disabled': isResetButtonDisabled,
                          })}
                          onClick={isResetButtonDisabled ? undefined : handleReset}
                          tabIndex={0}
                        >
                          {getString('filter.reset')}
                        </div>
                        <div
                          role="button"
                          className={cn('show-results-button', {
                            'show-results-button--disabled': isFilterButtonDisabled,
                          })}
                          onClick={isFilterButtonDisabled ? undefined : () => handleShowResults()}
                          tabIndex={0}
                          data-testid="show-results-button"
                        >
                          Show {(!isFilterButtonDisabled && getRewardsCount()) || ''} result
                          {getRewardsCount() !== 1 || isFilterButtonDisabled ? 's' : ''}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="filter__mask" onKeyPress={handleKeyPressSelect} onClick={() => setVisible(false)} tabIndex={0} role="button" />
          </>
        )}
      </div>
    </>
  )
}
