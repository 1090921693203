import React from 'react'
import { Dialog as MaterialUiDialog } from '@virgin-core/components'

import { getString } from '@vrw/data'
import { ViewingRegion } from '@vrw/data/src/utils/helperFunctions'
import { hideDialogApp } from '@vrw/data/src/redux/dialogApp/dialogApp.slice'
import { getIsVisible } from '@vrw/data/src/redux/dialogApp/dialogApp.selectors'

import { getViewingRegion } from '../../dataImplementation/viewingRegionWeb'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'

import { FontFamilies, color } from '../../style/variables'
import { media } from '../../style/media'
import { EXTERNAL_PATHS } from '../../router/paths'
import Cross from '../icons/Cross'
import { PUBLIC_URL } from '../../config'

export const DialogApp = () => {
  const dispatch = useAppDispatch()
  const onClose = () => dispatch(hideDialogApp())
  const isVisible = useAppSelector(getIsVisible)
  const showDownloadImage =
    getViewingRegion() === ViewingRegion.GB
      ? `${PUBLIC_URL}/img/download_the_virgin_red_app.jpg`
      : `${PUBLIC_URL}/img/download_the_virgin_red_app_us.jpg`

  return (
    <MaterialUiDialog open={isVisible} onClose={onClose} maxWidth="lg" PaperProps={{ style: { borderRadius: '8px', margin: '15px' } }}>
      <style jsx>{`
        .dialog-content {
          font-family: ${FontFamilies.barlow};
          padding: 32px 26px;
          text-align: center;
        }
        .content-wrapper {
          align-items: center;
          display: flex;
        }
        .download-prompt-image {
          display: none;
        }
        .dialog-title {
          font-size: 22px;
          font-style: italic;
          font-weight: 600;
          line-height: 1.14;
          margin-bottom: 24px;
        }
        .dialog-body {
          font-size: 16px;
          line-height: 1.6;
          margin-bottom: 24px;
        }
        .download-image-links {
          display: flex;
          justify-content: center;
        }
        .download-image-link:first-child {
          margin-right: 5px;
        }
        .download-image-link {
          height: 35px;
        }
        .app-store-download-image {
          height: 35px;
          width: 105px;
        }
        .google-play-download-image {
          height: 35px;
          width: 118px;
        }
        .close {
          cursor: pointer;
          display: flex;
          padding: 5px;
          position: absolute;
          right: 5px;
          top: 5px;
        }

        @media ${media.tabletAndHigher} {
          .dialog-content {
            max-width: 700px;
            padding: 36px;
          }
          .dialog-title {
            font-size: 36px;
          }
          .dialog-body {
            margin-bottom: 50px;
          }
          .download-prompt-image {
            display: flex;
          }
          .download-prompt-image img {
            height: 232px;
            margin-right: 30px;
            width: 232px;
          }
          .download-image-link:first-child {
            margin-right: 10px;
          }
          .download-image-link {
            height: 43px;
          }
          .app-store-download-image {
            height: 43px;
            width: 129px;
          }
          .google-play-download-image {
            height: 43px;
            width: 145px;
          }
          .close {
            right: 10px;
            top: 10px;
          }
        }
      `}</style>
      <div className="dialog-content">
        <div className="dialog-title" data-testid="dialog-title">
          {getString('rewardDetails.noRedemptions.dialog.title').toUpperCase()}
        </div>
        <div
          className="close"
          onClick={() => onClose()}
          data-testid="close-cross"
          role="button"
          aria-label="Close dialog window"
          tabIndex={0}
        >
          <Cross size={12} color={color.black} />
        </div>
        <div className="content-wrapper">
          <div className="download-prompt-image-wrapper">
            <div className="download-prompt-image">
              <img src={showDownloadImage} alt="App download prompt" />
            </div>
          </div>
          <div className="body-button-wrapper">
            <div className="dialog-body" data-testid="dialog-body">
              {getString('rewardDetails.noRedemptions.dialog.body')}
            </div>
            <div className="download-image-links">
              <a href={EXTERNAL_PATHS.APPLE_STORE_DOWNLOAD_URL} className="download-image-link" target="_blank" rel="noopener noreferrer">
                <img className="app-store-download-image" src={`${PUBLIC_URL}/img/red/mobile/app-store-badge.svg`} alt="App store icon" />
              </a>
              <a href={EXTERNAL_PATHS.GOOGLE_PLAY_DOWNLOAD_URL} className="download-image-link" target="_blank" rel="noopener noreferrer">
                <img
                  className="google-play-download-image"
                  src={`${PUBLIC_URL}/img/red/mobile/play-store-badge.svg`}
                  alt="Play store icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MaterialUiDialog>
  )
}
