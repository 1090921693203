import React, { PropsWithChildren } from 'react'
import { Document } from '@contentful/rich-text-types'

import { AuthState } from '@vrw/data/src/redux/auth/types'

import ContentfulCopy from '../../richText/contentfulCopy'
import { media } from '../../../style/media'
import { ImageProps } from '../../image/convertContentfulImage'
import { CroppedImage } from '../../image'
import { Button } from '../../Buttons/Button'
import { Heading } from './Heading'

export interface DoubleCtaTileProps {
  type: 'doubleCta'
  id: string
  title: string | null
  image: ImageProps | null
  body: Document | null
  auth?: AuthState
}

const DoubleCtaTile = ({ title, image, body, children, auth }: PropsWithChildren<DoubleCtaTileProps>) => {
  return (
    <>
      <style jsx>{`
        .cta-tile-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          border-radius: 8px;
          padding: 0 32px;
        }
        .text-content {
          flex-grow: 1;
        }
        .text-body {
          min-height: 78px;
        }
        .cta-image-container {
          margin-top: 24px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }

        .cta-image {
          width: 223px;
          height: 223px;
        }

        .text-body :global(.rich-text-copy p) {
          font-size: 15px;
        }

        .cta-buttons-wrapper {
          margin: 32px auto;
        }
        .cta-button-wrapper:last-child {
          margin-right: 0px;
        }

        .cta-button-wrapper {
          margin-right: 11px;
          margin-bottom: 11px;
          min-width: 126px;
          display: inline-block;
        }

        @media ${media.tabletAndHigher} {
          .cta-image-container {
            margin-top: 21px;
          }
          .cta-image {
            width: 280px;
            height: 280px;
          }
          .cta-tile-wrapper {
            padding-left: 24px;
            padding-right: 0px;
          }
          .text-body {
            min-height: 130px;
          }

          .text-body :global(.rich-text-copy p) {
            font-size: 16px;
          }

          .cta-tile-wrapper {
            flex-direction: row;
          }

          .cta-buttons-wrapper {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
          }
          .cta-button-wrapper {
            min-width: 141px;
          }
        }

        @media ${media.desktopAndHigher} {
          .cta-image-container {
            margin-top: 77px;
          }
          .cta-image {
            width: 450px;
            height: 450px;
          }

          .text-body :global(.rich-text-copy p) {
            font-size: 20px;
          }
          .cta-tile-wrapper {
            padding-left: 41px;
          }
        }
      `}</style>
      <div className="cta-tile-wrapper">
        <div className="text-content">
          {title && <Heading>{title}</Heading>}
          {body && (
            <div className="text-body">
              <ContentfulCopy content={body} />
            </div>
          )}
          {children}
          {auth?.isAuthenticated ? null : (
            <div className="cta-buttons-wrapper">
              <div className="cta-button-wrapper">
                <Button isPrimary={true} onClick={() => {}} text="Join Virgin Red" />
              </div>
              <div className="cta-button-wrapper">
                <Button isPrimary={false} onClick={() => {}} text="Log in" />
              </div>
            </div>
          )}
        </div>
        {image && (
          <div className="cta-image-container">
            <div className="cta-image">
              <CroppedImage size="contain" imgSrc={image.url} altText={image.alt} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DoubleCtaTile
