import { connect } from 'react-redux'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { DispatchFunction } from '../../redux/types'
import { LoginBox } from './LoginBox'

import { doLoginWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { getPath } from '../../dataImplementation/viewingRegionWeb'

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogin: () => doLoginWeb(getPath() || undefined),
    },
    dispatch
  )

export const LoginBoxContainer = connect(undefined, mapDispatchToProps)(LoginBox)
