import storage from 'redux-persist/lib/storage'

import { RedDataConfig } from '@vrw/data/src/types'
import { NavTargets } from '@vrw/data/src/navTargets'

import {
  API_URL,
  BRAZE_JWT_URL,
  FEATURE_FLAGS,
  TRAINS_DYNAMIC_LINK_ANDROID_PACKAGE,
  TRAINS_DYNAMIC_LINK_APP_STORE_ID,
  TRAINS_DYNAMIC_LINK_BASE_URL,
  TRAINS_DYNAMIC_LINK_IOS_PACKAGE,
  CONTENTFUL_ENV,
  CONTENTFUL_SPACE,
  CONTENTFUL_URL,
  CONTENTFUL_PREVIEW_URL,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  MENTION_ME_API_URL,
  B2C_API_URL,
  RED_API_URL,
} from '../config'
import { PATHS } from '../router/paths'
import { saveSecret, retrieveSecret, deleteSecret } from './storage'
import { authenticate, refresh, logout, passwordless, loginWithEmail, handleVaaAuth, getVaaToken, stepUp, stepUpTokenParsing } from './auth'
import { getLocale, localeToRegion } from './viewingRegionWeb'
import { navigate, navigateBack } from './navigation'

const navTargets: NavTargets = {
  Home: PATHS.HOME,

  Login: 'Not needed yet.',
  Public: PATHS.HOME,
  Intro: 'Not needed yet.',
  Offline: 'Not needed on Web.',
  UpdateRequired: 'Not needed on Web.',

  PersonalDetails: PATHS.ONBOARDING_PERSONAL_DETAILS,
  TermsAndConditions: PATHS.ONBOARDING_TERMS_AND_CONDITIONS,
  LinkVaa: PATHS.ONBOARDING_WELCOME,
  AutoLinkPartner: PATHS.ONBOARDING_AUTO_LINK_ACCOUNTS,
  PartnerLinkVAA: PATHS.ONBOARDING_PARTNER_LINK_VAA,
  NotificationConsent: 'Not needed on Web.',
  CommunicationPreferences: PATHS.ONBOARDING_COMMUNICATION_PREFERENCES,
  Welcome: PATHS.ONBOARDING_WELCOME,

  MyOrders: 'Not needed yet.',
  MyVouchers: 'Not needed yet.',
  OrderCheckout: PATHS.ORDER_CHECKOUT,
  OrderConfirmation: PATHS.ORDER,
  DigitalOrderConfirmation: PATHS.ORDER,
  QrCodeVoucher: PATHS.VOUCHER,
  UrlCodeVoucher: PATHS.VOUCHER,
  DonationCheckout: 'Not needed yet.',
  DonationConfirmation: PATHS.ORDER,
  ArticleDetails: 'Not needed yet.',

  Account: PATHS.MY_ACCOUNT,
  AccountUpdateEmail: PATHS.MY_ACCOUNT_UPDATE_EMAIL,
  AccountVerifyEmail: PATHS.MY_ACCOUNT_VERIFY_EMAIL,
  EmailUpdated: PATHS.EMAIL_UPDATED,
  EmailUpdatedSuccess: PATHS.EMAIL_UPDATED_SUCCESS,
  MyAccountPersonalDetails: PATHS.MY_ACCOUNT_PERSONAL_DETAILS,
  MyAccountLinkAccount: PATHS.MY_ACCOUNT_LINK_ACCOUNTS,
  MyAccountPromoCodes: PATHS.MY_ACCOUNT_PROMO_CODES,
  MyAccountReferAFriend: PATHS.MY_ACCOUNT_REFER_DASHBOARD,
  MyAccountSettings: PATHS.MY_ACCOUNT_SETTINGS,

  PromotionApplied: 'not needed for web currently',
  RewardDetails: 'not needed for web currently',
  RewardDetailsEarn: 'not needed for web currently',
  PrivacySettingsPrompt: 'not needed for web currently',
  OrderStack: 'not needed for web currently',
}

const notYetImplemented = (name: string) => () => {
  throw new Error(`Data Library feature "${name}" not yet implemented for web, see web/src/dataConfig.ts`)
}

export const redDataConfig: RedDataConfig = {
  apiBaseUrl: API_URL || '',
  auth: {
    authenticate,
    refresh,
    logout,
    stepUp,
    stepUpTokenParsing,
    passwordless,
    loginWithEmail,
  },
  b2cApiBaseUrl: B2C_API_URL || '',
  brazeJwtUrl: BRAZE_JWT_URL || '',
  contentful: {
    env: CONTENTFUL_ENV,
    space: CONTENTFUL_SPACE,
    url: CONTENTFUL_URL,
    previewUrl: CONTENTFUL_PREVIEW_URL,
    accessToken: CONTENTFUL_ACCESS_TOKEN,
    previewAccessToken: CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  },
  mentionMeApiUrl: MENTION_ME_API_URL || '',
  navigate,
  navigateBack,
  navTargets,
  redApiBaseUrl: RED_API_URL || '',
  secrets: {
    save: saveSecret,
    retrieve: retrieveSecret,
    delete: deleteSecret,
  },
  vaa: {
    handleVaaAuth,
    getVaaToken,
  },
  openExternalLink: (url: string) => {
    window.location.href = url
  },
  navigatePush: notYetImplemented('navigatePush'),
  storage: {
    set: (key, value) => storage.setItem(key, value),
    get: (key) => storage.getItem(key),
    delete: (key) => storage.removeItem(key),
  },
  platform: 'web',
  featureFlags: {
    mentionMe: FEATURE_FLAGS.mentionME,
  },
  getViewingRegion: () => localeToRegion(getLocale()),
  vttDynamicLinkParams: {
    baseUrl: TRAINS_DYNAMIC_LINK_BASE_URL,
    androidPackage: TRAINS_DYNAMIC_LINK_ANDROID_PACKAGE,
    appStoreId: TRAINS_DYNAMIC_LINK_APP_STORE_ID,
    iosPackage: TRAINS_DYNAMIC_LINK_IOS_PACKAGE,
  },
}
