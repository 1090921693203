import { connect } from 'react-redux'
import { DispatchFunction } from '../../../../../../redux/types'
import { bindTypedActionCreators } from '../../../../../../helpers/bindTypedActionCreators'
import { LoginButton } from './LoginButton'

import { doLoginWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { getPath } from '../../../../../../dataImplementation/viewingRegionWeb'

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogin: () => doLoginWeb(getPath() || undefined),
    },
    dispatch
  )

const LoginButtonContainer = connect(null, mapDispatchToProps)(LoginButton)

export { LoginButtonContainer }
