import { connect } from 'react-redux'

import { doLinkAccountsWeb, doLinkAccountsWebResponseHandler, doFetchAccounts } from '@vrw/data/src/redux/accounts/dispatchers'
import { getAccountsEntities, getIsUpdating } from '@vrw/data/src/redux/accounts/selectors'
import { LinkAccounts } from './LinkAccounts'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

const mapStateToProps = (state: RootState) => ({
  accountsEntities: getAccountsEntities(state),
  isUpdating: getIsUpdating(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLinkAccountsWeb,
      doLinkAccountsWebResponseHandler,
      doFetchAccounts,
    },
    dispatch
  )

const LinkAccountsContainer = connect(mapStateToProps, mapDispatchToProps)(LinkAccounts)

export { LinkAccountsContainer }
