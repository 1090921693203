import React, { FC } from 'react'
import { useMediaPredicate } from 'react-media-hook'

import { getString } from '@vrw/data/src/utils'
import { Order, OrderStatus } from '@vrw/data/src/redux/orders/types'
import { media } from '../../../../../style/media'
import { color } from '../../../../../style/variables'
import { PATHS } from '../../../../../router/paths'

import { Picture } from '../../../../../components/Picture'
import Subheading from '../../../../../components/typography/Subheading'
import { CustomLink } from '../../../../../components/links'
import Body from '../../../../../components/typography/Body'
import IconMapper, { Icon } from '../../../../../components/icons/IconMapper'
import { Content } from '../../../../../components/layout'

const OrderTile: FC<Order> = ({ content, orderId, status }) => {
  const isMobile = useMediaPredicate(media.mobileAndLower)
  const isTablet = useMediaPredicate(media.tabletAndLower)

  if (!content) return null
  const { title } = content
  const imageUrl = 'imageUrl' in content ? content.imageUrl : ''
  const brandDetails = 'brandDetails' in content ? content.brandDetails : undefined
  const brandDetailsLogo = brandDetails?.brandLogo || ''

  let imageHeight = 190
  let imageWidth = 350
  let logoDimensions = 72

  if (isMobile) {
    imageHeight = 120
    imageWidth = 120
    logoDimensions = 35
  } else if (isTablet) {
    imageHeight = 130
    imageWidth = 230
    logoDimensions = 52
  }

  const responsiveImages = {
    mobile: {
      imgSrc: `${imageUrl}?w=${imageWidth}&h=${imageHeight}&q=60&fit=fill&fm=webp`,
      hiResImgSrc: `${imageUrl}?w=${imageWidth * 2}&h=${imageHeight * 2}&q=60&fit=fill&fm=webp`,
    },
    tablet: {
      imgSrc: `${imageUrl}?w=${imageWidth}&h=${imageHeight}&q=65&fit=fill&fm=webp`,
      hiResImgSrc: `${imageUrl}?w=${imageWidth * 2}&h=${imageHeight * 2}&q=65&fit=fill&fm=webp`,
    },
    desktop: {
      imgSrc: `${imageUrl}?w=${imageWidth}&h=${imageHeight}&q=85&fit=fill&fm=webp`,
      hiResImgSrc: `${imageUrl}?w=${imageWidth * 2}&h=${imageHeight * 2}&q=85&fit=fill&fm=webp`,
    },
  }
  const responsiveLogoImages = {
    mobile: {
      imgSrc: `${brandDetailsLogo}?w=${logoDimensions}&h=${logoDimensions}&fm=webp`,
      hiResImgSrc: `${brandDetailsLogo}?w=${logoDimensions * 2}&h=${logoDimensions * 2}&fm=webp`,
    },
    tablet: {
      imgSrc: `${brandDetailsLogo}?w=${logoDimensions}&h=${logoDimensions}&fm=webp`,
      hiResImgSrc: `${brandDetailsLogo}?w=${logoDimensions * 2}&h=${logoDimensions * 2}&fm=webp`,
    },
    desktop: {
      imgSrc: `${brandDetailsLogo}?w=${logoDimensions}&h=${logoDimensions}&fm=webp`,
      hiResImgSrc: `${brandDetailsLogo}?w=${logoDimensions * 2}&h=${logoDimensions * 2}&fm=webp`,
    },
  }

  return (
    <>
      <style jsx>{`
        .order-tile {
          background-color: ${color.white};
          border-radius: 8px;
          border: solid 1px ${color.secondaryButtonBorder};
          display: flex;
          margin: 0 auto 16px;
          max-width: 1100px;
          position: relative;
          text-decoration: none;
        }
        .order-tile:hover {
          border-color: ${color.authorGrey};
        }
        .main-image-wrapper {
          height: 120px;
          width: 120px;
        }
        .main-image {
          border-radius: 8px 0 0 8px;
          height: 120px;
          overflow: hidden;
          width: 120px;
        }
        .logo-image {
          background-color: ${color.white};
          box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
          height: 35px;
          left: 8px;
          position: absolute;
          top: 78px;
          width: 35px;
          z-index: 1;
        }
        .content {
          display: flex;
          flex-direction: column;
          margin: 14px 14px 8px;
          width: calc(100% - 100px);
        }
        .order-link-wrapper {
          align-items: flex-end;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        }
        .order-link {
          display: flex;
          text-decoration: none;
        }
        .icon-wrapper {
          margin-left: 5px;
        }
        .cancelled-order {
          color: ${color.brandPrimary};
          font-size: 14px;
        }

        @media ${media.tabletAndHigher} {
          .main-image-wrapper {
            height: 100%;
            width: 230px;
          }
          .main-image {
            height: 130px;
            width: 230px;
          }
          .logo-image {
            height: 52px;
            left: 12px;
            top: 66px;
            width: 52px;
          }
          .content {
            margin: 14px 28px 16px;
            width: calc(100% - 230px);
          }
          .cancelled-order {
            font-size: 16px;
            margin-top: 8px;
          }
        }

        @media ${media.desktopAndHigher} {
          .main-image-wrapper {
            height: 100%;
            width: 350px;
          }
          .main-image {
            height: 190px;
            width: 350px;
          }
          .logo-image {
            height: 72px;
            left: 12px;
            top: 106px;
            width: 72px;
          }
          .content {
            margin: 40px 40px 24px;
            width: calc(100% - 350px);
          }
        }
      `}</style>
      <Content>
        <CustomLink to={`/virgin-red${PATHS.MY_REWARDS_ORDERS}/${orderId}`}>
          <div className="order-tile">
            <div className="main-image-wrapper">
              <div className="main-image">
                <Picture
                  altText={`${title} reward image`}
                  width={imageWidth}
                  height={imageHeight}
                  fallbackImg={`${imageUrl}?w=${imageWidth}&h=${imageHeight}&q=85&fit=fill`}
                  responsiveImages={responsiveImages}
                  loading={'lazy'}
                  isWebP={true}
                />
              </div>
              {brandDetails?.brandLogo && (
                <div className="logo-image">
                  <Picture
                    altText={`${brandDetails.brandName} logo`}
                    width={logoDimensions}
                    height={logoDimensions}
                    fallbackImg={`${brandDetails?.brandLogo}?w=${logoDimensions}&h=${logoDimensions}&q=60&fit=fill`}
                    responsiveImages={responsiveLogoImages}
                    loading={'lazy'}
                    isWebP={true}
                  />
                </div>
              )}
            </div>
            <div className="content">
              <Subheading textAlign="left" textSize={{ mobile: 16, tablet: 24 }} lineHeight={{ mobile: 1.19, desktop: 1.14 }} weight={600}>
                {title}
              </Subheading>
              {status === OrderStatus.CANCELLED && (
                <div className="cancelled-order" data-testid={`cancelled-order${-orderId}`}>
                  {getString('myRewards.viewOrder.cancelled')}
                </div>
              )}
              <div className="order-link-wrapper">
                <div className="order-link">
                  <Body
                    color={color.brandPrimary}
                    marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                    marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
                  >
                    {getString('myRewards.viewOrder')}
                  </Body>
                  <div className="icon-wrapper">
                    <IconMapper icon={Icon.ArrowRight} color={color.brandPrimary} size={8} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomLink>
      </Content>
    </>
  )
}

export default OrderTile
