import { logger, httpErrors, ApiErrorResponse, httpErrorMessages } from '../utils'
import { getString, getStringOrNull } from '../utils/getString'
import { setToastMessage } from './toast/actions'
import { doLogoutWeb } from './auth/dispatchers'
import { UserFacingError } from './types'

const errorHandler = (dispatch, error, errorAction, ...rest) => {
  logger.warn(`errorHandler.ts: error:`, error, errorAction, ...rest)
  const unauthorised = error?.message?.includes(httpErrors.UNAUTHORIZED)
  const userNotFound = error?.message?.includes(httpErrorMessages.CLIENT_ERROR) && error?.message?.includes(httpErrors.NOT_FOUND)

  if (userNotFound || unauthorised) {
    dispatch(doLogoutWeb())
  } else {
    // UserFacingError can be used to provide a user-facing version of an error
    const friendlyMessageFromError = (error as UserFacingError)?.friendlyString

    // Error codes from the API can be mapped to friendly content strings
    const friendlyMessageFromApiCode = getStringOrNull(`api.errorCode.${(error as ApiErrorResponse)?.code}`)

    // If neither exist, use the generic fallback error
    // Don't show toast if error is due to user aborting an auth0 step
    if (!error?.error?.match(/user_cancelled/)) {
      dispatch(setToastMessage(friendlyMessageFromError || friendlyMessageFromApiCode || getString('api.unknownError')))
    }
    dispatch(errorAction(error?.message || error, ...rest))
  }
}

export { errorHandler }
