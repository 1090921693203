import React, { ChangeEvent, FocusEvent, FC, useState, useRef, FormEvent, useEffect } from 'react'
import { Dialog as MaterialUiDialog, TextField } from '@virgin-core/components'

import { getString } from '@vrw/data'
import { getStringOrNull } from '@vrw/data/src/utils/getString'
import { DeliveryAddress, DeliveryAddressErrorState } from '@vrw/data/src/redux/orders/types'
import { parseJoiErrors } from '@vrw/data/src/utils/validations'
import { Reward } from '@vrw/data/src/redux/rewards/types'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import Cross from '../icons/Cross'
import { Spacer } from '../Spacer'
import { Button } from '../Buttons/Button'

type FormValues = {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  building?: string
  addressLine1?: string
  addressLine2?: string
  town?: string
  county?: string
  postcode?: string
}

export interface DialogAddressState {
  reward: Reward
  initialAddress: DeliveryAddress
  doUpdateOrderDeliveryAddress: Function
  validateOrderDeliveryAddress: Function
}

export interface DialogAddressDispatchProps {
  visible: boolean
  onClose: () => void
}

const DialogAddress: FC<DialogAddressState & DialogAddressDispatchProps> = ({
  visible,
  onClose,
  initialAddress,
  doUpdateOrderDeliveryAddress,
  validateOrderDeliveryAddress,
  reward,
}) => {
  const formRef = useRef<any>(null)
  const getFormValues = (): FormValues => ({
    firstName: formRef.current?.firstName?.value,
    lastName: formRef.current?.lastName?.value,
    phoneNumber: formRef.current?.phoneNumber?.value,
    email: formRef.current?.email?.value,
    building: formRef.current?.building?.value,
    addressLine1: formRef.current?.addressLine1?.value,
    addressLine2: formRef.current?.addressLine2?.value,
    town: formRef.current?.town?.value,
    county: formRef.current?.county?.value,
    postcode: formRef.current?.postcode?.value,
  })
  const screenId = 'orders.address'
  const [hasEmptyFields, setHasEmptyFields] = useState<boolean>(true)
  const [errors, setErrors] = useState<DeliveryAddressErrorState>({})

  const checkEmptyFields = (key: string) => {
    const form = formRef.current
    if (form && form[key].value.length === 0) {
      setHasEmptyFields(true)
    } else if (
      form &&
      form['firstName']?.value &&
      form['lastName']?.value &&
      form['phoneNumber']?.value &&
      form['email']?.value &&
      form['building']?.value &&
      form['addressLine1']?.value &&
      form['town']?.value &&
      form['county']?.value &&
      form['postcode']?.value
    ) {
      setHasEmptyFields(false)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = event.target
    checkEmptyFields(id)
  }

  const handleSubmit = () => {
    const formValidation = validateOrderDeliveryAddress(getFormValues(), reward.redemptionType)
    if (formValidation.error) {
      const newErrors = parseJoiErrors(formValidation.error.details)

      setErrors((prevState: DeliveryAddressErrorState) => ({
        ...prevState,
        ...newErrors,
      }))
    } else {
      doUpdateOrderDeliveryAddress(getFormValues())
      handleClose()
    }
  }

  const handleClose = () => {
    setErrors({})
    onClose()
  }

  useEffect(() => {
    const initialAddressHasEmptyFields = Object.values(initialAddress).some((addressField: string) => addressField.length === 0)
    if (!initialAddressHasEmptyFields) setHasEmptyFields(false)
  }, [initialAddress])

  return (
    <MaterialUiDialog open={visible} onClose={handleClose} maxWidth={false}>
      <style jsx>{`
        .dialog-content {
          padding: 32px 26px 16px;
          text-align: center;
        }
        .dialog-title {
          font-size: 22px;
          font-weight: 600;
          line-height: 1.14;
          margin-bottom: 24px;
        }
        .dialog-close {
          cursor: pointer;
          display: flex;
          padding: 5px;
          position: absolute;
          right: 5px;
          top: 5px;
        }
        .buttons-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .buttons-wrapper :global(button:first-child) {
          margin-bottom: 16px;
        }

        @media ${media.tabletAndHigher} {
          .dialog-content {
            border-radius: 8px;
            padding: 36px 36px 16px;
            max-width: 648px;
          }
          .dialog-title {
            font-size: 24px;
          }
          .dialog-close {
            right: 10px;
            top: 10px;
          }
          .buttons-wrapper {
            flex-direction: row;
          }
          .buttons-wrapper :global(button:first-child) {
            margin-right: 12px;
          }
          .buttons-wrapper :global(button:last-child) {
            min-width: 243px;
          }
        }
      `}</style>
      <div className="dialog-content">
        <div
          className="dialog-close"
          onClick={handleClose}
          data-testid="close-cross"
          role="button"
          aria-label="Close dialog window"
          tabIndex={0}
        >
          <Cross size={12} color={color.black} role="img" />
        </div>
        <div className="dialog-title">{getString(`${screenId}.enterADeliveryAddress`)}</div>
        <Spacer size="small" />
        <form
          ref={formRef}
          onSubmit={(event: FormEvent) => {
            event.preventDefault()
          }}
        >
          <TextField
            defaultValue={initialAddress.firstName}
            error={!!errors.firstName}
            inputHeight="72px"
            data-testid="address-first-name"
            fullWidth
            helperText={getStringOrNull(`${screenId}.firstName.error.${errors.firstName}`)}
            id="firstName"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.firstName.label`)}
            label={getString(`${screenId}.firstName.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.lastName}
            error={!!errors.lastName}
            inputHeight="72px"
            data-testid="address-last-name"
            fullWidth
            helperText={getStringOrNull(`${screenId}.lastName.error.${errors.lastName}`)}
            id="lastName"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.lastName.label`)}
            label={getString(`${screenId}.lastName.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.phoneNumber}
            error={!!errors.phoneNumber}
            inputHeight="72px"
            data-testid="address-telephone-number"
            fullWidth
            helperText={getStringOrNull(`${screenId}.phoneNumber.error.${errors.phoneNumber}`)}
            id="phoneNumber"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.telephoneNumber.label`)}
            label={getString(`${screenId}.telephoneNumber.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.email}
            error={!!errors.email}
            inputHeight="72px"
            data-testid="address-email"
            fullWidth
            helperText={getStringOrNull(`${screenId}.email.error.${errors.email}`)}
            id="email"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.email.label`)}
            label={getString(`${screenId}.email.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.building}
            error={!!errors.building}
            inputHeight="72px"
            data-testid="address-building-number"
            fullWidth
            helperText={getStringOrNull(`${screenId}.building.error.${errors.building}`)}
            id="building"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.building.label`)}
            label={getString(`${screenId}.building.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.addressLine1}
            inputHeight="72px"
            data-testid="address-first-line"
            error={!!errors.addressLine1}
            fullWidth
            helperText={getStringOrNull(`${screenId}.addressLine1.error.${errors.addressLine1}`)}
            id="addressLine1"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.firstLine.label`)}
            label={getString(`${screenId}.firstLine.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.addressLine2}
            inputHeight="72px"
            data-testid="address-second-line"
            error={!!errors.addressLine2}
            fullWidth
            id="addressLine2"
            onChange={handleChange}
            helperText="Optional"
            placeholder={getStringOrNull(`${screenId}.secondLine.label`)}
            label={getString(`${screenId}.secondLine.label`)}
          />
          <TextField
            defaultValue={initialAddress.town}
            inputHeight="72px"
            data-testid="address-town"
            error={!!errors.town}
            fullWidth
            helperText={getStringOrNull(`${screenId}.town.error.${errors.town}`)}
            id="town"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.town.label`)}
            label={getString(`${screenId}.town.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.county}
            inputHeight="72px"
            data-testid="address-county"
            error={!!errors.county}
            fullWidth
            helperText={getStringOrNull(`${screenId}.county.error.${errors.county}`)}
            id="county"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.county.label`)}
            label={getString(`${screenId}.county.label`)}
            required
          />
          <TextField
            defaultValue={initialAddress.postcode}
            inputHeight="72px"
            data-testid="address-postcode"
            error={!!errors.postcode}
            fullWidth
            helperText={getStringOrNull(`${screenId}.postcode.error.${errors.postcode}`)}
            id="postcode"
            onChange={handleChange}
            placeholder={getStringOrNull(`${screenId}.postcode.label`)}
            label={getString(`${screenId}.postcode.label`)}
            required
          />
          <Spacer size="small" />
          <div className="buttons-wrapper">
            <Button
              isFullWidth={false}
              onClick={handleClose}
              text={getString('actions.cancel')}
              dataAttributes={{ testid: 'address-cancel-button' }}
            />
            <Button
              isFullWidth={false}
              isPrimary
              buttonType="submit"
              disabled={hasEmptyFields}
              onClick={handleSubmit}
              text={getString(`${screenId}.deliverToThisAddress.label`)}
              dataAttributes={{ testid: 'address-confirm-button' }}
            />
          </div>
        </form>
      </div>
    </MaterialUiDialog>
  )
}

export { DialogAddress }
