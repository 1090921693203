import React, { FC, useState, ChangeEvent, useEffect, useCallback } from 'react'
import { MenuItem, TextField } from '@virgin-core/components'
import { getString } from '@vrw/data'
import { ViewingRegion, getRegionDisplayName } from '@vrw/data/src/utils'
import { useRegionSelector } from '../../context/RegionSelectorProvider'
import { getLocale, localeToRegion, setRegionBannerSeen } from '../../dataImplementation/viewingRegionWeb'
import { Button } from '../Buttons/Button'
import Copy from '../richText/copy'
import { H3Header } from '../typography'
import { media } from '../../style/media'
import { color, ZIndex } from '../../style/variables'
import { PATHS } from '../../router/paths'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'

export const RegionSelectorModal: FC = () => {
  const currentRegion = localeToRegion(getLocale())
  const redNavigate = useRedNavigate()
  const [regionSelected, setRegionSelected] = useState(currentRegion)
  const { hideRegionSelectorModal, hideRegionSelectorBanner } = useRegionSelector()

  const onRegionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const selectedRegion = event?.target?.value as ViewingRegion
    setRegionSelected(selectedRegion)
  }

  const applyAndClose = (): void => {
    hideRegionSelectorModal()
    hideRegionSelectorBanner()
    setRegionBannerSeen()
    if (regionSelected !== currentRegion) {
      redNavigate(PATHS.HOME, {}, regionSelected)
    }
  }

  const closeModal = useCallback((): void => {
    hideRegionSelectorModal()
    setRegionSelected(currentRegion)
    const selectCountryButton = document.getElementById('banner-button-select-country')
    selectCountryButton?.focus()
  }, [currentRegion, hideRegionSelectorModal])

  useEffect(() => {
    const inputArea = document.getElementById('region')
    const doneRegion = document.getElementById('done-region')
    const closeRegion = document.getElementById('close-region')
    let shiftDown = false

    inputArea?.focus()

    const orderFlow = (event: KeyboardEvent, previousComponent?: HTMLElement | null, nextComponent?: HTMLElement | null) => {
      if (event.key !== 'Tab') return
      if (shiftDown && previousComponent) {
        event.preventDefault()
        previousComponent.focus()
        return
      }
      if (nextComponent) {
        event.preventDefault()
        nextComponent.focus()
      }
    }

    const closeRegionEventListener = function (event: KeyboardEvent) {
      orderFlow(event, doneRegion, inputArea)
    }

    const inputAreaEventListener = function (event: KeyboardEvent) {
      orderFlow(event, closeRegion)
    }

    const documentKeyDownEventListener = function (event: KeyboardEvent) {
      if (event.key === 'Escape') {
        event.preventDefault()
        closeModal()
      }
      if (event.key === 'Shift') {
        shiftDown = true
      }
    }

    const documentKeyUpEventListener = function (event: KeyboardEvent) {
      if (event.key === 'Shift') {
        shiftDown = false
      }
    }

    closeRegion?.addEventListener('keydown', closeRegionEventListener)
    inputArea?.addEventListener('keydown', inputAreaEventListener)
    document?.addEventListener('keydown', documentKeyDownEventListener)
    document.addEventListener('keyup', documentKeyUpEventListener)

    return () => {
      closeRegion?.removeEventListener('keydown', closeRegionEventListener)
      inputArea?.removeEventListener('keydown', inputAreaEventListener)
      document?.removeEventListener('keydown', documentKeyDownEventListener)
      document.removeEventListener('keyup', documentKeyUpEventListener)
    }
  }, [closeModal])

  return (
    <>
      <style jsx>{`
        .region-selector-modal {
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          background-color: rgba(0, 0, 0, 0.4);
          width: 100%;
          height: 100%;
          padding: 15px;
          overflow-y: auto;
          z-index: ${ZIndex.Modal};
        }
        .region-selector-modal-box {
          position: relative;
          flex-shrink: 0;
          background-color: ${color.white};
          border-radius: 8px;
          width: 100%;
          max-width: 534px;
          padding: 24px;
          margin: auto;
        }
        .region-selector-modal-text {
          margin-bottom: 16px;
        }
        .region-selector-modal-button-wrapper {
          display: flex;
        }
        .region-selector-modal-button-wrapper > :global(button) {
          min-width: 0;
          max-width: 128px;
        }
        .region-selector-modal-button-wrapper > :global(button):not(:last-of-type) {
          margin-right: 8px;
        }
        @media ${media.tabletAndHigher} {
          .region-selector-modal-box {
            padding: 32px 40px 40px;
          }
          .region-selector-modal-text {
            margin-bottom: 24px;
          }
          .region-selector-modal-button-wrapper > :global(button) {
            max-width: 148px;
          }
          .region-selector-modal-button-wrapper > :global(button):not(:last-of-type) {
            margin-right: 16px;
          }
        }
        @media ${media.desktopAndHigher} {
          .region-selector-modal-box {
            max-width: 648px;
          }
          .region-selector-modal-text {
            margin-bottom: 34px;
          }
          .region-selector-modal-button-wrapper {
            margin-top: 32px;
          }
        }
      `}</style>
      <div className="region-selector-modal" role="dialog">
        <div className="region-selector-modal-box">
          <H3Header
            weight={600}
            fontSize={{ mobile: 24, tablet: 24, desktop: 28 }}
            marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
            marginBottom={{ mobile: 8, tablet: 8, desktop: 16 }}
          >
            {getString('region.modal.title')}
          </H3Header>
          <div className="region-selector-content">
            <div className="region-selector-modal-text">
              <Copy>{getString('region.modal.body')}</Copy>
            </div>
            <TextField
              inputHeight="72px"
              iconColor={color.textStandard}
              select
              fullWidth
              id="region"
              name="region"
              onChange={onRegionChange}
              role="combobox"
              label="select country"
              InputLabelProps={{ style: { color: '#FFFFFF' } }}
              value={regionSelected}
            >
              {Object.keys(ViewingRegion).map((enumKey) => (
                <MenuItem key={`menu-item-${enumKey}`} id={`menu-item-${enumKey}`} value={enumKey}>
                  {getRegionDisplayName(enumKey as ViewingRegion)}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="region-selector-modal-button-wrapper">
            <Button text={getString('region.modal.button.done')} onClick={applyAndClose} isPrimary id="done-region" />
            <Button
              text={getString('region.modal.button.cancel')}
              onClick={closeModal}
              id="close-region"
              ariaLabel="close country selection modal"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const RegionSelectorModalWrapper: FC = () => {
  const { isRegionSelectorModalVisible } = useRegionSelector()
  return isRegionSelectorModalVisible ? <RegionSelectorModal /> : null
}

export default RegionSelectorModalWrapper
