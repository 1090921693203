import React from 'react'

import { Content } from '../../components'
import { FontFamilies, color } from '../../style/variables'
import H1Header from '../../components/oldTypography/H1Header'
import TextWithSymbol from '../../components/oldTypography/textWithSymbol'
import { getString } from '@vrw/data'
import { media } from '../../style/media'
import { Page } from '../../components'
import { Button } from '../../components/Buttons/Button'
import { EmailUpdatedSuccessProps } from './types'
import LeadText from '../../components/oldTypography/leadText'

const EmailUpdatedSuccess: React.FC<EmailUpdatedSuccessProps> = ({ doLogin }) => {
  return (
    <>
      <style jsx>{`
        @media ${media.desktopAndHigher} {
          .content-wrapper {
            margin-bottom: 80px;
          }
          .button-container {
            display: flex;
            justify-contents: center;
          }
        }
      `}</style>
      <Page title="Email updated | Virgin Red" testId="page-account-email-updated">
        <H1Header textAlign="center" fontFamily={FontFamilies.barlow} color={color.textHeader}>
          <TextWithSymbol text={`${getString('emailUpdated.title')}.`} />
        </H1Header>
        <div className="content-wrapper">
          <Content>
            <LeadText>{getString('emailUpdated.description')}</LeadText>
            <div className="button-container">
              <LeadText>
                <Button isFullWidth={false} isPrimary onClick={() => doLogin()} text={getString('emailUpdated.submit')} />
              </LeadText>
            </div>
          </Content>
        </div>
      </Page>
    </>
  )
}

export { EmailUpdatedSuccess }
