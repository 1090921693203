import React from 'react'
import { FC } from 'react'

import { DeliveryAddress } from '@vrw/data/src/redux/orders/types'
import { getString } from '@vrw/data'

import { color } from '../../style/variables'
import { IconButton, IconButtonType } from '../Buttons'

interface AddressContainerProps {
  address: DeliveryAddress
}

interface AddressChooserProps extends AddressContainerProps {
  showDialog: () => void
  loading: boolean
}

const AddressContainer: FC<AddressContainerProps> = ({ address }) => {
  const { firstName, lastName, building, addressLine1, addressLine2, town, county, postcode } = address
  return (
    <div className="address-container">
      <p>
        {firstName} {lastName}
        <br />
        {building} {addressLine1}
        <br />
        {addressLine2 && (
          <>
            {addressLine2}
            <br />
          </>
        )}
        {town}
        <br />
        {county && (
          <>
            {county}
            <br />
          </>
        )}
        {postcode}
      </p>
    </div>
  )
}

const AddressChooser: FC<AddressChooserProps> = ({ address, showDialog, loading }) => {
  const hasExistingAddress = address.firstName.length > 0
  const text = getString(hasExistingAddress ? 'orders.address.changeAddress' : 'orders.address.addNewAddress')
  return (
    <>
      <style jsx>{`
        .address-chooser {
          display: block;
        }
        .address-chooser :global(button) {
          border-color: ${color.white};
        }
      `}</style>
      <div className="address-chooser">
        {hasExistingAddress ? <AddressContainer address={address} /> : null}
        <IconButton
          dataAttributes={{
            testid: 'address-chooser-button',
          }}
          display="inline-block"
          isFullWidth={false}
          loading={loading}
          onClick={showDialog}
          type={!hasExistingAddress ? IconButtonType.PLUS : undefined}
          text={text}
        />
      </div>
    </>
  )
}

export { AddressChooser, AddressContainer }
