import React, { useState } from 'react'
import { Page } from '../../../../../src/components'
import { getString } from '@vrw/data'
import { MyAccountBackground } from '../../MyAccountBackground'
import { YStack, Text, XStack, RadioGroup, Label, Input, Spinner } from '@red-ui/components/'
import { doShowDialog } from '@vrw/data/src/redux/dialog/dispatchers'
import { DialogType } from '@vrw/data/src/redux/dialog/types'
import { useAppDispatch } from '../../../../../src/redux/hooks/hooks'
import { PATHS } from '../../../../../src/router/paths'
import { Link } from '../../../../../src/components/Link'
import { localizePath } from '../../../../../src/dataImplementation/viewingRegionWeb'
import { useRedNavigate } from '../../../../../src/dataImplementation/useRedNavigate'
import { useUserSubscriptionStatus } from '../../../../query/subscriptions'

import { useCancelSubscription } from './useCancelSubscription'
import { StyledButton, ButtonsContainer, Subtitle, Title } from './styles'

export const SubscriptionCancellation = () => {
  const dispatch = useAppDispatch()
  const redNavigate = useRedNavigate()
  const [selectedReason, setSelectedReason] = useState<string | null>(null)
  const [reasonText, setReasonText] = useState<string>('')
  const { currentSubscription, refetch: refetchUserSubscriptionStatus } = useUserSubscriptionStatus()
  const reasons = Array.from({ length: 7 }, (_, index) => getString(`account.subscription.cancel.content.reason.${index + 1}`))
  const userProvidedReason = reasons[reasons.length - 1]
  const isInputDisabled = selectedReason !== userProvidedReason
  const trimmedReasonText = reasonText.trim()
  const isButtonDisabled = !selectedReason || (selectedReason === userProvidedReason && !trimmedReasonText)

  const onConfirmPress = () => {
    redNavigate(PATHS.MY_ACCOUNT_SUBSCRIPTION)
    refetchUserSubscriptionStatus()
  }

  const { cancelSubscription, isPending, isError, error } = useCancelSubscription(onConfirmPress)

  const onCancelPress = () => {
    if (!currentSubscription?.id) return
    cancelSubscription({
      subscriptionId: currentSubscription.id,
      reasonText: (trimmedReasonText || selectedReason) as string,
    })
  }

  const openCancelSubscriptionDialog = () => {
    dispatch(
      doShowDialog({
        callbackOnConfirm: onConfirmPress,
        callbackOnCancel: onCancelPress,
        titleTextId: 'account.subscription.confirmCancelationModal.title',
        bodyTextId: 'account.subscription.confirmCancelationModal.body',
        buttonCancelTextId: 'account.subscription.confirmCancelationModal.confirm',
        buttonConfirmTextId: 'account.subscription.confirmCancelationModal.cancel',
        type: DialogType.ALERT,
        explicitCancelRequired: true,
      })
    )
  }

  const onReasonChange = (reason: string) => {
    setSelectedReason(reason)

    if (reason !== userProvidedReason && reasonText) {
      setReasonText('')
    }
  }

  return (
    <Page title={getString('account.subscription.page.title')}>
      <MyAccountBackground>
        <Title>{getString('account.subscription.cancel.content.title')}</Title>
        <Subtitle>{getString('account.subscription.cancel.content.subtitle')}</Subtitle>
        <Text>{getString('account.subscription.cancel.content.feedback')}</Text>
        <RadioGroup onValueChange={onReasonChange} marginVertical="$2">
          {reasons.map((reason, index) => {
            const id = `cancel-reason-${index + 1}`
            return (
              <XStack key={reason} alignItems="center" gap="$2">
                <RadioGroup.Item value={reason} id={id}>
                  <RadioGroup.Indicator />
                </RadioGroup.Item>
                <Label htmlFor={id} fontSize="$3">
                  {reason}
                </Label>
              </XStack>
            )
          })}
        </RadioGroup>
        <YStack gap="$3" width="100%">
          <Input
            fontSize="$3"
            disabled={isInputDisabled}
            value={reasonText}
            placeholder={getString('account.subscription.cancel.content.input.placeholder')}
            onChange={(e) => setReasonText(e.nativeEvent.text)}
          />
          {isError && <Text color="$red6">{`${getString('api.unknownError')}: ${error?.code}`}</Text>}
          {isPending ? (
            <Spinner color="$red6" />
          ) : (
            <ButtonsContainer>
              <StyledButton variant="secondary" onPress={openCancelSubscriptionDialog} disabled={isButtonDisabled}>
                {getString('actions.continue')}
              </StyledButton>
              <Link href={localizePath(PATHS.MY_ACCOUNT_SUBSCRIPTION)} asChild="except-style" style={{ textDecoration: 'none' }}>
                <StyledButton>{getString('account.subscription.cancel.content.keepButton')}</StyledButton>
              </Link>
            </ButtonsContainer>
          )}
        </YStack>
      </MyAccountBackground>
    </Page>
  )
}
