import React, { forwardRef, MouseEvent, useRef, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector, useDebounce } from 'web/src/redux/hooks/hooks'
import { color, FontFamilies } from 'web/src/style/variables'
import { getSearchResultsView, getIsLoading, getSearchTerm } from '@vrw/data/src/redux/search/selectors'
import { setSearchTerm, setSearchResultsView } from '@vrw/data/src/redux/search/actions'
import { doRewardsSearch } from '@vrw/data/src/redux/search/dispatchers'
import { LoadingSpinnerWrapper } from 'web/src/components/Spinner'
import { SearchResults } from './SearchResults'
import { PUBLIC_URL } from '../../../config'

type Tab = 'Earn' | 'Spend'

export const Search = forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(getSearchResultsView)
  const searchValue = useAppSelector(getSearchTerm) ?? ''
  const [hideResults, setHideResults] = useState(false)
  const debouncedSearchValue = useDebounce<string>(searchValue, 600)
  const showResults = debouncedSearchValue.length > 2 && !hideResults
  const tabs: Tab[] = ['Earn', 'Spend']
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      dispatch(doRewardsSearch(debouncedSearchValue))
      setHideResults(false)
    }
  }, [dispatch, debouncedSearchValue])
  const handleClearSearch = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dispatch(setSearchTerm(''))
    inputRef?.current?.focus()
    setHideResults(true)
  }
  return (
    <>
      <style jsx global>
        {`
          body {
            pointer-events: none;
            overflow-y: hidden !important;
          }
          .overlay {
            display: block;
          }
          header {
            background: white;
            z-index: 2;
          }
        `}
      </style>
      <style jsx>{`
        .search-wrapper {
          background-image: linear-gradient(to bottom, ${color.darkPinkBackground} 0%, ${color.white} 100%);
          background-repeat: repeat-x;
          border-top: 1px solid ${color.white};
          font-size: 16px;
          left: 0;
          pointer-events: auto;
          position: absolute;
          right: 0;
          top: 72px;
          width: 100%;
          z-index: 100;
        }
        .search-container {
          margin: 32px auto 48px;
          max-width: 80%;
          position: relative;
          width: 672px;
        }
        .search-box {
          position: relative;
          width: 100%;
          font-weight: 700;
        }
        input::placeholder {
          color: ${color.lighterGrey};
          font-weight: 400;
        }
        input[type='text'] {
          background:
            url(${PUBLIC_URL || ''}/img/search-icon.svg) 18px center / 24px no-repeat,
            ${color.white};
          border: 1px solid ${color.brandPrimary};
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
          border-radius: 12px;
          font-size: inherit;
          font-weight: inherit;
          height: 48px;
          padding-left: 61px;
          position: relative;
          width: 100%;
        }
        input:focus,
        input:focus-visible {
          outline: none;
        }
        .clear-search {
          background: url(${PUBLIC_URL || ''}/img/clear-search.svg) center / 24px no-repeat;
          border: none;
          cursor: pointer;
          height: 100%;
          padding: 18px;
          position: absolute;
          right: 0;
        }
        .search-tabs {
          border-radius: 23px;
          height: 45px;
          margin-top: 20px;
          width: 100%;
          position: relative;
          font-weight: 700;
        }
        .search-tab {
          background: ${color.white};
          border: 1px solid ${color.grey03};
          color: ${color.brandPrimary};
          cursor: pointer;
          font-family: ${FontFamilies.barlow};
          font-size: inherit;
          font-weight: inherit;
          padding: 12px 0;
          width: 50%;
          position: absolute;
        }
        .search-tab:first-child {
          border-top-left-radius: 23px;
          border-bottom-left-radius: 23px;
          left: 0;
        }
        .search-tab:last-child {
          border-top-right-radius: 23px;
          border-bottom-right-radius: 23px;
          right: 0;
        }
        .search-tab[aria-selected='true'] {
          background: ${color.brandPrimary};
          border: 1px solid ${color.brandPrimary};
          color: ${color.white};
        }
        .search-tab:first-child[aria-selected='true']:after {
          border-top-right-radius: 23px;
          border-bottom-right-radius: 23px;
          content: '';
          right: -18px;
        }
        .search-tab:last-child[aria-selected='true']:before {
          border-top-left-radius: 23px;
          border-bottom-left-radius: 23px;
          content: '';
          left: -18px;
        }
        .search-tab:after,
        .search-tab:before {
          background: ${color.brandPrimary};
          bottom: -1px;
          position: absolute;
          top: -1px;
          z-index: 1;
          width: 24px;
        }
      `}</style>
      <div className="search-wrapper" ref={ref}>
        <div className="search-container">
          <div className="search-box">
            <input
              ref={inputRef}
              aria-label="Search input box"
              autoFocus
              maxLength={50}
              onChange={({ target }) => dispatch(setSearchTerm(target.value))}
              placeholder="Search Virgin Red offers"
              type="text"
              value={searchValue}
            />
            {searchValue.length > 0 && (
              <button aria-label="Clear Search input box" className="clear-search" onClick={(e) => handleClearSearch(e)}></button>
            )}
          </div>
          <div className="search-tabs">
            {tabs.map((tab) => (
              <button
                key={tab}
                aria-selected={activeTab === tab}
                className={`search-tab`}
                onClick={() => dispatch(setSearchResultsView(tab))}
                role="tab"
              >
                {tab}
              </button>
            ))}
          </div>
          {showResults && (
            <LoadingSpinnerWrapper getIsLoading={getIsLoading}>
              <SearchResults />
            </LoadingSpinnerWrapper>
          )}
        </div>
      </div>
    </>
  )
})
