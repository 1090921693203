import React, { FC } from 'react'

import { getString } from '@vrw/data'

import { media } from '../../style/media'
import { color, spacing } from '../../style/variables'

import { CurvedContainer } from '../CurvedContainer'

import H2Header from '../typography/H2Header'
import Body from '../typography/Body'
import { Button } from '../Buttons/Button'

import { LoggedOutHeroProps } from './types'
import { Picture } from '../Picture'

import { PATHS } from '../../router/paths'
import { StyledCopyAnchor } from '../links'
import { PUBLIC_URL } from '../../config'

const LoggedOutHero: FC<LoggedOutHeroProps> = ({ doLogin }) => {
  return (
    <>
      <style jsx>{`
        .content-wrapper {
          margin: 0 ${spacing.pageMargins.mobile}px 24px;
        }
        .header-wrapper {
          max-width: 280px;
        }
        .buttons-wrapper {
          display: flex;
          margin-top: 16px;
        }
        .join-red-button {
          margin-right: 12px;
          max-width: 150px;
        }
        .log-in-button {
          max-width: 150px;
        }
        .hero-image-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          z-index: -1;
        }
        .hero-image {
          height: auto;
          margin: 0 auto;
          width: 100%;
          z-index: 1;
        }

        @media ${media.tabletAndHigher} {
          .component-wrapper {
            display: flex;
          }
          .header-wrapper {
            max-width: 290px;
          }
          .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
          }
          .hero-image-wrapper {
            margin-top: 48px;
            width: 50%;
          }
          .hero-image {
            height: 100%;
            width: 100%;
          }
          .buttons-wrapper {
            margin-bottom: 48px;
            margin-top: 16px;
          }
        }

        @media ${media.desktopAndHigher} {
          .component-wrapper {
            margin: 0 auto -70px;
            max-width: 1440px;
          }
          .content-max {
            margin: 70px auto;
            max-width: 520px;
          }
          .header-wrapper {
            max-width: 500px;
          }
          .buttons-wrapper {
            margin-top: 40px;
          }
          .hero-image {
            max-height: 300px;
            max-width: 300px;
          }
        }
      `}</style>
      <CurvedContainer position="bottom" overlayColor={color.darkPinkBackground} height={60}>
        <div className="component-wrapper">
          <div className="content-wrapper">
            <div className="content-max">
              <div className="header-wrapper">
                <H2Header textAlign="left" fontStyle="italic" weight={600} marginTop={{ desktop: 0 }}>
                  WELCOME TO VIRGIN RED
                </H2Header>
              </div>
              <Body marginTop={{ mobile: 0, tablet: 0, desktop: 0 }} marginBottom={{ mobile: 0, tablet: 30, desktop: 0 }} isDesktopTextBig>
                Virgin Red gives you access to <StyledCopyAnchor to={PATHS.SPEND}>rewards</StyledCopyAnchor> from the Virgin family and
                beyond. Join today, and get <StyledCopyAnchor to={`${PATHS.EARN}/297260`}>500 bonus Virgin Points</StyledCopyAnchor> the
                first time you <StyledCopyAnchor to={PATHS.EARN}>earn</StyledCopyAnchor>, like{' '}
                <StyledCopyAnchor to={`${PATHS.EARN}/308773`}>3 points for every £1 you spend on train tickets</StyledCopyAnchor>,
                nationwide.
              </Body>
              <div className="buttons-wrapper">
                <div className="join-red-button">
                  <Button
                    onClick={() => doLogin()}
                    text={getString('loginBox.button')}
                    isPrimary
                    display="block"
                    dataAttributes={{ testid: 'loggedOutHero-btn-join' }}
                  />
                </div>
                <div className="log-in-button">
                  <Button
                    onClick={() => doLogin()}
                    text={getString('loginBox.memberQuery.link')}
                    isPrimary={false}
                    display="block"
                    dataAttributes={{ testid: 'loggedOutHero-btn-login' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hero-image-wrapper">
            <Picture
              altText=""
              width={600}
              fallbackImg={`${PUBLIC_URL}/img/CAMPAIGN-TRAINS-HERO.jpg`}
              height={600}
              responsiveImages={{
                mobile: {
                  imgSrc: `${PUBLIC_URL}/img/webp/CAMPAIGN-TRAINS-HERO.webp`,
                },
                tablet: {
                  imgSrc: `${PUBLIC_URL}/img/webp/CAMPAIGN-TRAINS-HERO.webp`,
                },
              }}
              loading={'eager'}
              isWebP={true}
            />
          </div>
        </div>
      </CurvedContainer>
    </>
  )
}

export { LoggedOutHero }
