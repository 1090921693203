import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Locale } from '@vrw/data/src/utils'
import { Login, LoginSuccess, LogoutSuccess, NotFound, EmailUpdated, PartnerLoginSuccess, DynamicComposedPage } from '../pages'
import { PATHS, virginRedPath } from './paths'
import { RegionalisedRoutes } from './regionalizedRoutes'
import { PrivateRoute } from '../components'

export const RootRouter = () => (
  <Routes>
    {/* GB only routes */}
    <Route path={`${virginRedPath}${PATHS.LOGIN_SUCCESS}`} element={<LoginSuccess />} />
    <Route path={`${virginRedPath}${PATHS.LOGOUT_SUCCESS}`} element={<LogoutSuccess />} />
    <Route path={`${virginRedPath}${PATHS.LOGIN}`} element={<Login />} />
    <Route path={`${virginRedPath}${PATHS.EMAIL_UPDATED}`} element={<EmailUpdated />} />
    <Route path={`${virginRedPath}${PATHS.PARTNER_LOGIN_SUCCESS}`} element={<PartnerLoginSuccess />} />
    {/* US Only routes */}
    <Route
      path={`/${Locale.EN_US}${virginRedPath}${PATHS.REWARDS_CREDIT_CARD}`}
      element={<DynamicComposedPage slugName="rewards-credit-card" />}
    />
    <Route
      path={`/${Locale.EN_US}${virginRedPath}${PATHS.MY_ACCOUNT_CREDIT_CARD_HUB}`}
      element={
        <PrivateRoute>
          <DynamicComposedPage slugName="my-virgin-reward-card-benefits" />
        </PrivateRoute>
      }
    />
    {/* NON Region specific routes */}
    <Route path={`/:locale?${virginRedPath}/*`} element={<RegionalisedRoutes />} />
    {/* catch all route */}
    <Route path="*" element={<NotFound />} />
  </Routes>
)
