import { styled, Text, XStack } from '@red-ui/components/'

export const InfoBoxWrapper = styled(XStack, {
  maxWidth: 780,
  backgroundColor: '$pink10',
  alignItems: 'center',
  borderRadius: '$2',
  paddingHorizontal: '$2',
  paddingVertical: '$1',
  marginBottom: '$3',
})

export const InfoText = styled(Text, {
  fontSize: '$3',
  lineHeight: '$3',
  $gtMobile: {
    fontSize: '$5',
    lineHeight: '$5',
  },
})
