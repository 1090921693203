import React, { FC, useEffect, useState } from 'react'
import { Checkbox } from '@virgin-core/components'

import { getString } from '@vrw/data'
import { Profile } from '@vrw/data/src/redux/profile/types'

import { OnboardingBackground } from '../OnboardingBackground'
import { color } from '../../../style/variables'
import H3Header from '../../../components/typography/Red/H3Header'
import { Button } from '../../../components/Buttons/Button'
import { Spacer, Page } from '../../../components'

interface CommunicationPreferencesProps {
  doSubmitOnboarding: (profile: Partial<Profile>, requestParams?: Record<string, string[]> | undefined) => void
  startAutoLinkAccountsFlow: (errorMessage?: string) => void
  isUpdating: boolean
}

const introText = getString('onboarding.communicationPreferences.introText')
const instructionText = getString('onboarding.communicationPreferences.instruction')
const emailLabel = getString('onboarding.communicationPreferences.marketingEmail.label')
const smsLabel = getString('onboarding.communicationPreferences.marketingSms.label')

const CommunicationPreferences: FC<CommunicationPreferencesProps> = ({ doSubmitOnboarding, isUpdating, startAutoLinkAccountsFlow }) => {
  const [formState, setFormState] = useState({
    // Consent is opt-out on form but opt-in in API, so reverse boolean value
    marketingEmail: true,
    marketingSms: true,
    // This variable isn't needed on Web but has to be set to false to skip the Notifications Consent screen
    notifications: false,
  })

  useEffect(() => {
    const idToken = localStorage.getItem('partnerLinkIdToken')
    const partnerCode = localStorage.getItem('connectPartnerCode')
    if (idToken && partnerCode) {
      startAutoLinkAccountsFlow()
    }
  }, [startAutoLinkAccountsFlow])

  const onChange = (id: string, value: boolean) => {
    setFormState((prevState) => ({ ...prevState, [id]: !value }))
  }
  const doSubmit = () => {
    doSubmitOnboarding({
      ...formState,
      marketingEmailText: emailLabel,
      marketingSmsText: smsLabel,
      marketingText: `${introText}\n${instructionText}`,
    })
  }

  return (
    <Page
      testId="onboarding-communication-preferences"
      title="Your contact preferences | Virgin Red"
      showNavigation={false}
      showRedFooter={false}
      showFooter={false}
    >
      <OnboardingBackground>
        <style jsx>{`
          @keyframes in {
            from {
              transform: translateY(20px);
              opacity: 0;
            }
            to {
              transform: translateY(0px);
              opacity: 1;
            }
          }
          .parentContainer {
            animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
          }
          .parentContainer :global(.header-three) {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 16px;
          }
        `}</style>
        <div className="parentContainer">
          <H3Header
            color={color.brandPrimary}
            textTransform="none"
            marginTop={{ mobile: 0 }}
            marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
          >
            {getString('onboarding.communicationPreferences.legend')}
          </H3Header>
          <Spacer size="small" />
          {introText}
          <Spacer size="xSmall" />
          {instructionText}
          <Spacer size="xSmall" />
          <Checkbox
            borderColor={color.lightGrey}
            label={emailLabel}
            checked={!formState.marketingEmail}
            name="marketingEmail"
            onChange={(event: any, checked: boolean) => onChange('marketingEmail', checked)}
            data-testid="checkbox-email"
          />
          <br />
          <Checkbox
            borderColor={color.lightGrey}
            label={smsLabel}
            checked={!formState.marketingSms}
            name="marketingSms"
            onChange={(event: any, checked: boolean) => onChange('marketingSms', checked)}
            data-testid="checkbox-sms"
          />
          <Spacer size="xSmall" />
          {getString('onboarding.communicationPreferences.note')}
          <Spacer size="large" />
          <div className="button-wrapper">
            <Button
              isPrimary
              text={getString('onboarding.communicationPreferences.confirmButton')}
              onClick={doSubmit}
              loading={isUpdating}
              dataAttributes={{ testid: 'onboarding-comms-prefs-button' }}
            />
          </div>
        </div>
      </OnboardingBackground>
    </Page>
  )
}

export { CommunicationPreferences }
