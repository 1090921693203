import React, { FC } from 'react'
import { getString } from '@vrw/data'
import { getRegionDisplayName } from '@vrw/data/src/utils'
import { setRegionBannerSeen, getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { useRegionSelector } from '../../context/RegionSelectorProvider'
import { GlobeIcon, Cross } from '../icons'
import { Body } from '..'
import { Button } from '../Buttons/Button'
import { color } from '../../style/variables'
import { size, media } from '../../style/media'

export const RegionSelectorBanner: FC = () => {
  const currentRegion = localeToRegion(getLocale())
  const { hideRegionSelectorBanner, showRegionSelectorModal } = useRegionSelector()

  const closeBanner = () => {
    setRegionBannerSeen()
    hideRegionSelectorBanner()
  }

  return (
    <>
      <style jsx>{`
        .region-selector-banner-container {
          background-color: ${color.darkPinkBackground};
          width: 100%;
          overflow: hidden;
        }
        .region-selector-banner {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 574px;
          padding: 16px 16px 24px;
          margin: 0 auto;
        }
        .region-selector-banner-text {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
        }
        .region-selector-banner-icon {
          margin-bottom: 10px;
        }
        .region-selector-banner-text :global(p) {
          font-weight: 600;
          margin: 0;
        }
        .region-selector-banner-button-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .region-selector-banner-button-wrapper > :global(button):not(:last-of-type) {
          margin-bottom: 16px;
        }
        .region-selector-banner-close-button {
          position: absolute;
          top: 16px;
          right: 16px;
          border: 0;
          background: none;
          padding: 0;
          cursor: pointer;
        }
        @media ${media.tabletAndHigher} {
          .region-selector-banner {
            max-width: ${size.hiResDesktopMin}px;
            padding: 24px 44px;
          }
          .region-selector-banner-text {
            flex-direction: row;
          }
          .region-selector-banner-icon {
            margin-bottom: 0;
            margin-right: 18px;
          }
          .region-selector-banner-button-wrapper {
            flex-direction: row;
            width: auto;
            margin-right: auto;
          }
          .region-selector-banner-button-wrapper > :global(button) {
            min-width: 148px;
          }
          .region-selector-banner-button-wrapper > :global(button):not(:last-of-type) {
            margin-bottom: 0;
            margin-right: 16px;
          }
          .region-selector-banner-close-button {
            top: 24px;
            right: 44px;
          }
        }
        @media ${media.desktopAndHigher} {
          .region-selector-banner {
            flex-direction: row;
            max-width: ${size.hiResDesktopMin}px;
            padding: 35px 124px;
          }
          .region-selector-banner-text {
            align-items: center;
            padding-right: 16px;
            margin-bottom: 0;
            margin-right: auto;
          }
          .region-selector-banner-button-wrapper {
            align-items: center;
            margin-right: 24px;
          }
          .region-selector-banner-close-button {
            position: relative;
            top: 0;
            right: 0;
          }
        }
        @media ${media.hiResDesktop} {
          .region-selector-banner {
            padding: 35px 170px;
          }
        }
      `}</style>
      <div className="region-selector-banner-container" role="complementary">
        <div className="region-selector-banner">
          <div className="region-selector-banner-text">
            <span className="region-selector-banner-icon">
              <GlobeIcon size={27} />
            </span>
            <Body marginTop={{ mobile: 0, tablet: 0, desktop: 0 }} marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
              <span>{getString('region.banner.body.1').replace('{{currentRegion}}', getRegionDisplayName(currentRegion))}</span>
              <br />
              <span>{getString('region.banner.body.2')}</span>
            </Body>
          </div>
          <div className="region-selector-banner-button-wrapper">
            <Button
              data-testid="banner-button-confirm"
              dataAttributes={{ testid: 'banner-button-confirm' }}
              text={getString('region.banner.button.confirm')}
              onClick={closeBanner}
              isPrimary
            />
            <Button
              data-testid="banner-button-select-country"
              dataAttributes={{ testid: 'banner-button-select-country' }}
              text={getString('region.banner.button.selectCountry')}
              onClick={showRegionSelectorModal}
              id="banner-button-select-country"
            />
          </div>

          <button
            aria-label="close"
            className="region-selector-banner-close-button"
            onClick={closeBanner}
            data-testid="banner-button-cross"
          >
            <Cross size={18} color={color.textStandard} />
          </button>
        </div>
      </div>
    </>
  )
}

const RegionSelectorBannerWrapper: FC = () => {
  const { isRegionSelectorBannerVisible } = useRegionSelector()
  return isRegionSelectorBannerVisible ? <RegionSelectorBanner /> : null
}

export default RegionSelectorBannerWrapper
