import React, { FC } from 'react'
import { AuthWrapper, Page } from '../../components'
import { MyRewardsPrivate } from './MyRewardsPrivate'
import { getString } from '@vrw/data'
import { MyRewardsPublicContainer as MyRewardsPublic } from './MyRewardsPublicContainer'

const MyRewards: FC = () => {
  return (
    <Page testId="page-myrewards" title="My rewards | Virgin Red" metaDescription={getString('meta.description.my.rewards')}>
      <AuthWrapper publicComponent={<MyRewardsPublic />} privateComponent={<MyRewardsPrivate />} />
    </Page>
  )
}

export { MyRewards }
