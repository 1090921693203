import { selectFeatureById } from '@vrw/data/src/redux/features/features.selectors'
import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from '../components'
import {
  Activity,
  ComposedPage,
  Earn,
  EmailUpdated,
  EmailUpdatedSuccess,
  Home,
  ModernSlaveryStatement,
  MyRewards,
  NotFound,
  OrderCheckout,
  OrderConfirmation,
  PrivacyPolicy,
  ReferAFriend,
  RewardDetails,
  Spend,
  TermsAndConditions,
  Voucher,
} from '../pages'
import {
  AccountSettings,
  LinkAccounts as LinkAccountsMyAccount,
  PersonalDetails as PersonalDetailsMyAccount,
  PromoCodes,
  ReferDashboard,
  UpdateEmail,
  VerifyEmail,
} from '../pages/MyAccount'
import { CommunicationPreferences, PersonalDetails, TermsAndConditions as TermsAndConditionsOnboarding, Welcome } from '../pages/Onboarding'
import { AutoLinkAccounts } from '../pages/Onboarding/AutoLinkAccounts'
import { PartnerLinkVAA } from '../pages/Onboarding/PartnerLinkVAA'
import { useAppSelector } from '../redux/hooks/hooks'
import { PATHS } from './paths'
import { useOnRouteChange } from './useOnRouteChange'
import { getViewingRegion } from '../dataImplementation/viewingRegionWeb'
import { ViewingRegion } from '@vrw/data/src/utils'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { BrandDetails } from '../pages/Brands/BrandDetails'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { Redirect } from './redirect'
import { useSubscriptionRoutes } from './useSubscriptionRoutes'

export const RegionalisedRoutes: FC = () => {
  const webSubscriptionFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_SUBSCRIPTION))
  const viewingRegion = getViewingRegion()
  const userProfileCountry = useAppSelector(getProfileData)?.country
  const subscriptionRoutes = useSubscriptionRoutes()

  useOnRouteChange()

  return (
    <Routes>
      {/* PUBLIC ROUTES that can display Private content */}
      <Route path={PATHS.HOME} element={<Home />} />
      <Route path={PATHS.SPEND} element={<Spend />} />
      <Route path={PATHS.SPEND_REWARD} element={<RewardDetails rewardType="spend" />} />
      <Route path={PATHS.EARN} element={<Earn />} />
      <Route path={PATHS.EARN_REWARD} element={<RewardDetails rewardType="earn" />} />
      <Route path={PATHS.BRAND_DETAILS} element={<BrandDetails />} />
      {viewingRegion === ViewingRegion.GB ? <Route path={PATHS.REFER_A_FRIEND} element={<ReferAFriend />} /> : null}
      <Route path={PATHS.REWARDS_CREDIT_CARD_WAITLIST} element={<Redirect to={PATHS.REWARDS_CREDIT_CARD} persistQueryParams />} />
      {/* PRIVATE ROUTES that can display ONLY Public content */}
      <Route
        path={PATHS.ONBOARDING_PERSONAL_DETAILS}
        element={
          <PrivateRoute>
            <PersonalDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ONBOARDING_TERMS_AND_CONDITIONS}
        element={
          <PrivateRoute>
            <TermsAndConditionsOnboarding />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ONBOARDING_COMMUNICATION_PREFERENCES}
        element={
          <PrivateRoute>
            <CommunicationPreferences />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ONBOARDING_WELCOME}
        element={
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ONBOARDING_LINK_ACCOUNTS_DEPRECATED}
        element={
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ONBOARDING_AUTO_LINK_ACCOUNTS}
        element={
          <PrivateRoute>
            <AutoLinkAccounts />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ONBOARDING_PARTNER_LINK_VAA}
        element={
          <PrivateRoute enforceExternalAuth>
            <PartnerLinkVAA />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.VOUCHER}
        element={
          <PrivateRoute>
            <Voucher />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ORDER}
        element={
          <PrivateRoute>
            <OrderConfirmation />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.MY_REWARDS}
        element={
          <PrivateRoute>
            <MyRewards />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.MY_ACCOUNT_PERSONAL_DETAILS}
        element={
          <PrivateRoute>
            <PersonalDetailsMyAccount />
          </PrivateRoute>
        }
      />
      {webSubscriptionFlag &&
        subscriptionRoutes.map(({ component, path }) => (
          <Route key={path} path={path} element={<PrivateRoute>{component}</PrivateRoute>} />
        ))}
      <Route
        path={PATHS.MY_ACCOUNT_LINK_ACCOUNTS}
        element={
          <PrivateRoute>
            <LinkAccountsMyAccount />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.MY_ACCOUNT_PROMO_CODES}
        element={
          <PrivateRoute>
            <PromoCodes />
          </PrivateRoute>
        }
      />
      {userProfileCountry === ViewingRegion.GB && (
        <Route
          path={PATHS.MY_ACCOUNT_REFER_DASHBOARD}
          element={
            <PrivateRoute>
              <ReferDashboard />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path={PATHS.MY_ACCOUNT_SETTINGS}
        element={
          <PrivateRoute>
            <AccountSettings />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.MY_ACCOUNT_UPDATE_EMAIL}
        element={
          <PrivateRoute>
            <UpdateEmail />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.MY_ACCOUNT_VERIFY_EMAIL}
        element={
          <PrivateRoute>
            <VerifyEmail />
          </PrivateRoute>
        }
      />
      <Route path={PATHS.EMAIL_UPDATED_SUCCESS} element={<EmailUpdatedSuccess />} />
      {/* The EMAIL_UPDATED path is here and in router.tsx because the us version was flashing the whoops not found page  - shouldn't be on both  */}
      <Route path={PATHS.EMAIL_UPDATED} element={<EmailUpdated />} />
      <Route
        path={PATHS.ACTIVITY}
        element={
          <PrivateRoute>
            <Activity />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.ORDER_CHECKOUT}
        element={
          <PrivateRoute>
            <OrderCheckout />
          </PrivateRoute>
        }
      />
      {/* PUBLIC ROUTES that can display ONLY Public content */}
      <Route path={PATHS.PRIVACY} element={<PrivacyPolicy />} />
      <Route path={PATHS.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
      <Route path={PATHS.MODERN_SLAVERY_STATEMENT} element={<ModernSlaveryStatement />} />
      <Route path={PATHS.COMPOSE_PAGE} element={<ComposedPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
