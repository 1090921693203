import React from 'react'
import { getString } from '@vrw/data'
import { getIsUpdating } from '@vrw/data/src/redux/profile/selectors'
import { doSubmitOnboarding } from '@vrw/data/src/redux/profile/dispatchers'
import H3Header from '../../../components/typography/Red/H3Header'
import { Button } from '../../../components/Buttons/Button'
import { Spacer } from '../../../components'
import { color } from '../../../style/variables'
import { PUBLIC_URL } from '../../../config'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hooks'

const LinkAccountsError = () => {
  const dispatch = useAppDispatch()
  const isUpdating = useAppSelector(getIsUpdating)

  return (
    <>
      <style jsx>{`
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .parentContainer {
          animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
        }
        .parentContainer :global(.header-three) {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        .image {
          width: 100%;
          height: 100%;
          max-width: 270px;
          margin: 45px auto;
          display: block;
        }
        .body-wrapper {
          text-align: center;
          margin-bottom: 40px;
        }
      `}</style>
      <div className="parentContainer">
        <H3Header
          color={color.brandPrimary}
          textAlign="center"
          textTransform="none"
          marginTop={{ mobile: 0 }}
          marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
        >
          {getString('onboarding.linkVaa.error.title')}
        </H3Header>
        <img
          src={`${PUBLIC_URL}/img/link-vaa-account/error.png`}
          className="image"
          alt="Virgin Red and Virgin Atlantic Airlines unlinked logo"
        />
        <div className="body-wrapper">
          {getString('onboarding.linkVaa.error.body1')}
          <Spacer size="xSmall" />
          {getString('onboarding.linkVaa.error.body2')}
        </div>
        <div className="button-wrapper">
          <Button
            isPrimary
            text={getString('onboarding.linkVaa.error.nextButton')}
            onClick={() => dispatch(doSubmitOnboarding({ linkedAccountsState: { VAA: false }, complete: true }))}
            loading={isUpdating}
          />
        </div>
      </div>
    </>
  )
}

export { LinkAccountsError }
