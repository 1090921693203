import { styled, Tooltip, View } from '@red-ui/components/'
import { CARD_WIDTH } from '../styles'

export const QuestionIcon = styled(View, {
  backgroundColor: '$black10',
  display: 'inline-flex',
  width: '$2',
  height: '$2',
  bottom: '$0.75',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$4',
  cursor: 'pointer',
})

export const TooltipContent = styled(Tooltip.Content, {
  borderRadius: '$4',
  enterStyle: { x: 0, y: -5, opacity: 0, scale: 0.9 },
  exitStyle: { x: 0, y: -5, opacity: 0, scale: 0.9 },
  maxWidth: CARD_WIDTH,
  shadowColor: '$black10',
  shadowOpacity: 0.3,
  shadowOffset: {
    width: 0,
    height: 10,
  },
  shadowRadius: 100,
  animation: [
    'quick',
    {
      opacity: {
        overshootClamping: true,
      },
    },
  ],
})
