import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducer } from '@vrw/data'
import { RedDataAction } from '@vrw/data/src/types'
import { LOGOUT_SUCCESS } from '@vrw/data/src/redux/auth/types'
import { RootState } from './types'
import { ENV } from '../config'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'intro',
    'categories',
    'categoriesEarn',
    'categoriesPublic',
    'categoriesEarnPublic',
    'rewardsEarn',
    'rewardsPublic',
    'rewardsEarnPublic',
    'discovery',
    'discoveryEarn',
    'discoveryPublic',
    'discoveryEarnPublic',
    'pages',
  ],
}

const rootReducer = (state: RootState | undefined, action: RedDataAction) => {
  let rootState = state
  if (action.type === LOGOUT_SUCCESS) {
    storage.removeItem('persist:root')
    rootState = undefined
  }
  return reducer(rootState, action)
}

const createStore = () => {
  // remove isRTKFullyMigrated expression and allow immutableCheck & serializableCheck once fully migrated!
  const isRTKFullyMigrated = false
  const persistedReducer: any = persistReducer<RootState>(persistConfig, rootReducer as any)
  const store = configureStore({
    reducer: persistedReducer,
    devTools: ENV === 'localhost' || ENV === 'dev',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: isRTKFullyMigrated,
        serializableCheck: isRTKFullyMigrated,
      }),
  })
  const persistor = persistStore(store)
  return { store, persistor }
}

const { store, persistor } = createStore()

export { store, persistor }
