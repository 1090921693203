import React from 'react'
import { XStack, YStack, Icon, Spinner } from '@red-ui/components/'
import { getString } from '@vrw/data'
import { SUBSCRIPTION_STATUS } from '@vrw/data/src/api/subscriptions.api'

import { Page } from '../../../../src/components'
import { MyAccountBackground } from '../MyAccountBackground'
import { useSubscriptionPlanById, useUserSubscriptionStatus } from '../../../query/subscriptions'
import { RestartSubscriptionInfoBox } from './RestartSubscriptionInfoBox'
import {
  ContentWrapper,
  SpinnerContainer,
  Title,
  LogoWrapper,
  Logo,
  SubscriptionName,
  Subtitle,
  FeaturesWrapper,
  FeaturesTable,
  FeatureTableItem,
  FeatureTableItemText,
} from './styles'

export const Subscription = () => {
  const { currentSubscription: { planId, status } = {} } = useUserSubscriptionStatus()
  const { data, isLoading } = useSubscriptionPlanById(planId)

  return (
    <Page title={getString('account.subscription.page.title')}>
      <MyAccountBackground>
        {isLoading || !planId ? (
          <SpinnerContainer>
            <Spinner color="$red6" size="large" />
          </SpinnerContainer>
        ) : (
          data && (
            <ContentWrapper>
              {status === SUBSCRIPTION_STATUS.INACTIVE && <RestartSubscriptionInfoBox subscriptionEndDate={new Date()} />}
              <Title alignSelf="flex-start">{getString('account.subscription.content.title')}</Title>
              <XStack alignItems="center">
                <LogoWrapper>
                  <Logo />
                </LogoWrapper>
                <YStack>
                  <SubscriptionName>{data.name}</SubscriptionName>
                  <Subtitle>{`Renews on 2 July 2024 for ${data.cost}/${data.frequency.toLowerCase()}`}</Subtitle>
                </YStack>
              </XStack>
              <FeaturesWrapper>
                <Subtitle marginBottom="$2">{getString('account.subscription.content.features.title')}</Subtitle>
                <FeaturesTable>
                  {data.features.map((feature) => (
                    <FeatureTableItem key={feature.description}>
                      <Icon.Check />
                      <FeatureTableItemText>{feature.description}</FeatureTableItemText>
                    </FeatureTableItem>
                  ))}
                </FeaturesTable>
              </FeaturesWrapper>
            </ContentWrapper>
          )
        )}
      </MyAccountBackground>
    </Page>
  )
}
