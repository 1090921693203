import React from 'react'

import { getString, transformInterpolatedString } from '@vrw/data'
import { color, layout, toastBgColors, toastColors } from '../../style/variables'
import { media } from '../../style/media'
import Cross from '../icons/Cross'
import Success from '../icons/Success'
import Error from '../icons/Error'
import { ToastType } from './types'
import { ToastProps } from './types'
import { Trans } from 'react-i18next'
import { StyledCopyAnchor } from '../links'

export const Toast = ({
  doUnsetToastMessage,
  autoDismissTime,
  isClosable = true,
  message,
  visible,
  type = ToastType.error,
}: ToastProps) => {
  const doUnsetToastMessageDelayed = () => {
    if (typeof doUnsetToastMessage === 'function') {
      doUnsetToastMessage()
    }
  }

  if (!message) {
    return null
  }

  if (visible && autoDismissTime) setTimeout(doUnsetToastMessageDelayed, autoDismissTime)

  let mainColor, secondaryColor
  switch (type) {
    case ToastType.success:
      mainColor = toastColors.success.title
      secondaryColor = toastBgColors.success
      break
    case ToastType.warning:
      mainColor = toastColors.warning.title
      secondaryColor = toastBgColors.warning
      break
    case ToastType.error:
      mainColor = toastColors.error.title
      secondaryColor = toastBgColors.error
      break
    case ToastType.information:
      mainColor = toastColors.information.title
      secondaryColor = toastBgColors.information
      break
    default:
      mainColor = toastColors.error.title
      secondaryColor = toastBgColors.error
  }

  return (
    <>
      <style jsx>{`
        .container {
          width: 100%;
          display: flex;
          justify-content: center;
          position: fixed;
          bottom: 0;
          z-index: 2;
          transition: 150ms ease-out;
          pointer-events: none;
          transform: translateY(20px);
          opacity: 0;
        }
        .container--visible {
          transform: translateY(0);
          opacity: 1;
        }
        .toast {
          max-width: ${layout.heroWidth.desktop}px;
          margin: 15px;
          padding: 16px 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${secondaryColor};
          border-radius: 8px;
          box-shadow: 0 0 10px 0 rgba(29, 29, 29, 0.1);
          transition: 300ms;
          text-align: center;
          color: ${mainColor};
        }
        .toast--visible {
          pointer-events: all;
        }
        .toast a {
          margin: 0 4px;
          color: ${mainColor};
          font-weight: bold;
          text-decoration: none;
        }
        .bold {
          font-weight: 600;
        }
        :global(.event-icon) {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          flex: none;
        }
        :global(.close-icon) {
          width: 25px;
          height: 45px;
          padding: 15px 0 15px 10px;
          flex: 0 0 auto;
          cursor: pointer;
        }
        @media ${media.tabletAndHigher} {
          .toast {
            margin: 20px;
            padding: 20px 70px;
          }
          :global(.event-icon) {
            margin: 0 20px 0 0;
          }
          :global(.close-icon) {
            width: 45px;
            height: 45px;
            padding: 15px 0 15px 30px;
          }
        }
      `}</style>
      <div className={`container ${visible ? `container--visible` : ''}`}>
        <div
          className={`toast ${visible ? `toast--visible` : ''}`}
          data-testid={visible ? `toast-${type}-visible` : `toast-${type}`}
          role="alert"
        >
          {type === ToastType.success && <Success size={20} color={mainColor} className="event-icon" role="img" />}
          {type === (ToastType.error || ToastType.warning) && <Error size={20} color={mainColor} className="event-icon" role="img" />}
          <div>
            <Trans
              defaults={transformInterpolatedString(message)}
              components={{
                supportLink: <StyledCopyAnchor to={transformInterpolatedString(getString('link.account.help'))} target external />,
                bold: <span className="bold" />,
              }}
            />
          </div>
          {isClosable && typeof doUnsetToastMessage === 'function' && (
            <Cross
              aria-label="close-toast"
              size={15}
              color={color.lightGrey}
              className="close-icon"
              onClick={doUnsetToastMessageDelayed}
              role="button"
            />
          )}
        </div>
      </div>
    </>
  )
}
