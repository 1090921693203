import React, { FC, useEffect } from 'react'

import { getString } from '@vrw/data'
import { LinkingJourneyOrigins } from '@vrw/data/src/utils'
import { color } from '../../../style/variables'
import { Button } from '../../../components/Buttons/Button'
import SuccessIcon from '../../../components/icons/Success'
import { Page } from '../../../components'
import { LinkAccountsProps } from './types'
import { MyAccountBackground } from '../MyAccountBackground'
import { PUBLIC_URL } from '../../../config'

const LinkAccounts: FC<LinkAccountsProps> = ({
  accountsEntities,
  isUpdating,
  doFetchAccounts,
  doLinkAccountsWeb,
  doLinkAccountsWebResponseHandler,
}) => {
  // styled-jsx will throw a warning on console because of this component. I tried a fix. Didn't work:
  // https://github.com/vercel/next.js/issues/3432#issuecomment-350568476
  const Styling = () => (
    <style jsx>{`
      .link-accounts__title {
        color: ${color.grey01};
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        opacity: 0.8;
      }
      .link-accounts__sub-title {
        align-items: center;
        color: ${color.white};
        display: flex;
        font-size: 14px;
        font-weight: 500;
        justify-content: center;
        margin: 0 0 10px;
        opacity: 0.8;
      }
      :global(.link-accounts__success-icon) {
        margin-right: 3px;
      }
      .link-accounts__info-partner {
        background-color: rgba(${color.whiteRGB}, 0.2);
        color: ${color.white};
        height: 48px;
        font-size: 14px;
        line-height: 20px;
        padding: 4px 97px;
        border-radius: 8px;
        width: 100%;
      }
      .link-accounts__partner-user-id {
        font-size: 16px;
      }
    `}</style>
  )
  const NotLinked = () => (
    <>
      <Styling />
      <div className="link-accounts__title">{getString('account.linkAccounts.vaa.partnerName')}</div>
      <div className="link-accounts__sub-title">{getString('account.linkAccounts.vaa.status.unlinked')}</div>
      <Button
        isPrimary
        text={getString('account.linkAccounts.vaa.button')}
        onClick={() => doLinkAccountsWeb(LinkingJourneyOrigins.ACCOUNT)}
        loading={isUpdating}
        dataAttributes={{ testid: 'btn-link-account' }}
      />
    </>
  )
  const Linked = () => (
    <>
      <Styling />
      <div className="link-accounts__title">{getString('account.linkAccounts.vaa.partnerName')}</div>
      <div className="link-accounts__sub-title">
        <SuccessIcon className="link-accounts__success-icon" size={16} color={color.white} />
        <span>{getString('account.linkAccounts.vaa.status.linked')}</span>
      </div>
      <div className="link-accounts__info-partner">
        <div className="link-accounts__partner-name">{getString('account.linkAccounts.vaa.partnerUserIdName')}</div>
        <div className="link-accounts__partner-user-id">{accountsEntities!.VAA!.partnerUserId}</div>
      </div>
    </>
  )

  useEffect(() => {
    const asyncFunc = async () => {
      doFetchAccounts()
      if (isUpdating) {
        const response = await doLinkAccountsWebResponseHandler()
        return response.done && doFetchAccounts()
      }
      return undefined
    }
    asyncFunc()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <style jsx>{`
        .link-accounts {
          background-image: url('${PUBLIC_URL || ''}/img/link-vaa-account/card-background.jpg');
          background-position: center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 248px;
          margin-top: 18px;
          padding: 16px;
          text-align: center;
          width: 345px;
        }
        .link-accounts__logo {
          margin: 0 auto;
          height: 80px;
          width: 80px;
        }
      `}</style>
      <Page title="Your linked accounts | Virgin Red" testId="page-account-link-accounts">
        <MyAccountBackground>
          <div className="link-accounts">
            <img
              src={`${PUBLIC_URL || ''}/img/link-vaa-account/vaa-logo.png`}
              className="link-accounts__logo"
              alt="Virgin Red and Virgin Atlantic Airlines logo"
            />
            {accountsEntities.VAA ? <Linked /> : <NotLinked />}
          </div>
          {!accountsEntities.VAA && (
            <div style={{ width: '345px', textAlign: 'center' }}>
              <h4>{getString('account.linkAccounts.pending.title')}</h4>
              <p>{getString('account.linkAccounts.pending.body')}</p>
            </div>
          )}
        </MyAccountBackground>
      </Page>
    </>
  )
}

export { LinkAccounts }
