import React from 'react'
import { View, Text, Tooltip, XStack, Icon } from '@red-ui/components/'
import { QuestionIcon, TooltipContent } from './styles'

type Props = {
  description: string
  extendedDescription?: string
}

export const Feature = ({ description, extendedDescription: tooltipText }: Props) => {
  const renderDescription = () => {
    if (tooltipText) {
      return (
        <Tooltip placement="top" size="$2">
          <Tooltip.Trigger flexShrink={1}>
            <Text fontSize="$4">
              {description}
              <Tooltip.Anchor display="inline" marginLeft="$2" top="$0.5">
                <QuestionIcon>
                  <Text color="$white" fontSize="$1">
                    ?
                  </Text>
                </QuestionIcon>
              </Tooltip.Anchor>
            </Text>
          </Tooltip.Trigger>
          <TooltipContent>
            <Tooltip.Arrow size="$4" />
            <Text>{tooltipText}</Text>
          </TooltipContent>
        </Tooltip>
      )
    }

    return (
      <View flexShrink={1}>
        <Text fontSize="$4">{description}</Text>
      </View>
    )
  }

  return (
    <li>
      <XStack alignItems="center" paddingVertical="$1" gap="$2">
        <View>
          <Icon.Check />
        </View>
        {renderDescription()}
      </XStack>
    </li>
  )
}
