import { connect } from 'react-redux'

import { doFetchAccounts, doDeleteAccountPrompt } from '@vrw/data/src/redux/accounts/dispatchers'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { getAccountsEntities } from '@vrw/data/src/redux/accounts/selectors'
import { jsonToBase64 } from '@vrw/data/src/utils/helperFunctions'
import { getString } from '@vrw/data/src/utils'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

import { PersonalDetails } from './PersonalDetails'

const vidAccountCallbackParam = jsonToBase64({
  partner: getString('account.vid.settings.web.platform'),
  redirectPath: getString('account.vid.settings.web.path'),
})

const mapStateToProps = (state: RootState) => ({
  profile: getProfileData(state),
  accounts: getAccountsEntities(state),
  vidAccountCallbackParam,
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doFetchAccounts,
      doDeleteAccountPrompt,
    },
    dispatch
  )

const PersonalDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)

export { PersonalDetailsContainer }
