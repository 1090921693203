import { Text, View, YStack, styled, Button } from '@red-ui/components'
import { Chip } from '../Chip'

export const SectionWrapper = styled(View, {
  flexDirection: 'column',
  marginTop: '$3',
  $gtTabletLandscape: {
    flexDirection: 'row',
    padding: '$3',
    shadowColor: '$black10',
    shadowOpacity: 0.1,
    shadowRadius: 30,
    borderRadius: 8,
    borderColor: '$black1',
    borderWidth: 1,
    backgroundColor: '$white',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
})

export const DescribeWrapper = styled(YStack, {
  paddingHorizontal: 20,
  $gtMobile: {
    paddingLeft: 44,
  },
  $gtTabletPortrait: {
    paddingLeft: 60,
  },
  $gtTabletLandscape: {
    width: 340,
    paddingLeft: 0,
  },
  $gtDesktop: {
    width: 426,
  },
})

export const ChipRoot = styled(Chip.Root, {
  marginTop: '$1',
  marginBottom: '$2',
  $gtTabletLandscape: {
    marginTop: '$7',
    marginBottom: '$3',
  },
})

export const TitleText = styled(Text, {
  fontSize: '$9',
  fontStyle: 'italic',
  fontWeight: '600',
  marginBottom: '$2',
})

export const DescriptionText = styled(Text, {
  fontSize: '$5',
  lineHeight: '$5',
  marginBottom: '$4',
})

export const ButtonShowAll = styled(Button, {
  size: '$5',
  marginTop: '$4',
  maxWidth: '100%',
  marginHorizontal: 20,
  $gtMobile: {
    marginTop: '$0',
    marginBottom: '$5',
    maxWidth: 245,
    marginHorizontal: 0,
  },
})

export const CarouselWrapper = styled(View, {
  marginBottom: -68,
})
