import React, { FC, useEffect } from 'react'
import { useRedNavigate } from '../../src/dataImplementation/useRedNavigate'

interface RedirectedProps {
  to: string
  persistQueryParams?: boolean
}

export const Redirect: FC<RedirectedProps> = ({ to, persistQueryParams }) => {
  const navigate = useRedNavigate()

  useEffect(() => {
    navigate(`${to}${persistQueryParams ? window.location.search : ''}`)
  }, [navigate, persistQueryParams, to])
  return <></>
}
