import { styled, View, Text } from '@red-ui/components/'

export const ChipBox = styled(View, {
  minWidth: 113,
  backgroundColor: '$black10',
  paddingVertical: '$0.5',
  paddingHorizontal: '$3',
  borderRadius: '$7',
  shadowColor: '$black10',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.12,
  shadowRadius: '$1',
  alignSelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ChipText = styled(Text, {
  fontWeight: '600',
  color: 'white',
})
