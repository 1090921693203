export const slugStructureForLinking = ({
  baseUrl,
  rewardId,
  groupId,
  slug,
}: {
  baseUrl: string
  rewardId: string
  groupId?: string
  slug?: string
}): string => {
  const slugHyphenRewardId = `${slug}-${rewardId}`
  const groupIdParam = groupId ? `?groupId=${groupId}` : ''
  return `/virgin-red${baseUrl}/${slug ? slugHyphenRewardId : rewardId}${groupIdParam}`
}
