import { H4, Text, Image, styled, View, YStack, XStack } from '@red-ui/components/'
import { PUBLIC_URL } from '../../../config'

export const ContentWrapper = styled(YStack, {
  $gtMobile: {
    minWidth: 'unset',
  },
  $gtTabletLandscape: {
    minWidth: '62vw',
  },
})

export const SpinnerContainer = styled(View, {
  width: '40vw',
  height: '40vh',
  justifyContent: 'center',
})

export const Title = styled(H4, {
  marginBottom: '$4',
  lineHeight: '$8',
})

export const LogoWrapper = styled(View, {
  borderRadius: 10,
  borderWidth: 1,
  borderColor: '$black1',
  padding: '$2',
  backgroundColor: '$white',
  marginRight: '$2',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Logo = styled(Image, {
  source: {
    uri: `${PUBLIC_URL}/img/virgin-logo-circle.svg`,
    width: 37,
    height: 37,
  },
})

export const SubscriptionName = styled(Text, {
  fontSize: '$6',
  fontWeight: '700',
  marginBottom: '$1',
})

export const Subtitle = styled(Text, {
  fontSize: '$3',
  color: '$black5',
  fontWeight: '400',
  $mobile: {
    maxWidth: 260,
  },
})

export const FeaturesWrapper = styled(YStack, {
  marginLeft: '$0',
  marginTop: '$2',
  $gtTabletPortrait: {
    marginLeft: '$11',
  },
})

export const FeaturesTable = styled(XStack, {
  flexWrap: 'wrap',
})

export const FeatureTableItem = styled(XStack, {
  width: '100%',
  marginBottom: '$2',
  $gtMobile: {
    width: 346,
    marginRight: '$3',
  },
})

export const FeatureTableItemText = styled(Text, {
  marginLeft: '$2',
  fontSize: '$3',
  fontWeight: '400',
})
