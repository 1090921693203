import { YStack, styled } from '@red-ui/components/'
import { CARD_WIDTH } from '../styles'

export const Card = styled(YStack, {
  justifyContent: 'space-between',
  $gtMobile: {
    minWidth: CARD_WIDTH,
  },
  maxWidth: CARD_WIDTH,
  borderRadius: '$4',
  backgroundColor: '$white',
  padding: '$3',
})

export const featuresListStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
}
