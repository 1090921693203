import React, { FC, useEffect } from 'react'
import { color } from '../../../style/variables'
import { getString } from '@vrw/data'
import { Order } from '@vrw/data/src/redux/orders/types'
import { Voucher } from '@vrw/data/src/redux/wallet/types'
import { ImageProps } from '../../../components/image/convertContentfulImage'
import { VoucherTileProps } from '../../../components/TileLister/VoucherTile/types'
import { HelpCTA } from '../../../components/HelpCTA'
import { MyRewardsHero } from './MyRewardsHero'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PARTIAL_PATHS, PATHS } from '../../../router/paths'
import { regionalizePath } from '../../../dataImplementation/viewingRegionWeb'
import { RenderVouchers } from './RenderVouchers'
import { RenderOrders } from './RenderOrders'
import { PUBLIC_URL } from '../../../config'

export interface MyRewardsTab {
  key: string
  link: string
  name: string
  onClick: (key: string) => void
}

export interface MyRewardsPrivateStateProps {
  orderIds: string[]
  orders: { [orderId: string]: Order }
  hasOrdersLoaded: boolean
  voucherIds: string[]
  vouchers: { [voucherId: string]: Voucher }
  hasVouchersLoaded: boolean
  moreAvailable: boolean
  myRewardRoute: string | null
}

export interface MyRewardsPrivateDispatchProps {
  onMount: () => void
  onLoadMore: () => void
  onClickTab: (key: string) => void
}

export interface MyRewardsPrivateCmsProps {
  heroBanner?: {
    title?: string
    leadText?: string
    image?: ImageProps | null
  }
}
export interface MyRewardsPrivateProps extends MyRewardsPrivateStateProps, MyRewardsPrivateDispatchProps, MyRewardsPrivateCmsProps {}

export const MyRewardsPrivate: FC<MyRewardsPrivateProps> = ({
  onMount,
  onLoadMore,
  orderIds,
  orders,
  onClickTab,
  hasOrdersLoaded,
  hasVouchersLoaded,
  voucherIds,
  vouchers,
  moreAvailable,
  myRewardRoute,
}) => {
  useEffect(() => {
    onMount()
  }, [onMount])

  const tabs: MyRewardsTab[] = [
    {
      key: 'vouchers',
      link: `/virgin-red${PATHS.MY_REWARDS_VOUCHERS}`,
      name: 'Vouchers',
      onClick: onClickTab,
    },
    {
      key: 'orders',
      link: `/virgin-red${PATHS.MY_REWARDS_ORDERS}`,
      name: 'Orders',
      onClick: onClickTab,
    },
  ]
  const voucherTiles: Array<VoucherTileProps> = voucherIds
    .map((id) => vouchers[id])
    .map((voucher) => ({
      type: 'voucher',
      voucherId: voucher.voucherId,
      title: voucher.content.title,
      imageUrl: voucher.content.imageUrl,
      brandLogoUrl: voucher.content.brandDetails.brandLogo,
      brandName: voucher.content.brandDetails.brandName,
      lead: voucher.content.lead,
      validTo: voucher.validTo,
    }))

  const orderTiles = orderIds.map((id) => orders[id])
  const gradientHeight = 224
  return (
    <>
      <style jsx>{`
        .slider {
          display: flex;
          justify-content: center;
        }
        .pink {
          height: ${gradientHeight}px;
          background-image: linear-gradient(to bottom, #fce6e6, #fef4f4 57%, #ffffff);
          margin-bottom: -${gradientHeight - 41}px;
        }
        .my-rewards-hero :global(.image) {
          background-size: contain;
        }
      `}</style>
      <div className="my-rewards-hero">
        <MyRewardsHero
          image={{
            url: `${PUBLIC_URL}/img/my-rewards-logged-in-hero.jpg?fl=progressive&q=80`,
            alt: 'My rewards',
          }}
          header={getString('myRewards.title').toUpperCase()}
          subtext={getString('myRewards.description')}
          bgColour={color.turquoiseGreen}
        />
      </div>
      <div className="pink"></div>
      <Routes>
        <Route
          path={PARTIAL_PATHS.VOUCHERS}
          element={
            <RenderVouchers
              tabs={tabs}
              myRewardRoute={myRewardRoute}
              voucherIds={voucherIds}
              voucherTiles={voucherTiles}
              hasVouchersLoaded={hasVouchersLoaded}
              onLoadMore={onLoadMore}
              moreAvailable={moreAvailable}
            />
          }
        />
        <Route
          path={PARTIAL_PATHS.ORDERS}
          element={
            <RenderOrders
              tabs={tabs}
              orderIds={orderIds}
              orderTiles={orderTiles}
              hasOrdersLoaded={hasOrdersLoaded}
              myRewardRoute={myRewardRoute}
            />
          }
        />
        <Route
          path={'*'}
          element={<Navigate to={regionalizePath(myRewardRoute === 'orders' ? PATHS.MY_REWARDS_ORDERS : PATHS.MY_REWARDS_VOUCHERS)} />}
        />
      </Routes>

      <HelpCTA heading={'myRewards.cantSeeTitle'} subheading={'myRewards.cantSeeDescription'} linkText={'myRewards.getHelp'} />
    </>
  )
}
