import { connect } from 'react-redux'

import { doRemoveOptedOutRewards } from '@vrw/data/src/redux/rewards/dispatchers'
import { doPatchProfile } from '@vrw/data/src/redux/profile/dispatchers'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

import { AccountSettings } from './AccountSettings'

const mapStateToProps = (state: RootState) => ({
  profile: getProfileData(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doPatchProfile,
      doRemoveOptedOutRewards,
    },
    dispatch
  )

const AccountSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(AccountSettings)

export { AccountSettingsContainer }
