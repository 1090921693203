import { ViewingRegion } from '@vrw/data/src/utils'
import { generatePath } from 'react-router-dom'
import { regionalizePath, getLocale, localeToRegion } from './viewingRegionWeb'

// Please use useRedNavigate for navigations
export const navigateBack = () => window.history.back()

export const navigate = (routeName: string, params?: { [key: string]: any }, region?: ViewingRegion) => {
  const currentRegion = localeToRegion(getLocale())
  const target = generatePath(regionalizePath(routeName, region ?? currentRegion), params)

  const current = window.location.pathname
  // Preserve VAA token in URL hash when redirecting from /onboarding/link-vaa to onboarding/welcome
  const hash = target.includes('onboarding/welcome') && window.location.hash
  const completeTarget = hash ? `${target}${hash}` : target
  if (target !== current) {
    window.location.href = completeTarget
  }
}
