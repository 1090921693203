import { connect } from 'react-redux'

import { doUpdateRewardsEarnPaginated, doUpdateRewardsEarn } from '@vrw/data/src/redux/discoveryEarn/dispatchers'
import { dofetchHomePageEarnRewards } from '@vrw/data/src/redux/rewardsEarn/dispatchers'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist, doGetWishlist } from '@vrw/data/src/redux/wishlist/dispatchers'
import { getIsFiltersChangedEarn, getIsLoadingEarn, getVisibleEarnRewardIds } from '@vrw/data/src/redux/discoveryEarn/selectors'
import {
  getAllRewardsIds as getAllEarnRewardsIds,
  getIsLoading as getIsLoadingAllEarn,
  getHasLoaded as getHasLoadedAllEarn,
  getEarnPagination,
  getAllRewardsEntities,
} from '@vrw/data/src/redux/rewardsEarn/selectors'
import { getWishlistRewards, getIsLoadingWishlist } from '@vrw/data/src/redux/wishlist/selectors'
import { toggleCategorySelectedEarn } from '@vrw/data/src/redux/discoveryEarn/actions'
import { RootState, DispatchFunction } from '../../redux/types'
import { RewardsList } from './RewardsList'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { getCategoriesEarn } from '@vrw/data/src/redux/categoriesEarn/selectors'

const mapStateToProps = (state: RootState, ownProps: { ignoreFilters?: boolean }) => {
  return {
    categories: getCategoriesEarn(state),
    rewards: getAllRewardsEntities(state),
    rewardIds: ownProps.ignoreFilters ? getAllEarnRewardsIds(state) : getVisibleEarnRewardIds(state),
    earn: true,
    isLoading: ownProps.ignoreFilters ? getIsLoadingAllEarn(state) : getIsLoadingEarn(state),
    hasLoaded: ownProps.ignoreFilters ? getHasLoadedAllEarn(state) : !getIsFiltersChangedEarn(state),
    pagination: getEarnPagination(state),
    showWishListIcons: true,
    wishlist: getWishlistRewards(state),
    isLoadingWishlist: getIsLoadingWishlist(state),
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction, ownProps: { ignoreFilters?: boolean }) =>
  bindTypedActionCreators(
    {
      doRefreshRewards: (loadMoreClicked?: boolean) =>
        ownProps.ignoreFilters ? dofetchHomePageEarnRewards : doUpdateRewardsEarnPaginated(loadMoreClicked),
      doGetWishlist,
      doAddRewardToWishlist,
      doRemoveRewardFromWishlist,
      toggleCategorySelected: toggleCategorySelectedEarn,
      doUpdateRewards: doUpdateRewardsEarn,
    },
    dispatch
  )

export const PrivateEarnRewardsListContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsList)
