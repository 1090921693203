import { styled, Text, View, XStack, Separator, Icon } from '@red-ui/components/'

export const Table = styled(View, {
  paddingHorizontal: '$3',
  paddingVertical: '$4',
  $gtTabletPortrait: {
    paddingVertical: '$6',
    paddingHorizontal: '$7',
  },
})

export const TableHeader = styled(View, {
  style: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
})

export const TableTitle = styled(Text, {
  fontSize: '$9',
  fontWeight: 700,
})

export const TableSubtitle = styled(Text, {
  paddingTop: '$2',
  fontSize: '$7',
  fontWeight: 600,
})

export const TableHeaderRow = styled(XStack, {
  justifyContent: 'space-between',
  paddingVertical: '$2',
  backgroundColor: '$white',
  $mobile: {
    flexDirection: 'column',
  },
})

export const TableHeaderText = styled(Text, {
  fontSize: '$5',
})

export const TableRow = styled(XStack, {
  justifyContent: 'space-between',
  backgroundColor: '$white',
})

export const TableHeaderFeatureSection = styled(View, {
  $gtTabletPortrait: {
    flexGrow: 4,
  },
  $gtMobile: {
    flexBasis: 0,
  },
  $mobile: {
    width: 'auto',
    marginBottom: '$1',
  },
  flexGrow: 2,
})

export const TableHeaderPlansSection = styled(XStack, {
  $gtTabletPortrait: {
    flexGrow: 5,
  },
  $gtMobile: {
    flexBasis: 0,
    flexGrow: 2,
    gap: '$1',
  },
  justifyContent: 'space-between',
  $mobile: {
    justifyContent: 'flex-start',
    gap: '$2',
  },
})

export const TableFeatureSection = styled(View, {
  flexBasis: 0,
  paddingVertical: '$2',
  $gtTabletPortrait: {
    flexGrow: 4,
  },
  flexGrow: 2,
  $mobile: {
    flexGrow: 4,
  },
})

export const TableFeatureSectionText = styled(Text, {
  $gtTabletPortrait: {
    paddingLeft: '$2',
  },
  paddingRight: '$5',
})

export const TableHeaderSectionItem = styled(View, {
  flexGrow: 1,
  flexBasis: 0,
  $mobile: {
    justifyContent: 'center',
    flex: 0,
  },
})

export const TableSectionItem = styled(View, {
  flexGrow: 1,
  flexBasis: 0,
  justifyContent: 'center',
})

export const TableSectionIconWrapper = styled(View, {
  flex: 1,
  width: '$5',
  justifyContent: 'center',
  alignItems: 'flex-start',
  $mobile: {
    alignItems: 'center',
  },
})

export const TableFeatureSectionSeparator = styled(Separator, {
  $gtTabletPortrait: {
    marginLeft: '$2',
  },
})

export const TablePlansSection = styled(XStack, {
  $gtTabletPortrait: {
    flexGrow: 5,
  },
  $gtMobile: {
    flexBasis: 0,
    gap: '$1',
    flexGrow: 2,
  },
  gap: '$1',
  justifyContent: 'space-between',
})

export const IconX = styled(Icon.X, {
  color: '$black2',
  $mobile: {
    color: '$black10',
    opacity: 0.2,
  },
})
