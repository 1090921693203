import React, { FC, useEffect } from 'react'
import cn from 'classnames'

import { BlockTypes } from '../../components/ContentfulBlock/types'
import { media } from '../../style/media'
import { Block, DynamicContentFactory, Page } from '../../components'
import { NotFound } from '..'
import { ComposedPageFields } from './types'
import { ContentfulEntry, ContentfulPage } from '@vrw/data/src/utils/contentfulPages'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { useSelector } from 'react-redux'
import { getCurrentPage, getError } from '@vrw/data/src/redux/pages/selectors'
import { doGenericPageFetchFilterBySlug } from '@vrw/data/src/redux/pages/dispatchers'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hooks/hooks'

export const ComposedPage: FC = () => {
  const viewingRegion = localeToRegion(getLocale())
  const { pagePath = '' } = useParams()
  const error = useSelector(getError)
  const currentPage = useSelector(getCurrentPage) as ContentfulPage<ComposedPageFields>
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(doGenericPageFetchFilterBySlug({ slug: pagePath, ...(viewingRegion && { viewingRegion: `EN_${viewingRegion}` }) }))
  }, [dispatch, pagePath, viewingRegion])

  if (error) {
    return <NotFound />
  }

  return currentPage ? (
    <>
      <style jsx>{`
        .block-container {
          margin-bottom: 24px;
        }
        .richtext-wrapper {
          padding: 0 15px;
        }
        .redhotbanner-wrapper,
        .image-embed-wrapper,
        .how-it-works-wrapper,
        .faq-wrapper {
          margin-bottom: 0;
        }
        @media ${media.tabletAndHigher} {
          .block-container {
            margin-bottom: 36px;
          }
          .richtext-wrapper {
            padding: 0;
          }
          .redhotbanner-wrapper,
          .image-embed-wrapper,
          .how-it-works-wrapper,
          .faq-wrapper {
            margin-bottom: 0;
          }
        }
        @media ${media.desktopAndHigher} {
          .block-container {
            margin-bottom: 48px;
          }
          .redhotbanner-wrapper,
          .image-embed-wrapper,
          .how-it-works-wrapper,
          .faq-wrapper {
            margin-bottom: 0;
          }
        }
      `}</style>
      <Page
        testId={`composed-page-${currentPage.slug}`}
        title={`${currentPage.seo?.fields?.title || currentPage.title} | Virgin Red `} // Using 'currentPage.title' here is wrong, however, it keeps the current behaviour expected by content editors if the actual SEO title is undefined
        metaDescription={`${currentPage.seo?.fields?.description}`}>
        {currentPage.content?.sys.contentType?.sys.id === 'dynamicPageContent' ? (
          <DynamicContentFactory content={currentPage.content as ContentfulEntry} />
        ) : (
          <div className="page-container">
            {currentPage.content?.fields.body?.map((x) => (
              <div
                key={x.sys.id}
                className={cn(
                  'block-container',
                  { 'richtext-wrapper': x.sys.contentType?.sys.id === BlockTypes.RICH_TEXT_BLOCK },
                  { 'redhotbanner-wrapper': x.sys.contentType?.sys.id === BlockTypes.RED_HOT_BANNER },
                  { 'image-embed-wrapper': x.sys.contentType?.sys.id === BlockTypes.IMAGE_EMBEDDED_CONTENT },
                  { 'how-it-works-wrapper': x.sys.contentType?.sys.id === BlockTypes.HOW_IT_WORKS },
                  { 'faq-wrapper': x.sys.contentType?.sys.id === BlockTypes.FAQ_BLOCK }
                )}>
                <Block block={x as ContentfulEntry}></Block>
              </div>
            ))}
          </div>
        )}
      </Page>
    </>
  ) : (
    <></>
  )
}
