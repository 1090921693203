import React, { FC, useEffect, useState } from 'react'
import {
  CookiePreferenceId,
  getCookiePreferences,
  getDefaultCookiePreferences,
  getMarketingAdvertisingPreferenceCookie,
  setAllCookiePreferences,
  setCookiePreferences as saveCookiePreferences,
} from '../../helpers/cookiePreferences'
import { PATHS, DOTCOM_PATHS } from '../../router/paths'
import { Anchor, Sheet, SheetProps, H5, Paragraph, Button, XStack, styled, Icon, YStackProps } from '@red-ui/components'
import { localizePath } from '../../dataImplementation/viewingRegionWeb'
import { getString, transformInterpolatedString } from '@vrw/data'
import { Trans } from 'react-i18next'
import { CookiePreferenceData, cookieDataMap } from './cookiePreferencesData'
import { useAppSelector } from 'web/src/redux/hooks/hooks'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import CookieTracker from './CookieTracker'

const StyledAnchor = styled(Anchor, {
  fontSize: '$3',
  fontWeight: '600',
  variant: 'red',
})

const StyledButton = styled(Button, {
  alignSelf: 'center',
  flexBasis: '80%',
  fontSize: '$3',
  marginHorizontal: '$2',
  marginVertical: '$1',
  size: '$6',
  $gtMobile: {
    flexBasis: 'auto',
    paddingHorizontal: '$12',
  },
  $gtTabletPortrait: {
    flexBasis: 'auto',
    paddingHorizontal: '$15',
  },
})

export const CookieConsent: FC = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const [showCookiePreferences, setShowCookiePreferences] = useState(false)
  const [cookiePreferences, setCookiePreferences] = useState(getCookiePreferences() ?? getDefaultCookiePreferences())

  const toggleCookiePreference = (cookiePreferenceId: CookiePreferenceId): void => {
    const newCookiePreferences = { ...cookiePreferences }

    newCookiePreferences[cookiePreferenceId] = !newCookiePreferences[cookiePreferenceId]

    setCookiePreferences(newCookiePreferences)
  }

  const isPrivacyPage = window.location.pathname?.match(new RegExp(PATHS.PRIVACY)) !== null

  useEffect(() => {
    if (getCookiePreferences() === null || getMarketingAdvertisingPreferenceCookie() === undefined) {
      setShowCookieBanner(true)
    }
  }, [])

  const sheetProps: SheetProps = {
    animation: 'lazy',
    disableDrag: true,
    dismissOnOverlayPress: false,
    forceRemoveScrollEnabled: isPrivacyPage ? false : true,
  }

  const overlayProps: YStackProps = {
    animation: sheetProps.animation,
    enterStyle: { opacity: 0 },
    exitStyle: { opacity: 0 },
    backgroundColor: '$black10',
    pointerEvents: isPrivacyPage ? 'none' : 'auto',
  }

  const frameProps = {
    borderRadius: 0,
    paddingVertical: '$6',
    paddingHorizontal: '$3',
    $gtTabletPortrait: {
      paddingHorizontal: '$6',
      paddingVertical: '$8',
    },
    $gtTabletLandscape: {
      paddingHorizontal: '$20',
    },
  }

  if (!showCookieBanner) return null
  return (
    <>
      <Sheet open={showCookieBanner} modal snapPointsMode="fit" {...sheetProps}>
        <Sheet.Overlay {...overlayProps} style={{ opacity: isPrivacyPage ? 0 : 0.32 }} />
        <Sheet.Frame {...frameProps}>
          <Sheet open={showCookiePreferences} modal snapPoints={[90]} {...sheetProps}>
            <Sheet.Overlay {...overlayProps} style={{ opacity: isPrivacyPage ? 0 : 0.32 }} />
            <Sheet.Frame {...frameProps}>
              <Sheet.ScrollView>
                <H5 textAlign="center" marginBottom="$2">
                  {getString('privacyPromptPrefernces.title.web')}
                </H5>
                <Button
                  accessibilityLabel={getString('privacyPromptPrefernces.close')}
                  backgroundColor="transparent"
                  borderStyle="unset"
                  borderWidth={0}
                  alignSelf="flex-end"
                  position="absolute"
                  hoverStyle={{
                    backgroundColor: '$transparent',
                  }}
                  pressStyle={{
                    backgroundColor: '$transparent',
                  }}
                  onPress={() => setShowCookiePreferences(false)}
                  icon={<Icon.X size={24} color="$black10" />}
                />
                <Paragraph size="$3" lineHeight="$4">
                  <Trans
                    defaults={transformInterpolatedString(getString('privacyPromptPreferences.text.web'))}
                    components={{
                      cpLink: <StyledAnchor href={DOTCOM_PATHS.COOKIES} />,
                    }}
                  />
                </Paragraph>
                {Object.entries<CookiePreferenceData>(cookieDataMap).map(([key, data]: [string, CookiePreferenceData]) => {
                  const id = key as CookiePreferenceId
                  return (
                    <CookieTracker
                      key={id}
                      title={data.title}
                      content={data.content}
                      toggleCookiePreference={() => id !== CookiePreferenceId.NecessaryCookies && toggleCookiePreference(id)}
                      isSelected={cookiePreferences[id]}
                    />
                  )
                })}
                <XStack
                  justifyContent="center"
                  marginTop="$3"
                  marginBottom="$2"
                  flexDirection="column"
                  $tabletPortrait={{
                    flexDirection: 'row',
                  }}
                >
                  <StyledButton
                    accessibilityLabel={getString('privacyPrompt.saveCustomButton.web')}
                    onPress={() => {
                      setShowCookieBanner(false)
                      saveCookiePreferences(cookiePreferences, isAuthenticated)
                    }}
                  >
                    {getString('privacyPrompt.saveCustomButton.web')}
                  </StyledButton>
                </XStack>
              </Sheet.ScrollView>
            </Sheet.Frame>
          </Sheet>
          <>
            <H5 textAlign="center" marginBottom="$2">
              {getString('privacyPrompt.title.web')}
            </H5>
            <Paragraph size="$3" lineHeight="$4">
              <Trans
                defaults={transformInterpolatedString(getString('privacyPrompt.text.web'))}
                components={{
                  ppLink: <StyledAnchor accessibilityLabel={'Privacy Policy'} href={localizePath(PATHS.PRIVACY)} />,
                  cpLink: <StyledAnchor href={DOTCOM_PATHS.COOKIES} />,
                }}
              />
            </Paragraph>
            <XStack
              justifyContent="center"
              marginTop="$3"
              flexDirection="row"
              flexWrap="wrap"
              $gtMobile={{
                flexDirection: 'row',
                marginBottom: '$2',
                flexWrap: 'nowrap',
              }}
            >
              <StyledButton
                onPress={() => {
                  setShowCookieBanner(false)
                  setAllCookiePreferences(true)
                }}
              >
                {getString('privacyPrompt.acceptButton')}
              </StyledButton>
              <StyledButton
                onPress={() => {
                  setShowCookieBanner(false)
                  setAllCookiePreferences(false)
                }}
              >
                {getString('privacyPrompt.rejectButton')}
              </StyledButton>
            </XStack>
            <XStack justifyContent="center">
              <StyledButton onPress={() => setShowCookiePreferences(true)} variant="secondary">
                {getString('privacyPrompt.settingsButton')}
              </StyledButton>
            </XStack>
          </>
        </Sheet.Frame>
      </Sheet>
      )
    </>
  )
}
