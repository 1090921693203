export const {
  VITE_ENV: ENV,
  VITE_IS_PRODUCTION: IS_PRODUCTION,
  VITE_API_URL: API_URL,
  VITE_DOTCOM_URL: DOTCOM_URL,
  VITE_AUTH0_CHANGE_EMAIL_CLIENT_ID: AUTH0_CHANGE_EMAIL_CLIENT_ID,
  VITE_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  VITE_AUTH0_AUTH_URL: AUTH0_AUTH_URL,
  VITE_AUTH0_PARTNER_CONNECT_CLIENT_ID: AUTH0_PARTNER_CONNECT_CLIENT_ID,
  VITE_AUTH0_API_AUDIENCE: AUTH0_API_AUDIENCE,
  VITE_VID_ACCOUNT_URL: VID_ACCOUNT,
  VITE_VAA_AUTH_ISSUER: VAA_AUTH_ISSUER,
  VITE_VAA_AUTH_CLIENT_ID: VAA_AUTH_CLIENT_ID,
  VITE_VAA_AUTH_P: VAA_AUTH_P,
  VITE_VAA_AUTH_CALLBACK: VAA_AUTH_CALLBACK,
  VITE_CONTENTFUL_ENV: CONTENTFUL_ENV,
  VITE_CONTENTFUL_SPACE: CONTENTFUL_SPACE,
  VITE_CONTENTFUL_URL: CONTENTFUL_URL,
  VITE_CONTENTFUL_PREVIEW_URL: CONTENTFUL_PREVIEW_URL,
  VITE_CONTENTFUL_ACCESS_TOKEN: CONTENTFUL_ACCESS_TOKEN,
  VITE_CONTENTFUL_PREVIEW_ACCESS_TOKEN: CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  VITE_FEATURE_MENTION_ME: FEATURE_MENTION_ME,
  VITE_FEATURE_VID_AUTH: FEATURE_VID_AUTH,
  VITE_TRAINS_DYNAMIC_LINK_BASE_URL: TRAINS_DYNAMIC_LINK_BASE_URL,
  VITE_TRAINS_DYNAMIC_LINK_ANDROID_PACKAGE: TRAINS_DYNAMIC_LINK_ANDROID_PACKAGE,
  VITE_TRAINS_DYNAMIC_LINK_IOS_PACKAGE: TRAINS_DYNAMIC_LINK_IOS_PACKAGE,
  VITE_TRAINS_DYNAMIC_LINK_APP_STORE_ID: TRAINS_DYNAMIC_LINK_APP_STORE_ID,
  VITE_BRAZE_API_KEY: BRAZE_API_KEY,
  VITE_BRAZE_BASE_URL: BRAZE_BASE_URL,
  VITE_BRAZE_JWT_URL: BRAZE_JWT_URL,
  VITE_PING_AUTH_URL: PING_AUTH_URL,
  VITE_PING_ACCOUNT_SPACE: PING_ACCOUNT_SPACE,
  VITE_PING_CLIENT_ID: PING_CLIENT_ID,
  VITE_PING_ACR_VALUE: PING_ACR_VALUE,
  VITE_DATADOG_SERVICE_NAME: DATADOG_SERVICE_NAME,
  VITE_DATADOG_APPLICATION_ID: DATADOG_APPLICATION_ID,
  VITE_DATADOG_CLIENT_TOKEN: DATADOG_CLIENT_TOKEN,
  VITE_MENTION_ME_PARTNER_CODE: MENTION_ME_PARTNER_CODE,
  VITE_MENTION_ME_API_URL: MENTION_ME_API_URL,
  VITE_B2C_API_URL: B2C_API_URL,
  VITE_RED_API: RED_API_URL,
  VITE_SYNCHRONY_APPLY_URL: SYNCHRONY_APPLY_URL,
  VITE_STRIPE_PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY,
} = import.meta.env

// given this never changes, we can just export it rather than it come from env
export const PUBLIC_URL = import.meta.env.DEV ? '' : '/virgin-red'

export const FEATURE_FLAGS = {
  mentionME: FEATURE_MENTION_ME === 'false',
  vidAuth: FEATURE_VID_AUTH === 'true',
}
