import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { useEffect } from 'react'
import { storePath, getLocale, localeToRegion } from '../dataImplementation/viewingRegionWeb'
import { pushToGTM } from '../helpers/windowMethods'
import { useAppSelector } from '../redux/hooks/hooks'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'
import { useLocation } from 'react-router-dom'

export function useOnRouteChange() {
  const profile = useAppSelector(getProfileData)
  const redNavigate = useRedNavigate()
  const { pathname, search } = useLocation()
  const localPath = pathname.replace('/virgin-red', '').replace('/en-us', '') ?? '' // Strip region part off of relative path

  useEffect(() => {
    if (profile) {
      pushToGTM({
        firstname: profile.firstName,
        surname: profile.lastName,
        email: profile.email,
        signup_id: profile.redUserId,
        locale: profile.defaultLocale,
        createdAt: profile.createdAt,
      })
    }
  }, [profile])

  useEffect(() => {
    const currentLocale = localeToRegion(getLocale())
    storePath(localPath + search)

    if (profile && profile?.country !== currentLocale) {
      redNavigate(localPath, {}, profile?.country)
    }
  }, [localPath, search, profile, redNavigate])
}
