import { WishlistRequest } from '../redux/wishlist/types'

export const getPaths = ({
  apiBaseUrl,
  redApiBaseUrl,
  b2cApiBaseUrl,
}: {
  apiBaseUrl: string
  redApiBaseUrl: string
  b2cApiBaseUrl?: string
}) => {
  return {
    RED_API_PROFILE_REGION_PATCHING: `${apiBaseUrl}/profiles/v2`,
    RED_API_PROFILE: `${apiBaseUrl}/profiles`,
    RED_API_ACCOUNTS: `${apiBaseUrl}/profiles/accounts`,
    RED_API_LINK_ACCOUNTS: `${apiBaseUrl}/profiles/account-linkings`,
    RED_API_UPDATE_EMAIL: `${apiBaseUrl}/profiles/email-updater`,
    RED_API_BALANCE: `${apiBaseUrl}/balances`,
    RED_API_FEATURES: (projectId: string, sessionId: string) => `${apiBaseUrl}/features?project=${projectId}&sessionId=${sessionId}`,
    RED_API_CHECK_VERSION: `${apiBaseUrl}/check_version`,
    RED_API_CONTENT_PRIVACY_POLICY: `${apiBaseUrl}/content/privacy`,
    RED_API_CONTENT_TERMS_AND_CONDITIONS: `${apiBaseUrl}/content/terms`,
    RED_API_DISCOVERY_BRANDS: `${redApiBaseUrl}/discoveries/brands`,
    RED_API_DISCOVERY_CATEGORIES: `${apiBaseUrl}/discoveries/categories`,
    RED_API_DISCOVERY_CATEGORIES_EARN: `${apiBaseUrl}/discoveries/categories/earn`,
    RED_API_DISCOVERY_REWARDS_EARN: `${apiBaseUrl}/discoveries/rewards/earn`,
    RED_API_DISCOVERY_REWARDS_SPEND: `${apiBaseUrl}/discoveries/rewards`,
    RED_API_DISCOVERY_CATEGORIES_PUBLIC: `${apiBaseUrl}/discoveries/categories/public`,
    RED_API_DISCOVERY_CATEGORIES_EARN_PUBLIC: `${apiBaseUrl}/discoveries/categories/earn/public`,
    RED_API_DISCOVERY_REWARDS_EARN_PUBLIC: `${apiBaseUrl}/discoveries/rewards/earn/public`,
    RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC: `${apiBaseUrl}/discoveries/rewards/public`,
    RED_API_DISCOVERY_SEARCH: `${apiBaseUrl}/discoveries/search`,
    RED_API_DISCOVERY_WISHLISTS: `${apiBaseUrl}/discoveries/rewards/wishlist`,
    RED_API_ADD_TO_WISHLIST: (redUserId: string) => `${apiBaseUrl}/wishlists/user/${redUserId}/rewards`,
    RED_API_REMOVE_FROM_WISHLIST: (request: WishlistRequest) =>
      `${apiBaseUrl}/wishlists/user/${request.redUserId}/rewards/${request.rewardType}/${request.rewardId}`,
    RED_API_EXCHANGE: `${apiBaseUrl}/exchanges`,
    RED_API_ORDERS: `${apiBaseUrl}/orders`,
    RED_API_PENDING_POINTS: `${apiBaseUrl}/statements/pending`,
    RED_API_PROMOTIONS: `${apiBaseUrl}/redemptions/promotion`,
    RED_API_REDEMPTIONS: `${apiBaseUrl}/redemptions`, // Collinson earn type only
    RED_API_REWARDS_SPEND: `${apiBaseUrl}/rewards`,
    RED_API_REWARDS_SPEND_PUBLIC: `${apiBaseUrl}/rewards/public`,
    RED_API_REWARDS_EARN: `${apiBaseUrl}/rewards/earn`,
    RED_API_REWARDS_EARN_PUBLIC: `${apiBaseUrl}/rewards/earn/public`,
    RED_API_ARTICLES_PUBLIC: `${apiBaseUrl}/articles`,
    RED_API_STATEMENT: `${apiBaseUrl}/statements`,
    RED_API_TWILIO_REGISTER: `${apiBaseUrl}/twilio/binding`,
    RED_API_VOUCHERS: `${apiBaseUrl}/vouchers`,
    RED_API_PARTNER_CONNECT: (partnerCode: string) => `${apiBaseUrl}/client-connect/${partnerCode}`,
    B2C_API_USER_EVENTS: `${b2cApiBaseUrl}/v1/user-events`,
    B2C_API_SUBSCRIPTIONS_PLANS: `${b2cApiBaseUrl}/v1/plans`,
    B2C_API_STRIPE_CHECKOUT_SESSION: `${b2cApiBaseUrl}/v1/accounts/me/checkout/sessions`,
    B2C_API_USER_SUBSCRIPTIONS: `${b2cApiBaseUrl}/v1/accounts/me/subscriptions`,
    GENERATE_B2C_API: (path?: string) => {
      const needSlash = path?.startsWith('/') ? '' : '/'
      return `${b2cApiBaseUrl}${needSlash}${path}`
    },
  } as const
}
