import React from 'react'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'
import { breakpoints, color, media } from '@virgin-core/styles'
import { ContentfulEntry, getSectionBackgroundColor } from '@vrw/data/src/utils/contentfulPages'
import { Dimensions, optimizeContentfulImageUrl } from '../../helpers/images'
import { Heading } from '..'
import { EmbeddedContentBlock } from './EmbeddedContentBlock'
import { ImageEmbeddedContentBlockFields } from './types'

interface ImageEmbeddedContentBlockProps {
  fields: ImageEmbeddedContentBlockFields
}

export const ImageEmbeddedContentBlock = ({ fields }: ImageEmbeddedContentBlockProps) => {
  const foregroundImageDimensions: Dimensions = { width: 1000, height: 750 }
  const backgroundImageDimensions: Dimensions = { width: 1920, height: 1080 }
  const foregroundUrl = optimizeContentfulImageUrl(fields.foregroundImage?.fields.file?.url, 'fill', foregroundImageDimensions)
  const backgroundUrl = optimizeContentfulImageUrl(fields.backgroundImage?.fields.file?.url, 'fill', backgroundImageDimensions)

  return (
    <>
      <style jsx>{`
        .image-content-block {
          ${backgroundUrl && `background-image: url(${backgroundUrl})`};
          background-color: ${getSectionBackgroundColor({ fields } as ContentfulEntry)};
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          padding-top: 20px;
        }
        .image-content-block :global(.image-content-panel) {
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        .image-content-block :global(.text-panel) {
          flex-direction: column;
          margin-bottom: 24px;
        }
        .image-content-block :global(.text-panel .header-one),
        .image-content-block :global(.text-panel .header-two) {
          font-size: 32px;
          text-align: center;
          margin: 0;
        }
        .image-content-block :global(.image-area) {
          margin-bottom: 24px;
        }
        .image-content-block .foreground-image {
          display: block;
          width: 100%;
          height: auto;
          position: relative;
          z-index: 1;
          object-fit: cover;
          max-width: 530px;
        }
        .image-content-block :global(.content-area) {
          width: 100%;
          margin-bottom: 16px;
        }
        .image-content-block :global(.content-area .embed-content) {
          border-radius: 8px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          overflow: hidden;
          padding: 0;
        }

        @media ${media.tabletAndHigher} {
          .image-content-block {
            padding-top: 40px;
          }
          .image-content-block :global(.text-panel),
          .image-content-block :global(.image-area) {
            margin-bottom: 20px;
          }
          .image-content-block :global(.text-panel .header-one),
          .image-content-block :global(.text-panel .header-two) {
            font-size: 40px;
          }
          .image-content-block :global(.content-area) {
            margin-bottom: 40px;
          }
        }

        @media ${media.desktopAndHigher} {
          .image-content-block :global(.image-content-panel) {
            flex-direction: row-reverse;
            align-items: center;
          }
          .image-content-block :global(.text-panel) {
            margin-bottom: 26px;
          }
          .image-content-block :global(.text-panel .header-one),
          .image-content-block :global(.text-panel .header-two) {
            font-size: 50px;
            text-align: left;
          }
          .image-content-block :global(.image-area) {
            margin-bottom: auto;
          }
          .image-content-block .foreground-image {
            max-width: 100%;
          }
        }

        @media ${media.hiResDesktop} {
          .image-content-block :global(.image-content-panel) {
            max-height: 800px;
          }
        }
      `}</style>
      <div className="image-content-block" data-testid="image-content-block">
        <GridWrapper>
          {fields.headline && (
            <GridContainer>
              <GridItem xs={12} lg={6} className="text-panel">
                <Heading
                  fontStyle="Normal"
                  color={fields.textColour ? color.white : color.textStandard}
                  weight={600}
                  textAlign={window.innerWidth < breakpoints.tabletMin ? 'center' : 'left'}
                  marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                  marginBottom={{ mobile: 16, tablet: 16, desktop: 24 }}
                  fontSize={{ mobile: 28, tablet: 24, desktop: 50 }}
                  seoHeadline={fields.topHero}
                >
                  {fields.headline}
                </Heading>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer className="image-content-panel">
            <GridItem xs={12} lg={6} className="image-area">
              {fields.foregroundImage && (
                <img
                  width={375}
                  height={290}
                  alt={fields.foregroundImage?.fields?.description}
                  src={foregroundUrl}
                  className="foreground-image"
                />
              )}
            </GridItem>
            <GridItem xs={12} lg={6} className="content-area">
              <EmbeddedContentBlock fields={fields.embeddedContent.fields} />
            </GridItem>
          </GridContainer>
        </GridWrapper>
      </div>
    </>
  )
}
