import { NavigateOptions, generatePath, useLocation, useNavigate } from 'react-router-dom'
import { getLocale, localeToRegion, regionalizePath } from './viewingRegionWeb'
import { ViewingRegion } from '@vrw/data/src/utils'

// We should generally use this in place of the plain navigate
export const useRedNavigate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (routeName: string, params?: { [key: string]: any }, region?: ViewingRegion, options?: NavigateOptions) => {
    const currentRegion = localeToRegion(getLocale())
    const target = generatePath(regionalizePath(routeName, region ?? currentRegion), params)

    const current = location.pathname
    // Preserve VAA token in URL hash when redirecting from /onboarding/link-vaa to onboarding/welcome
    const hash = target.includes('onboarding/welcome') && window.location.hash
    const completeTarget = hash ? `${target}${hash}` : target
    if (target !== current) {
      navigate(completeTarget, options)
    }
  }
}
