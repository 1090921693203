import React from 'react'
import { getString } from '@vrw/data'
import { SubscriptionPlan } from '@vrw/data/src/api/subscriptions.api'
import {
  TableFeatureSection,
  TableFeatureSectionSeparator,
  TableFeatureSectionText,
  TablePlansSection,
  TableRow,
  TableSectionItem,
  TableSubtitle,
  TableSectionIconWrapper,
} from './styles'
import { FeatureAvailabilityIcon } from './FeatureAvailabilityIcon'

type TableBodyProps = {
  features: string[]
  plans: SubscriptionPlan[]
}

export const TableBody = ({ features, plans }: TableBodyProps) => {
  const renderTableRow = (featureDescription: string, featureOrderIndex: number) => {
    const isFirstFeature = featureOrderIndex === 0
    const isLastFeature = featureOrderIndex === features.length - 1
    const borderTopRadius = isFirstFeature ? '$2' : 0
    const borderBottomRadius = isLastFeature ? '$2' : 0

    return (
      <React.Fragment key={featureDescription}>
        <TableRow>
          <TableFeatureSection>
            <TableFeatureSectionText>{featureDescription}</TableFeatureSectionText>
          </TableFeatureSection>
          <TablePlansSection>
            {plans.map((plan) => (
              <TableSectionItem key={plan.id}>
                <TableSectionIconWrapper
                  borderTopLeftRadius={borderTopRadius}
                  borderTopRightRadius={borderTopRadius}
                  borderBottomLeftRadius={borderBottomRadius}
                  borderBottomRightRadius={borderBottomRadius}
                  $mobile={{ backgroundColor: `${plan.primaryColor}50` }}>
                  <FeatureAvailabilityIcon planFeatures={plan.features} featureDescription={featureDescription} />
                </TableSectionIconWrapper>
              </TableSectionItem>
            ))}
          </TablePlansSection>
        </TableRow>
        {!isLastFeature && <TableFeatureSectionSeparator />}
      </React.Fragment>
    )
  }

  return (
    <>
      <TableSubtitle>{getString('subscribe.page.content.table.subtitle')}</TableSubtitle>
      {features.map(renderTableRow)}
    </>
  )
}
