import { useQuery } from '@tanstack/react-query'
import { apiSubscribeToPlan, SUBSCRIPTION_ERROR_CODE, SubscriptionError } from '@vrw/data/src/api/subscriptions.api'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSubscriptionPlanById, useUserSubscriptionStatus } from '../../query/subscriptions'
import { useRedNavigate } from '../../dataImplementation/useRedNavigate'
import { PATHS } from '../../router/paths'

export const useConfirmSubscription = () => {
  const [getSearchParams] = useSearchParams()
  const sessionId = getSearchParams.get('session_id') ?? ''
  const { refetch } = useUserSubscriptionStatus()
  const navigate = useRedNavigate()

  const createSubscription = useQuery({
    queryKey: ['createSubscription', sessionId],
    queryFn: () => apiSubscribeToPlan(sessionId),
    enabled: !!sessionId,
    retry: (failureCount: number, error: SubscriptionError) => {
      if (error?.code === SUBSCRIPTION_ERROR_CODE.SUBSCRIPTION_EXISTS) {
        navigate(PATHS.MY_ACCOUNT_SUBSCRIPTION)
        return false
      }

      return failureCount < 3
    },
  })

  const plan = useSubscriptionPlanById(createSubscription.data?.planId)

  useEffect(() => {
    if (createSubscription.isFetched) refetch()
  }, [createSubscription.isFetched, refetch])

  return {
    ...createSubscription,
    sessionId,
    plan: plan.data,
    isLoading: createSubscription.isLoading || plan.isLoading,
  }
}
