import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { convertToKebabCase } from '../../helpers/text'

import { FontFamilies, color } from '../../style/variables'
import { localizePath } from '../../dataImplementation/viewingRegionWeb'
import { styled, View } from 'tamagui'

export interface IPageNavItem {
  linkUrl: string
  linkText: string
  navItems?: IPageNavItem[]
}

export interface InPageNavigationItemProps extends IPageNavItem {
  level?: number
}

const ItemLine = styled(View, {
  width: '$0.25',
  position: 'absolute',
  left: 0,
  top: '$0.5',
  bottom: '$0.5',
  variants: {
    active: {
      true: {
        backgroundColor: '$red6',
      },
    },
  },
})

export const InPageNavigationItem: React.FC<InPageNavigationItemProps> = ({ linkUrl, linkText, navItems, level = 1 }) => {
  const isActive = window.location.href.endsWith(linkUrl)
  const showNested = !!(navItems?.length && window.location.href.includes(linkUrl))

  return (
    <>
      <style jsx>{`
        .in-page-nav-item {
          margin: 10px 0;
        }

        :global(.in-page-nav-link) {
          color: ${color.textStandard};
          font-family: ${FontFamilies.barlow};
          font-size: 14px;
          letter-spacing: 1.3px;
          text-decoration: none;
          text-transform: uppercase;
        }

        :global(.in-page-nav-link):hover {
          color: ${color.brandPrimary};
        }

        :global(.in-page-nav-link--active) {
          color: ${color.brandPrimary};
        }
      `}</style>
      <>
        <li className="in-page-nav-item">
          <View paddingLeft={`$${level}`}>
            <ItemLine active={isActive} />
            <Link
              data-testid={convertToKebabCase(linkText)}
              to={localizePath(linkUrl)}
              title={linkText}
              aria-label={linkText}
              className={cx('in-page-nav-link', {
                'in-page-nav-link--active': isActive,
              })}>
              {linkText}
            </Link>
          </View>
        </li>
        {showNested && navItems.map((item) => <InPageNavigationItem key={item.linkUrl} level={level + 1} {...item} />)}
      </>
    </>
  )
}
