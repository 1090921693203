import React from 'react'
import { SubscriptionFeature } from '@vrw/data/src/api/subscriptions.api'
import { Icon } from '@red-ui/components'
import { IconX } from './styles'

type FeatureAvailabilityIconProps = {
  planFeatures: SubscriptionFeature[]
  featureDescription: string
}

export const FeatureAvailabilityIcon = ({ planFeatures, featureDescription }: FeatureAvailabilityIconProps) => {
  const isFeatureAvailableInPlan = planFeatures.find((feature) => feature.description === featureDescription)
  return isFeatureAvailableInPlan ? <Icon.Check /> : <IconX />
}
